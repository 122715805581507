import React from 'react';
import _ from 'lodash';

import DeliveriesService from '../../../services/deliveries_service';
import DeliveryRow from './delivieres/delivery_row';
import DeliveryItem from './delivieres/delivery_item';
import DeliveryDocumentRow from './delivieres/delivery_document_row';
import DeliveryModel from '../../../models/delivery_model';
import Loader from '../../loader';
import * as Toast from '../../shared/toast';
import * as swipe from '../../shared/swipe';

class DeliveryModal extends React.Component {
  constructor(props) {
    super(props);

    const { purchaseOrder } = props;

    this.state = {
      deliveries: purchaseOrder.deliveries.map(delivery => new DeliveryModel(delivery)),
      isLoading: false,
    };
  }

  componentDidMount = () => {
    swipe.triggerSwipeEvent();
    this.calculateTotal();
  }

  componentDidUpdate = (prevProps) => {
    const { isNewDelivery } = this.props;
    if (isNewDelivery !== prevProps.isNewDelivery && isNewDelivery) this.addNewDelivery();
  }

  addNewDelivery = () => {
    const {
      props: {
        purchaseOrder,
      },
      state: {
        deliveries,
      },
    } = this;

    this.setState({ isLoading: true });

    DeliveriesService.new(purchaseOrder.id)
      .then((delivery) => {
        this.setState({
          deliveries: [delivery, ...deliveries],
          isLoading: false,
        }, () => this.calculateTotal());
      });
  }

  updateDeliveries = (newDelivery) => {
    const { deliveries } = this.state;

    const newDeliveries = deliveries.map((delivery) => {
      if (newDelivery.uuid === delivery.uuid) {
        return newDelivery;
      }
      return delivery;
    });

    this.setState({ deliveries: newDeliveries }, () => this.calculateTotal());
  }

  deleteDelivery = (deleteDelivery) => {
    let { state: { deliveries } } = this;

    deliveries = deliveries.map((delivery) => {
      if (delivery.uuid === deleteDelivery.uuid) {
        return deleteDelivery;
      }
      return delivery;
    });

    this.setState({ deliveries }, () => this.calculateTotal());
  }

  saveNewDeliveries = () => {
    const { props: { purchaseOrder, currentUser, updateOrderItems }, state: { deliveries } } = this;

    this.setState({ isLoading: true });

    DeliveriesService.updateAll(purchaseOrder.id, deliveries, currentUser)
      .then(({ deliveries: newDeliveries, items }) => {
        this.setState({ deliveries: newDeliveries, isLoading: false });
        updateOrderItems(items);
        this.calculateTotal();
      })
      .catch(() => (
        Toast.errorToast(I18n.t('commons.messages.error'))
      ));
  }

  totalValue = () => {
    const { deliveries } = this.state;
    if (deliveries.length === 0) {
      return 0;
    }
    return deliveries.map(delivery => delivery.totalDeliveryValue()).reduce((acc, delivery) => Number(acc) + Number(delivery));
  }

  calculateTotal = () => {
    const { deliveries } = this.state;

    let onlyItems = [];
    deliveries.filter(delivery => delivery._destroy === 0).map(delivery => (
      delivery.items.map(item => onlyItems.push({ ...item, deliveryUuid: delivery.uuid }))
    ));

    const groupedItemsObject = _.groupBy(onlyItems, 'purchaseOrderItemId');

    const groupItemsArray = this.returnOnlyObjectValue(groupedItemsObject);

    onlyItems = [];

    for (let i = 0; i < groupItemsArray.length; i += 1) {
      for (let j = 0; j < groupItemsArray[i].length; j += 1) {
        groupItemsArray[i][j].total = this.sumGoodItemsValue(groupItemsArray, i, j);
        onlyItems.push(groupItemsArray[i][j]);
      }
    }

    const newDeliveries = deliveries.map((delivery) => {
      const deliveryItems = onlyItems.filter(item => item.deliveryUuid === delivery.uuid);
      const newDelivery = delivery;
      newDelivery.items = deliveryItems;

      return newDelivery;
    });

    this.setState({ deliveries: newDeliveries });
  }

  returnOnlyObjectValue = (obj) => {
    const arrayOfObject = [];
    for (const value of Object.values(obj)) {
      arrayOfObject.push(value.reverse());
    }
    return arrayOfObject;
  }

  sumGoodItemsValue = (arrayOfObjects, firstIndex, endIndex) => {
    const items = arrayOfObjects[firstIndex];
    let sum = 0;
    for (let i = 0; i <= endIndex; i += 1) {
      if (items[i].goodItemsCount !== '') {
        sum += Number(items[i].goodItemsCount);
      }
    }
    return sum;
  }

  exchangedTotalValue = () => (
    Math.round(this.totalValue() * 100) / 100
  )

  render() {
    const {
      state: {
        deliveries, isLoading,
      },
      props: {
        currentUser, isDeliveryModalOpen, closeDeliveryModal, companyCurrency,
        exchangeRate, editableDelivery, dateFormat, numberFormat,
      },
    } = this;
    const { thousands_separator, decimal_mark } = numberFormat;
    const amountFormat = { delimiter: thousands_separator, separator: decimal_mark, precision: 2 };

    const htmlClasses = isLoading ? ' has-loading' : '';

    return (
      <div className={`modal modal-table sub-modal ${isDeliveryModalOpen ? 'active' : ''}`}>
        <div className="modal-wrapper">
          <div className={`modal-window window window-form${htmlClasses}`}>
            <div className="window-header modal-header static-height">
              <span>
                <h1>
                  {I18n.t('purchase_orders.form.deliveries.deliveries')}
                </h1>
              </span>
              <span className="actions">
                { editableDelivery
                  ? (
                    <a className="link" onClick={this.addNewDelivery}>
                      {I18n.t('purchase_orders.form.items.add_new_delivery')}
                    </a>
                  ) : null
                }
              </span>
            </div>
            <div className="window-content">
              <Loader />
              <div className="table-fluid table-column-9 show-last">
                <input type="radio" name="mobile-table_2" id="mobile-column_2_1" defaultChecked="true" />
                <input type="radio" name="mobile-table_2" id="mobile-column_2_2" />
                <input type="radio" name="mobile-table_2" id="mobile-column_2_3" />
                <input type="radio" name="mobile-table_2" id="mobile-column_2_4" />
                <input type="radio" name="mobile-table_2" id="mobile-column_2_5" />
                <input type="radio" name="mobile-table_2" id="mobile-column_2_6" />
                <input type="radio" name="mobile-table_2" id="mobile-column_2_7" />
                <input type="radio" name="mobile-table_2" id="mobile-column_2_8" />
                <input type="radio" name="mobile-table_2" id="mobile-column_2_9" />
                <div className="table-header with-filters">
                  <div className="table-nav">
                    <label htmlFor="mobile-column_2_1" />
                    <label htmlFor="mobile-column_2_2" />
                    <label htmlFor="mobile-column_2_3" />
                    <label htmlFor="mobile-column_2_4" />
                    <label htmlFor="mobile-column_2_5" />
                    <label htmlFor="mobile-column_2_6" />
                    <label htmlFor="mobile-column_2_7" />
                    <label htmlFor="mobile-column_2_8" />
                    <label htmlFor="mobile-column_2_9" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                    <div className="dot" />
                  </div>
                </div>
                <table>
                  {
                    deliveries.filter(delivery => delivery._destroy === 0).map(delivery => (
                      <React.Fragment key={delivery.uuid}>
                        <DeliveryRow
                          updateDeliveries={this.updateDeliveries}
                          exchangeRate={exchangeRate}
                          delivery={delivery}
                          key={`delivery-row-${delivery.uuid}`}
                          editableDelivery={editableDelivery}
                          dateFormat={dateFormat}
                          numberFormat={numberFormat}
                        />
                        <tbody key={`delivery-head-${delivery.uuid}`} className="tbody-sub">
                          <tr className="thead fluid-not-mobile">
                            <th>{I18n.t('purchase_orders.form.deliveries.number')}</th>
                            <th>{I18n.t('purchase_orders.form.deliveries.description')}</th>
                            <th className="colored-pass">{I18n.t('purchase_orders.form.deliveries.pass')}</th>
                            <th className="colored-fail">{I18n.t('purchase_orders.form.deliveries.fail')}</th>
                            <th>{I18n.t('purchase_orders.form.deliveries.total')}</th>
                            <th className="number price">{I18n.t('purchase_orders.form.deliveries.unit_price')}</th>
                            <th>{I18n.t('purchase_orders.form.deliveries.units')}</th>
                            <th>{I18n.t('purchase_orders.form.deliveries.last_modify')}</th>
                            <th className="number price">{I18n.t('purchase_orders.form.deliveries.value')}</th>
                            <th className="number price">{`${I18n.t('purchase_orders.form.deliveries.value')}(${companyCurrency})`}</th>
                            <th colSpan={2} />
                          </tr>
                          <tr className="fluid-only-mobile">
                            <th>{I18n.t('purchase_orders.form.deliveries.number')}</th>
                            <th>{I18n.t('purchase_orders.form.deliveries.description')}</th>
                            <th>{I18n.t('purchase_orders.form.deliveries.total')}</th>
                            <th className="number price">{I18n.t('purchase_orders.form.deliveries.unit_price')}</th>
                            <th>{I18n.t('purchase_orders.form.deliveries.units')}</th>
                            <th>{I18n.t('purchase_orders.form.deliveries.last_modify')}</th>
                            <th className="number price">{I18n.t('purchase_orders.form.deliveries.value')}</th>
                            <th className="number price">{`${I18n.t('purchase_orders.form.deliveries.value')}(${companyCurrency})`}</th>
                            <th />
                            <th className="colored-pass fluid-always-display">{I18n.t('purchase_orders.form.deliveries.pass')}</th>
                            <th className="colored-fail fluid-always-display">{I18n.t('purchase_orders.form.deliveries.fail')}</th>
                            <th className="not-sticky" />
                          </tr>
                          {
                            delivery.items.map(item => (
                              <DeliveryItem
                                updateDeliveries={this.updateDeliveries}
                                item={item}
                                key={item.uuid}
                                delivery={delivery}
                                currentUser={currentUser}
                                exchangeRate={exchangeRate}
                                editableDelivery={editableDelivery}
                                numberFormat={numberFormat}
                              />
                            ))
                          }
                          <DeliveryDocumentRow
                            deleteDelivery={this.deleteDelivery}
                            delivery={delivery}
                            updateDeliveries={this.updateDeliveries}
                            key={`delivery-document-${delivery.uuid}`}
                            editableDelivery={editableDelivery}
                          />
                        </tbody>
                      </React.Fragment>
                    ))
                  }
                  <tfoot>
                    <tr className="fluid-not-mobile">
                      <td colSpan="7" className="not-sticky fluid-always-display" />
                      <td colSpan="3" className="with-as fluid-always-display">
                        <div className="as-th">
                          {I18n.t('purchase_orders.form.items.delivered_value')}
                        </div>
                        <div className="as-td total">
                          {I18n.toNumber(this.exchangedTotalValue(), amountFormat)}
                        </div>
                      </td>
                      <td colSpan="2" className="fluid-always-display" />
                    </tr>
                    <tr className="tr-fluid-show-1 fluid-only-mobile">
                      <td colSpan="12" className="with-as fluid-always-display not-sticky">
                        <div className="as-th">
                          {I18n.t('purchase_orders.form.items.delivered_value')}
                        </div>
                        <div className="as-td total">
                          {I18n.toNumber(this.exchangedTotalValue(), amountFormat)}
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
            <div className="window-footer modal-footer">
              <div className="items items-end center">
                <div className="with-as to-left">
                  <span className="as-th">{I18n.t('purchase_orders.form.items.delivered_value')}</span>
                  <span className="as-td total">
                    {I18n.toNumber(this.exchangedTotalValue(), amountFormat)}
                  </span>
                </div>
                <label
                  className="button inverse button-mute hide-on-complete"
                  onClick={() => closeDeliveryModal()}
                >
                  {I18n.t('purchase_orders.form.deliveries.close')}
                </label>
                { editableDelivery
                  ? (
                    <a
                      onClick={this.saveNewDeliveries}
                      className="button button-primary hide-on-complete"
                    >
                      {I18n.t('purchase_orders.form.deliveries.save_changes')}
                    </a>
                  ) : null
                }
              </div>
            </div>
          </div>
        </div>
        <label className="modal-backdrop" onClick={() => closeDeliveryModal()} />
      </div>
    );
  }
}

export default DeliveryModal;
