import React from 'react';
import uuid from 'uuid/v4';
import ApproveActions from '@/pages/myPurchases/components/common/approve_actions';
import Tooltip from '@/components/uiElements/Tooltip';
import useTooltip from '@/hooks/useTooltip';
import Warning from '@/icons/Warning';
import Comment from '@/icons/Comment';
import EllipsisCell from '../../common/ellipsis_cell';

export default function PurchaseOrderRow({
  purchaseOrder, columnView, currentCompany, accountSettings, projectSettings,
}) {
  const [isTooltipOpen, tooltipTriggerRef] = useTooltip();
  const purchaseOrderUrl = `/purchase_orders/${purchaseOrder.id}/edit`;
  const deviation = purchaseOrder.deviation > 0
    ? I18n.toNumber(purchaseOrder.deviation, { delimiter: ' ', precision: 2 })
    : null;

  return (
    <div className="tr">
      { columnView.poNumber
        && (
          <EllipsisCell url={purchaseOrderUrl} text={purchaseOrder.poNumber} className="sticky-left" />
        )
      }
      { columnView.parentPo && currentCompany.parentPoEnabled
        && (
          <EllipsisCell url={purchaseOrderUrl} text={purchaseOrder.parentPoNumber} />
        )
      }
      { columnView.dateRaised
        && (
          <EllipsisCell url={purchaseOrderUrl} text={purchaseOrder.raisedDate} className="sticky-left" />
        )
      }
      { columnView.deliveryDate
        && (
          <EllipsisCell url={purchaseOrderUrl} text={purchaseOrder.deliveryDate} />
        )
      }
      { columnView.expectedDeliveryDate
        && (
          <EllipsisCell url={purchaseOrderUrl} text={purchaseOrder.expectedDeliveryDate} />
        )
      }
      { columnView.confirmedDeliveryDate
        && (
          <EllipsisCell url={purchaseOrderUrl} text={purchaseOrder.confirmedDeliveryDate} />
        )
      }
      { columnView.supplier
        && (
          <EllipsisCell url={purchaseOrderUrl} text={`${purchaseOrder.supplierName} (${purchaseOrder.supplierId})`} />
        )
      }
      { columnView.budget
        && (
          <EllipsisCell url={purchaseOrderUrl} text={`${purchaseOrder.budgetNumber || ''} ${purchaseOrder.budgetName || ''}`} />
        )
      }
      { columnView.description
        && (
          <EllipsisCell url={purchaseOrderUrl} text={purchaseOrder.description} />
        )
      }
      { columnView.department
        && (
          <EllipsisCell url={purchaseOrderUrl} text={`${purchaseOrder.departmentId || ''} ${purchaseOrder.departmentName || ''}`} />
        )
      }
      { columnView.project && projectSettings && projectSettings.active
        && (
          <EllipsisCell url={purchaseOrderUrl} text={`${purchaseOrder.projectNumber || ''} ${purchaseOrder.projectName || ''}`} />
        )
      }
      { columnView.poValue
        && (
          <a href={purchaseOrderUrl} className="td number price bold">
            {purchaseOrder.totalAmountInPoCurrency}
          </a>
        )
      }
      { columnView.currency
        && (
          <a href={purchaseOrderUrl} className="td number price bold">
            {purchaseOrder.currency}
          </a>
        )
      }
      { columnView.poValueInCurrency
        && (
          <a href={purchaseOrderUrl} className="td number price bold">
            {purchaseOrder.totalAmount}
          </a>
        )
      }
      { columnView.notInvoiced
        && (
          <a href={purchaseOrderUrl} className="td number price bold">
            {purchaseOrder.notInvoiced}
          </a>
        )
      }
      { columnView.invoiced
        && (
          <a href={purchaseOrderUrl} className="td number price bold" data-beyond={deviation}>
            {purchaseOrder.invoiced}
          </a>
        )
      }
      { columnView.committed
        && (
          <a href={purchaseOrderUrl} className="td number price bold">
            {purchaseOrder.committed}
          </a>
        )
      }
      { columnView.status
        && (
          <a href={purchaseOrderUrl} className="td has-status">
            <span className={`status capitalize ${purchaseOrder.status}`}>
              {I18n.t(`purchase_orders.statuses.${purchaseOrder.status}`)}
            </span>
          </a>
        )
      }
      { columnView.createdBy
        && (
          <a href={purchaseOrderUrl} className="td with-avatars">
            <div className="avatars no-wrap">
              <span className="avatar has-tooltip" tabIndex="-1">
                <img src={purchaseOrder.userAvatar} />
                <span className="tooltip center">
                  {purchaseOrder.userFullName}
                </span>
              </span>
            </div>
          </a>
        )
      }
      { columnView.approvedBy
        && (
          <a href={purchaseOrderUrl} className="td with-avatars">
            <div className="avatars no-wrap">
              <ApproveActions approveActions={purchaseOrder.approveActions} />
            </div>
          </a>
        )
      }
      { columnView.customer && currentCompany.customPoEnabled
        && (
          <EllipsisCell url={purchaseOrderUrl} text={purchaseOrder.customerName} />
        )
      }
      { columnView.purchaseType && currentCompany.customPoEnabled
        && (
          <EllipsisCell url={purchaseOrderUrl} text={purchaseOrder.type} />
        )
      }
      { columnView.salesOrder && currentCompany.customPoEnabled
        && (
          <EllipsisCell url={purchaseOrderUrl} text={purchaseOrder.salesOrder} />
        )
      }
      { columnView.account && accountSettings && accountSettings.active
        && (
          <EllipsisCell url={purchaseOrderUrl} text={purchaseOrder.accountName} />
        )
      }
      {purchaseOrder.customFields.map(customField => (
        columnView[_.camelCase(customField.slug)]
          && (<EllipsisCell key={uuid()} url={purchaseOrderUrl} text={customField.value} />)
      ))}

      {
        columnView.comments
        && (
          <a href={purchaseOrderUrl} className="td column-fit nowrap">
            {purchaseOrder.commentsCount > 0 ? (
              <div className="has-tooltip center" data-tooltip={purchaseOrder.commentsCount}>
                <Comment style={{ width: '24px', height: '24px' }} />
              </div>
            ) : null}
          </a>
        )
      }

      { purchaseOrder.outdated && (
        <div className="td actions ct-relative">

          <a ref={tooltipTriggerRef} href={purchaseOrderUrl}>
            <Warning className="ct-h-5.5 ct-w-5.5 ct-text-orange" />
          </a>

          { isTooltipOpen && (
          <div className="ct-absolute ct-top-1 1300:ct-right-[calc(1.7rem+3px)] ct-right-[calc(1rem+3px)] ct-translate-y-[-100%] ct-z-20">
            <Tooltip isOpen={isTooltipOpen} direction="BOTTOM_RIGHT" text={I18n.t('errors.purchase_order.outdated_title')} className="ct-w-fit" />
          </div>
          )}

        </div>
      )}

    </div>
  );
}
