import React from 'react';
import Table from '../shared/table';
import InvoiceTableHead from './shared/invoice_table_head';
import PurchaseOrderFilters from './shared/purchase_order_filters';
import PaginateWithDots from '../item_list/paginate_with_dots';
import AccountingsService from '../../services/accountings_service';
import InvoiceOrderRow from './invoices/invoice_order_row';
import MobileFilters from './shared/mobile_filters';
import PurchaseOrderAttach from './invoices/purchase_order_attach';
import BudgetCategoryAttach from './invoices/budget_category_attach';
import BulkActions from './invoices/bulk_actions';
import InvoiceDetach from './invoices/invoice_detach';
import Loader from '../loader';
import AutoMatchModal from './shared/auto_match_modal';
import IntegrationsService from '../../services/integrations_service';
import * as toast from '../shared/toast';
import * as swipe from '../shared/swipe';

class AccountingTransactions extends Table {
  constructor(props) {
    super(props);

    const urlFilters = this.urlParamsSetup();
    const page = urlFilters.page ? parseInt(urlFilters.page) : 1;

    this.filters = urlFilters.filters;
    delete this.filters.show_completed;
    this.order = urlFilters.order;

    this.state = {
      invoices: [],
      pages: 1,
      currentPage: page,
      filters: [],
      filterActions: [],
      filtersComponentKey: 1,
      openAttachToPurchaseOrder: false,
      openAttachToBudgetCategory: false,
      openBulkActions: false,
      allInvoicesAttached: false,
      attachInvoices: [],
      openDetach: false,
      total: 0,
      autoMatchModalOpen: false,
      supplierId: null,
      isProcessing: false,
      isIntegrated: false,
    };

    IntegrationsService
      .active().then(({ jobProcessing, id }) => {
        this.setState({
          isProcessing: jobProcessing,
          isIntegrated: true,
        });
        this.integrationId = id;
        if (jobProcessing) this.subscribeIntegration(this.integrationId);
      })
      .catch(errors => errors.response.data);
  }

  getInvoices = () => {
    const { updateTotalCounts } = this.props;
    swipe.triggerSwipeEvent();
    this.onTableLoading();
    AccountingsService.invoices(window.location.search).then(({
      invoices, pages, filters, total, transactionsCount, purchaseOrdersCount,
      invoicesCount,
    }) => {
      this.setState({
        invoices,
        pages,
        total,
        filters,
        filterActions: filters ? Object.keys(filters) : [],
      });
      this.onTableLoad();
      updateTotalCounts(transactionsCount, purchaseOrdersCount, invoicesCount);
    });
  }

  componentDidMount() {
    this.getInvoices();
  }

  onRefresh = () => {
    this.setState({ isProcessing: true });

    IntegrationsService
      .authorized().then(({ authorized }) => {
        if (authorized) {
          this.subscribeIntegration(this.integrationId);
          IntegrationsService
            .synchronizeStart().catch(() => {
              toast.errorToast(I18n.t('settings.integrations.new.setup.error_save'));
            });
        } else {
          toast.errorToast(I18n.t('settings.integrations.common.unauthorized'));
          this.setState({ isProcessing: false });
        }
      });
  }

  subscribeIntegration = (id) => {
    this.subscription = App.cable.subscriptions.create(
      { channel: 'IntegrationsChannel', integration: id },
      {
        connected: () => {
          IntegrationsService
            .active()
            .then(({ jobProcessing }) => {
              this.setState({ isProcessing: jobProcessing });
            });
        },
        received: (data) => {
          if (data.action == 'refresh_all') {
            if (data.authorized) {
              this.setState({
                isProcessing: false,
              });
              this.filter();
              toast.successToast(I18n.t('settings.integrations.common.synchronized'));
            } else {
              this.setState({
                isProcessing: false,
              });
              toast.errorToast(I18n.t('settings.integrations.common.unauthorized'));
            }
            this.subscription.unsubscribe();
          }
        },
      },
    );
  }

  openAutoMatchModal = () => {
    this.setState({ autoMatchModalOpen: true });
  }

  closeAutoMatchModal = () => {
    this.setState({ autoMatchModalOpen: false });
  }

  headers = () => (
    [
      { name: 'date', translation: 'purchase_orders.partials.invoices_body.date' },
      { name: 'period', translation: 'purchase_orders.partials.invoices_body.period' },
      { name: 'supplier', translation: 'purchase_orders.supplier' },
      { name: 'po_number', translation: 'purchase_orders.number' },
      { name: 'supplier_invoice', translation: 'purchase_orders.partials.invoices_body.supplier_invoice' },
      { name: 'invoice_number', translation: 'purchase_orders.partials.invoices_body.invoice' },
      { name: 'account', translation: 'purchase_orders.partials.invoices_body.account' },
      { name: 'department', translation: 'purchase_orders.partials.invoices_body.department' },
      { name: 'project', translation: 'purchase_orders.partials.invoices_body.project' },
      { name: 'description', translation: 'purchase_orders.partials.invoices_body.description' },
      { name: 'supplier_invoice_reference', translation: 'purchase_orders.partials.invoices_body.reference' },
      { name: 'budget_number', translation: 'purchase_orders.partials.invoices_body.budget_number' },
      { name: 'budget_name', translation: 'purchase_orders.partials.invoices_body.budget_name' },
      { name: 'value', translation: 'purchase_orders.partials.invoices_body.ex_vat', class: 'number price' },
    ]
  )

  filter = (additionalState = {}, page = 1) => {
    const { updateTotalCounts } = this.props;
    const attributes = {
      custom: Object.keys(this.filters).map(key => ({ name: key, value: this.filters[key] })),
      page,
      order: this.order,
    };

    this.onTableLoading();

    AccountingsService.invoicesFilter(attributes).then(({
      invoices, pages, query, total, transactionsCount, purchaseOrdersCount,
      invoicesCount,
    }) => {
      window.history.pushState({}, 'Accounting', query);
      this.setState({
        invoices,
        pages,
        total,
        currentPage: 1,
        openBulkActions: false,
        attachInvoices: [],
        allInvoicesAttached: false,
        ...additionalState,
      });
      this.onTableLoad();
      updateTotalCounts(transactionsCount, purchaseOrdersCount, invoicesCount);
    });
  }

  filterWithCurrentPage = () => {
    this.filter({}, this.state.currentPage);
  }

  onFilter = ({ name, value }) => {
    const stringValue = value.toString();

    if (this.filters[name]) this.filters[name].push(stringValue);
    else this.filters[name] = [stringValue];

    this.filter();
  }

  onStateFilter = ({ value }) => {
    const stringValue = value.toString();

    this.filters.state = stringValue;

    this.filter();
  }

  onAttachToPurchaseOrder = (invoices, supplierId) => {
    this.setState({
      openAttachToPurchaseOrder: !this.state.openAttachToPurchaseOrder,
      attachInvoices: invoices,
      supplierId,
    });
  }

  onAttachToBudget = (invoices) => {
    this.setState({
      openAttachToBudgetCategory: !this.state.openAttachToBudgetCategory,
      attachInvoices: invoices,
    });
  }

  onRemove = (invoices) => {
    const assignedInvoices = invoices || this.state.attachInvoices;

    AccountingsService.invoicesBulkDestroy((assignedInvoices.map(invoice => invoice.id))).then(() => {
      this.filterWithCurrentPage();
    }).catch(() => {
      toast.errorToast(I18n.t('commons.messages.error'));
    });
  }

  onRestore = (invoices) => {
    const assignedInvoices = invoices || this.state.attachInvoices;

    AccountingsService.invoicesBulkRestore((assignedInvoices.map(invoice => invoice.id))).then(() => {
      this.filterWithCurrentPage();
    }).catch(() => {
      toast.errorToast(I18n.t('commons.messages.error'));
    });
  }

  onToggleAttachPoSettings = () => {
    this.setState({
      openAttachToPurchaseOrder: !this.state.openAttachToPurchaseOrder,
    });
  }

  onToggleAttachBudgetCategorySettings = () => {
    this.setState({
      openAttachToBudgetCategory: !this.state.openAttachToBudgetCategory,
    });
  }

  onToggleBulkActions = () => {
    const { openBulkActions, invoices } = this.state;

    this.setState({
      openBulkActions: !openBulkActions,
      attachInvoices: [],
      allInvoicesAttached: false,
      invoices: invoices.map((invoice) => {
        const newInvoice = invoice;
        newInvoice.selected = false;
        return newInvoice;
      }),
    });
  }

  onToggleDetach = (invoices) => {
    const assignedInvoices = invoices || this.state.attachInvoices;

    this.setState({
      openDetach: !this.state.openDetach,
      attachInvoices: assignedInvoices,
    });
  }

  onBulkSelect = (selectedInvoice) => {
    const attachedInvoices = [];

    const invoices = this.state.invoices.map((invoice) => {
      if (invoice.id === selectedInvoice.id) {
        const selected = !invoice.selected;
        const newInvoice = invoice;
        newInvoice.selected = selected;
        if (selected) attachedInvoices.push(newInvoice);
        return newInvoice;
      }
      if (invoice.selected) attachedInvoices.push(invoice);
      return invoice;
    });

    const openBulkActions = attachedInvoices.length > 0;

    this.setState({
      openBulkActions,
      invoices,
      attachInvoices: attachedInvoices,
      allInvoicesAttached: false,
    });
  }

  onBulkSelectAll = () => {
    const { allInvoicesAttached, invoices } = this.state;
    const assignAll = !allInvoicesAttached;

    this.setState({
      openBulkActions: assignAll,
      invoices: invoices.map((invoice) => {
        const newInvoice = invoice;
        newInvoice.selected = assignAll;
        return newInvoice;
      }),
      attachInvoices: assignAll ? invoices : [],
      allInvoicesAttached: assignAll,
    });
  }

  decoratedTotal = () => {
    const { total } = this.state;

    const nf = new Intl.NumberFormat('no');
    return nf.format(total);
  }

  render() {
    const {
      pages, currentPage, filters, attachInvoices, filterActions,
      filtersComponentKey, invoices, openAttachToPurchaseOrder,
      openAttachToBudgetCategory, openBulkActions, allInvoicesAttached,
      openDetach, isLoading, autoMatchModalOpen, supplierId,
      isProcessing, isIntegrated,
    } = this.state;

    const {
      onToggleTransactionModal, companyCurrency, currentUser, integrationType, dateFormat, amountFormat,
    } = this.props;
    const dotsNumber = this.headers().length + 4;
    const htmlClasses = isLoading ? ' has-loading' : '';

    return (
      <React.Fragment>
        <div className="tab-pages tile-content">
          {openAttachToBudgetCategory
            ? (
              <BudgetCategoryAttach
                onToggleAttachBudgetCategorySettings={this.onToggleAttachBudgetCategorySettings}
                filter={this.filterWithCurrentPage}
                invoices={attachInvoices}
              />
            ) : null}
          {openDetach
            ? (
              <InvoiceDetach
                onToggleDetach={this.onToggleDetach}
                filter={this.filterWithCurrentPage}
                invoices={attachInvoices}
              />
            ) : null}
          {openAttachToPurchaseOrder
            ? (
              <PurchaseOrderAttach
                onToggleAttachPoSettings={this.onToggleAttachPoSettings}
                filter={this.filterWithCurrentPage}
                invoices={attachInvoices}
                currency={companyCurrency}
                supplierId={supplierId}
                amountFormat={amountFormat}
              />
            ) : null}
          <div className="page active">
            {!openBulkActions
              ? (
                <PurchaseOrderFilters
                  filter={this.filter}
                  onFilter={this.onFilter}
                  onStateFilter={this.onStateFilter}
                  onRemoveFilter={this.onRemoveFilter}
                  onRemoveStateFilter={this.onRemoveStateFilter}
                  onDateFilter={this.onDateFilter}
                  onQueryFilter={this.onQueryFilter}
                  clearDate={this.clearDate}
                  currentFilters={this.filters}
                  filters={filters}
                  filterActions={filterActions}
                  integrationRefesh={currentUser.isAdmin}
                  key={`availableFiltersTransactions${filtersComponentKey}`}
                  openAutoMatchModal={this.openAutoMatchModal}
                  onRefresh={this.onRefresh}
                  isProcessing={isProcessing}
                  isIntegrated={isIntegrated}
                  refreshInvoices={this.getInvoices}
                  dateFormat={dateFormat}
                  importTransactions
                />
              ) : null}
            <div className="has-bulk">
              {openBulkActions
                ? (
                  <BulkActions
                    invoices={attachInvoices}
                    filters={this.filters}
                    onToggleAttachBudgetCategorySettings={this.onToggleAttachBudgetCategorySettings}
                    onToggleAttachPoSettings={this.onToggleAttachPoSettings}
                    onToggleBulkActions={this.onToggleBulkActions}
                    onToggleDetach={this.onToggleDetach}
                    onRemove={this.onRemove}
                    onRestore={this.onRestore}
                  />
                ) : null}
              <div className={`table-fluid table-scrollable table-column-${dotsNumber}${htmlClasses}`}>
                <Loader />
                {this._renderInputs(dotsNumber)}
                <div className="table-header with-filters">
                  {filterActions.length > 0
                    ? (
                      <MobileFilters
                        onFilter={this.onFilter}
                        onStateFilter={this.onStateFilter}
                        onRemoveFilter={this.onRemoveFilter}
                        onRemoveStateFilter={this.onRemoveStateFilter}
                        onDateFilter={this.onDateFilter}
                        clearDate={this.clearDate}
                        currentFilters={this.filters}
                        filters={filters}
                        filterActions={filterActions}
                        filtersPillsKey={`availableMobileFilters${filtersComponentKey}`}
                      />
                    ) : null}
                  <div className="table-nav">
                    {this._renderLabels(dotsNumber)}
                    {this._renderDots(dotsNumber)}
                  </div>
                </div>
                <div className="table table-sortable">
                  <div className="colgroup">
                    {this._renderColls(dotsNumber)}
                  </div>
                  <InvoiceTableHead
                    onOrder={this.onOrder}
                    order={this.order}
                    headers={this.headers()}
                    allInvoicesAttached={allInvoicesAttached}
                    onBulkSelectAll={this.onBulkSelectAll}
                  />
                  <div className="tbody">
                    {!isLoading && invoices.map(invoice => (
                      <InvoiceOrderRow
                        invoice={invoice}
                        onBulkSelect={this.onBulkSelect}
                        onBulkRemove={this.onBulkRemove}
                        key={invoice.id}
                        onAttachToPurchaseOrder={this.onAttachToPurchaseOrder}
                        onAttachToBudget={this.onAttachToBudget}
                        onRemove={this.onRemove}
                        onRestore={this.onRestore}
                        onToggleDetach={this.onToggleDetach}
                        onToggleTransactionModal={onToggleTransactionModal}
                        companyCurrency={companyCurrency}
                      />
                    ))}
                  </div>
                  <div className="tfoot">
                    <div className="td visible" />
                    <div className="td visible" />
                    <div className="td mobile-hide" />
                    <div className="td mobile-hide" />
                    <div className="td mobile-hide" />
                    <div className="td mobile-hide" />
                    <div className="td mobile-hide" />
                    <div className="td mobile-hide" />
                    <div className="td mobile-hide" />
                    <div className="td mobile-hide" />
                    <div className="td mobile-hide" />
                    <div className="td mobile-hide" />
                    <div className="td mobile-hide" />
                    <div className="td mobile-hide" />
                    <div className="td th number">{I18n.t('purchase_orders.invoices.total_value')}</div>
                    <div className="td total number price bold">{this.decoratedTotal()}</div>
                    <div className="td mobile-hide mark" />
                    <div className="td mobile-hide mark" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="tile-footer">
          <PaginateWithDots onPageChange={this.onPageChange} numberPages={pages} selected={currentPage} />
        </div>
        {autoMatchModalOpen
          ? (
            <AutoMatchModal
              isOpen={autoMatchModalOpen}
              close={this.closeAutoMatchModal}
              integrationType={integrationType}
              onRefresh={this.onRefresh}
            />
          ) : null
        }
      </React.Fragment>
    );
  }
}

export default AccountingTransactions;
