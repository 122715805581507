import React from 'react';
import PropTypes from 'prop-types';

class PredefinedItem extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    quantity: PropTypes.number,
    price: PropTypes.number,
    category_id: PropTypes.number,
    amountFormat: PropTypes.object,
  }

  state = {
    checked: false,
  }

  constructor(props) {
    super(props);

    this.assignItemToPurchaseOrderItems = this.assignItemToPurchaseOrderItems.bind(this);
    this.clearCheckedStatus = this.clearCheckedStatus.bind(this);
  }

  formatedPrice = () => {
    const { item: { price }, amountFormat } = this.props;
    if (price == '') return '';
    return I18n.toNumber(price, amountFormat);
  }

  assignItemToPurchaseOrderItems() {
    const { state: { checked }, props: { assignItemAction } } = this;
    this.setState({ checked: !checked });
    assignItemAction(this);
  }

  clearCheckedStatus() {
    this.setState({ checked: false });
  }

  render() {
    const { item } = this.props;
    const { checked } = this.state;

    return (
      <label>
        <div className={`list-item${checked ? ' item-checked' : ''}`}>
          <div className="list-item-content grid gutter-2">
            <div className="cell-auto">
              <div className="checkbox" />
            </div>
            <div className="cell">
              <p>{item.name}</p>
              <p>{item.sku}</p>
            </div>
            <div className="cell">
              <p>{this.formatedPrice()}</p>
            </div>
          </div>
        </div>
        <input className="hide" type="checkbox" value="false" checked={checked} onChange={this.assignItemToPurchaseOrderItems} />
      </label>
    );
  }
}

export default PredefinedItem;
