import React from 'react';
import TransactionRow from './transaction_row';
import TransactionsTableHead from './transactions_table_head';

function TableBodyTransaction({
  transactions, onAttachToPurchaseOrder, onAttachToBudget,
  editable, state, currentUser, setAttachInfoModalOpen, amountFormat,
}) {
  return (
    <tbody className="expandable">
      <tr>
        <td className="with-table no-f" colSpan="13">
          <table className="table-fluid inside-window">
            <TransactionsTableHead />
            <tbody>
              {
                transactions.length > 0
                  ? transactions.map(transaction => (
                    <TransactionRow
                      key={transaction.uuid}
                      transaction={transaction}
                      onAttachToPurchaseOrder={onAttachToPurchaseOrder}
                      onAttachToBudget={onAttachToBudget}
                      editable={editable}
                      state={state}
                      currentUser={currentUser}
                      setAttachInfoModalOpen={setAttachInfoModalOpen}
                      amountFormat={amountFormat}
                    />
                  ))
                  : (
                    <tr>
                      <td colSpan="11" className="empty"><span>No Transactions</span></td>
                    </tr>
                  )
              }
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  );
}

export default TableBodyTransaction;
