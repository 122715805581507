import { parseDate, formatNumber } from "@/tools";
import React, { useEffect, useState, forwardRef } from "react";
import { Calendar, CheckLg, TrashFill, XLg } from "react-bootstrap-icons";
import IconButton from "@/components/uiElements/old/IconButton";
import TextField from "@/components/uiElements/old/TextField";
import { useForm, Controller } from "react-hook-form";
import { PAYMENT_METHODS } from "@/config/constants";
import _ from "lodash";
import DatePicker from "react-datepicker-latest";
import { useEditModalContext } from "../context";

function PaymentRow({ payment, acceptPayment, removePayment, sumOfItems, numberFormat, dateFormat }) {
  const { currentEdditingPayment, setCurrentEdditingPayment } =
    useEditModalContext();
  const [editMode, setEditMode] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const {
    paymentMethod,
    paymentDate,
    usageRate,
    value,
    currency,
    exchangeRate,
    valueInCompanyCurrency,
    purchaseOrderInvoiceId,
  } = payment;

  const defaultValues = {
    paymentDate,
    value,
    usageRate: _.round(usageRate, 2),
  };

  const { control, getValues, register, setValue, reset } = useForm({
    defaultValues: defaultValues,
    mode: "all",
  });

  useEffect(() => {
    if (editMode) setCurrentEdditingPayment(payment);
  }, [editMode]);

  useEffect(() => {
    if (editMode && payment !== currentEdditingPayment) {
      setEditMode(false);
      reset({ ...defaultValues });
    }
  }, [currentEdditingPayment]);

  useEffect(() => {
    register("paymentDate");
    register("value");
    register("usageRate");
    reset({ ...defaultValues });
  }, [payment]);

  function trBackground() {
    if (purchaseOrderInvoiceId) return "ct-bg-green-light";
    if (paymentMethod === PAYMENT_METHODS.RESIDUAL) return "ct-bg-blue-lighter";

    return "ct-bg-white";
  }

  function setValueFromUsageRate(value) {
    setValue("value", (value * sumOfItems) / 100, { shouldTouch: false });
  }

  function setUsageRateFromValue(value) {
    setValue("usageRate", (value / sumOfItems) * 100, { shouldTouch: false });
  }

  function savePayment() {
    const values = getValues();
    acceptPayment(values, payment);

    setEditMode(false);
    setCurrentEdditingPayment(null);
  }

  function cancelEditMode() {
    setEditMode(false);
    setCurrentEdditingPayment(null);
  }

  function removePaymentRow() {
    removePayment(payment);
    setDeleteMode(false);
  }

  const rowActions = () => {
    if (purchaseOrderInvoiceId)
      return (
        <div className="ct-p-2 ct-text-gray ct-text-sm">
          <i className="icon-lock ct-text-lg" />
        </div>
      );

    if (
      paymentMethod === PAYMENT_METHODS.RESIDUAL ||
      paymentMethod === PAYMENT_METHODS.AUTOMATIC
    )
      return <></>;

    return (
      <div className="ct-flex ct-float-right ct-gap-x-2">
        <IconButton
          className="hover:ct-text-orange"
          icon={<i className="icon-edit ct-text-lg" />}
          onClick={() => setEditMode(true)}
        />
        <IconButton
          className="hover:ct-text-red"
          icon={<TrashFill />}
          onClick={() => setDeleteMode(true)}
        />
      </div>
    );
  };

  const deleteConfirmation = () => {
    if (!deleteMode) return <></>;

    return (
      <div className="ct-absolute ct-bg-white/90 ct-w-full ct-z-10 ct-h-[45px] ct-flex ct-justify-between ct-items-center ct-pl-5 ct-pr-2">
        <div className="ct-font-bold ct-text-black ct-text-sm">
          {I18n.t(
            "frontend.payment_plan.components.payment_row.delete_confirmation"
          )}
        </div>
        <div className="float-right">
          <div className="ct-flex ct-float-right ct-gap-x-2">
            <IconButton
              success
              text={I18n.t("commons.actions.ok")}
              icon={<CheckLg size={18} />}
              onClick={() => removePaymentRow()}
            />
            <IconButton
              warning
              icon={<XLg size={18} />}
              onClick={() => setDeleteMode(false)}
            />
          </div>
        </div>
      </div>
    );
  };

  const CustomDatePickerInput = forwardRef(({ value, onClick }, ref) => {
    return (
      <TextField
        value={value}
        onClick={onClick}
        rightContent={<Calendar />}
        ref={ref}
        transparent
        className="ct-w-32"
      />
    );
  });

  const { thousandsSeparator, decimalMark } = numberFormat;
  const amountFormat = { delimiter: thousandsSeparator, separator: decimalMark, precision: 2 }

  if (editMode)
    return (
      <tr className="ct-bg-gray-lighter ct-text-black">
        <td className="ct-capitalize ct-pl-5">{I18n.t(`frontend.payment_plan.method.${paymentMethod}`)}</td>
        <td>
          <Controller
            name="paymentDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                className="no-default-icon"
                dateFormat={dateFormat}
                suffixIcon={null}
                selected={new Date(field.value)}
                onChange={(date) => field.onChange(date.toISOString())}
                customInput={<CustomDatePickerInput />}
              />
            )}
          />
        </td>
        <td>
          <Controller
            name="usageRate"
            control={control}
            render={({ field }) => (
              <TextField
                type="number"
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  setValueFromUsageRate(e.target.value);
                }}
                rightContent="%"
                transparent
                className="ct-w-16"
              />
            )}
          />
        </td>
        <td>
          <Controller
            name="value"
            control={control}
            render={({ field }) => (
              <TextField
                type="number"
                {...field}
                onChange={(e) => {
                  field.onChange(e);
                  setUsageRateFromValue(e.target.value);
                }}
                transparent
                className="ct-w-20"
              />
            )}
          />
        </td>
        <td>{currency}</td>
        <td>{exchangeRate.toFixed(2)}</td>
        <td>{valueInCompanyCurrency}</td>
        <td colSpan="2">
          <div className="ct-flex ct-items-center ct-justify-between ct-min-w-[144px]">
            <div>{purchaseOrderInvoiceId}</div>
            <div className="ct-min-w-28">
              <div className="ct-flex ct-float-right ct-gap-x-2">
                <IconButton
                  success
                  text={I18n.t("commons.actions.done")}
                  icon={<CheckLg size={18} />}
                  onClick={() => savePayment()}
                />
                <IconButton
                  warning
                  icon={<XLg size={18} />}
                  onClick={() => cancelEditMode()}
                />
              </div>
            </div>
          </div>
        </td>
      </tr>
    );

  return (
    <>
      {deleteConfirmation()}
      <tr className={`${trBackground()} ct-text-black ct-relative`}>
        <td className="ct-capitalize ct-pl-5">{I18n.t(`frontend.payment_plan.method.${paymentMethod}`)}</td>
        <td className="ct-w-36">{paymentDate}</td>
        <td>
          {I18n.toNumber(usageRate, amountFormat)}
          <span className="ct-text-gray-cold ct-ml-2">%</span>
        </td>
        <td className="ct-text-right">{I18n.toNumber(value, amountFormat)}</td>
        <td className="ct-text-gray-cold">{currency}</td>
        <td>{I18n.toNumber(exchangeRate, amountFormat)}</td>
        <td className="ct-font-bold ct-text-right">
          {I18n.toNumber(valueInCompanyCurrency, amountFormat)}
        </td>
        <td colSpan="2">
          <div className="ct-flex ct-items-center ct-justify-between ct-min-w-[144px]">
            <div className="ct-font-bold">{purchaseOrderInvoiceId}</div>
            <div className="ct-min-w-28">{rowActions()}</div>
          </div>
        </td>
      </tr>
    </>
  );
}

export default PaymentRow;
