import PropTypes from 'prop-types';

export const dateFieldBasePropTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  disableRanges: PropTypes.bool,
  noInput: PropTypes.bool,
};

export const dateFilterFormat = {
  year: '2-digit',
  month: '2-digit',
  day: '2-digit',
};
