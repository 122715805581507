import React from 'react';
import Invoice from './invoices/invoice';
import TransactionModal from './modals/transaction_modal';
import PurchaseOrderAttach from '../accountings/purchase_orders/purchase_order_attach';
import Modal from '../shared/modal';
import InvoicesService from '../../services/invoices_service';
import InvoicedModal from './invoices/invoiced_modal';
import IntegrationRefresh from '../integrations/shared/integration_refresh';
import InvoiceModel from '../../models/invoice_model';


class Invoices extends React.Component {
  state = {
    invoices: [],
    isTransactionModalOpen: false,
    isTransactionsModalOpen: false,
    isInvoicedModalOpen: false,
    invoiceId: null,
  }

  componentDidMount() {
    const { invoices, numberFormat } = this.props;
    const { thousands_separator, decimal_mark } = numberFormat;
    this.amountFormat = { delimiter: thousands_separator, separator: decimal_mark, precision: 2 };
    const newInvoices = invoices.map(invoice => new InvoiceModel(invoice));
    this.setState({ invoices: newInvoices }, () => {
      this.sumInvoices();
      this.sumInvoicesInCurrency();
    });
  }

  openInvoicedModal = () => {
    this.setState({ isInvoicedModalOpen: true });
  }

  closeInvoicedModal = () => {
    this.setState({ isInvoicedModalOpen: false });
  }

  sumInvoices = () => {
    const { props: { setTotalInvoicesValue }, state: { invoices } } = this;
    let totalInvoicesValue = 0;
    invoices.map(invoice => (totalInvoicesValue += invoice.value));
    setTotalInvoicesValue(totalInvoicesValue);
  }

  sumInvoicesInCurrency = () => {
    const { props: { setTotalInvoicesValueInCurrency }, state: { invoices } } = this;
    let totalInvoicesValueInCurrency = 0;

    invoices.map(invoice => (totalInvoicesValueInCurrency += invoice.currencyValue));
    setTotalInvoicesValueInCurrency(totalInvoicesValueInCurrency);
  }

  reloadInvoices = (newInvoice) => {
    const { invoices } = this.state;
    const newInvoices = invoices.map(invoice => (invoice.id === newInvoice.id ? newInvoice : invoice));
    this.setState({ invoices: newInvoices });
    this.sumInvoices();
    this.sumInvoicesInCurrency();
  }

  removeTransactiontFromList = (id) => {
    const newInvoices = [];
    const { invoices } = this.state;
    invoices.map((invoice) => { if (invoice.id !== id) newInvoices.push(invoice); });
    this.setState({ invoices });
    this.sumInvoices();
    this.sumInvoicesInCurrency();
  }

  onToggleTransactionsModal = () => {
    const { isTransactionsModalOpen } = this.state;
    this.setState({
      isTransactionsModalOpen: !isTransactionsModalOpen,
    });
  }

  onToggleTransactionModal = (invoiceId = null) => {
    const { isTransactionModalOpen } = this.state;
    this.setState({
      isTransactionModalOpen: !isTransactionModalOpen, invoiceId,
    });

    this.fetchInvoices();
  }

  fetchInvoices = () => {
    const { purchaseOrder, updateInvoices } = this.props;

    InvoicesService.purchaseOrderInvoices(purchaseOrder.id).then(({ invoices }) => {
      updateInvoices(invoices);
      this.setState({ invoices }, () => {
        this.sumInvoices();
        this.sumInvoicesInCurrency();
      });
    });
  }

  isInvoiceEditable = () => {
    const { purchaseOrder, currentUser } = this.props;

    return _.includes(purchaseOrder.permitted_actions, 'invoice') || currentUser.isAdmin;
  }

  allInvoicesInOneCurrency = () => {
    const { invoices } = this.state;
    const { purchaseOrderCurrency } = this.props;

    const currencies = _.uniq(invoices.map(({ transactionCurrency }) => transactionCurrency));
    return currencies.length === 1 && currencies[0] === purchaseOrderCurrency;
  }

  deviationAmount = () => {
    const {
      totalInvoicesValue, totalItemsValue, totalInvoicesValueInCurrency, totalItemsCurrencyValue, exchangeRate,
    } = this.props;

    const value = this.allInvoicesInOneCurrency() ? (totalItemsCurrencyValue - totalInvoicesValueInCurrency) * exchangeRate : totalItemsValue - totalInvoicesValue;
    return (
      <>
        <td className="total number price">{I18n.toNumber((value), this.amountFormat)}</td>
      </>
    );
  }

  inOneCurrencyTotal = () => {
    const { totalInvoicesValueInCurrency, purchaseOrderCurrency } = this.props;

    if (!this.allInvoicesInOneCurrency()) return <td colSpan={2} />;

    return (
      <>
        <td className="total number price">{I18n.toNumber(totalInvoicesValueInCurrency, this.amountFormat)}</td>
        <td className="total">{purchaseOrderCurrency}</td>
      </>
    );
  }

  inOneCurrencyDeviation = () => {
    const { totalInvoicesValueInCurrency, purchaseOrderCurrency, totalItemsCurrencyValue } = this.props;
    if (!this.allInvoicesInOneCurrency()) return <td colSpan={2} />;

    return (
      <>
        <td className="total number price">{I18n.toNumber(totalItemsCurrencyValue - totalInvoicesValueInCurrency, this.amountFormat)}</td>
        <td className="total">{purchaseOrderCurrency}</td>
      </>
    );
  }

  render() {
    const {
      invoices, isInvoicedModalOpen, isTransactionModalOpen, isTransactionsModalOpen, invoiceId,
    } = this.state;
    const {
      companyCurrency,
      purchaseOrder,
      purchaseOrderSupplierId,
      purchaseOrderCurrency,
      totalInvoicesValue,
      isInvoiced,
      currentUser,
      confirmInvoiceGoods,
      integrationType,
      standAlone,
      updatePurchaseOrder,
      dateFormat,
    } = this.props;

    return (
      <div className="form-section cell-12 with-tile">
        {isTransactionsModalOpen
          ? (
            <Modal>
              <PurchaseOrderAttach
                onToggleAttachPoSettings={this.onToggleTransactionsModal}
                filter={this.fetchInvoices}
                purchaseOrderId={purchaseOrder.id}
                currency={purchaseOrderCurrency}
                updatePurchaseOrder={updatePurchaseOrder}
              />
            </Modal>
          )
          : null}
        <div className="tile">
          <div className="tile-header form-section-header">
            <span>{I18n.t('purchase_orders.form.transaction_list')}</span>
            <div className="actions">
              {currentUser.isAdmin
                ? (
                  <IntegrationRefresh onReceived={this.fetchInvoices} />
                ) : null}
            </div>
          </div>
          <div className="tile-content">
            <div className="table-fluid table-column-12 show-last inside-window">
              <table>
                <thead>
                  <tr>
                    <th>{I18n.t('purchase_orders.form.transactions.source')}</th>
                    <th>{I18n.t('purchase_orders.form.transactions.date')}</th>
                    <th>{I18n.t('purchase_orders.form.transactions.due_date')}</th>
                    <th>{I18n.t('purchase_orders.form.transactions.supplier')}</th>
                    <th>{I18n.t('purchase_orders.form.transactions.supplier_invoice')}</th>
                    <th>{I18n.t('purchase_orders.form.transactions.invoice')}</th>
                    <th>{I18n.t('purchase_orders.form.transactions.account')}</th>
                    <th>{I18n.t('purchase_orders.form.transactions.project')}</th>
                    <th>{I18n.t('purchase_orders.form.transactions.description')}</th>
                    <th className="number price">{I18n.t('purchase_orders.form.transactions.value')}</th>
                    <th className="with-currency">{I18n.t('purchase_orders.form.transactions.currency')}</th>
                    <th className="number price">{`${I18n.t('purchase_orders.form.transactions.value')}(${companyCurrency})`}</th>
                    {standAlone && <th>{I18n.t('purchase_orders.form.transactions.paid')}</th>}
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {
                    invoices.map((invoice, index) => (
                      <Invoice
                        key={invoice.id}
                        index={index}
                        invoice={invoice}
                        editable={this.isInvoiceEditable()}
                        onToggleTransactionModal={this.onToggleTransactionModal}
                        fetchInvoices={this.fetchInvoices}
                        integrationType={integrationType}
                        standAlone={standAlone}
                        accountant={purchaseOrder.accountant}
                        amountFormat={this.amountFormat}
                      />
                    ))
                  }
                </tbody>
                <tfoot>
                  <tr className="total">
                    <td className="visible" colSpan={7}>
                      <div className="items">
                        {this.isInvoiceEditable() ? (
                          <React.Fragment>
                            <a className="link" onClick={() => (this.onToggleTransactionModal(null))}>{I18n.t('purchase_orders.form.add_new_transaction')}</a>
                            <a className="link" onClick={this.onToggleTransactionsModal}>{I18n.t('purchase_orders.form.view_all_transaction')}</a>
                          </React.Fragment>
                        ) : null}
                      </div>
                    </td>
                    <td className="mobile-hide" />
                    <td className="th total number price">{I18n.t('purchase_orders.form.total_value')}</td>
                    {this.inOneCurrencyTotal()}
                    <td className="total number price">{I18n.toNumber(totalInvoicesValue, this.amountFormat)}</td>
                    <td className="mobile-hide" colSpan={standAlone ? 2 : 1} />
                  </tr>
                  <tr className="total">
                    <td colSpan={7}>&nbsp;</td>
                    <td className="mobile-hide" />
                    <td className="th text-right word-wrap-break">{I18n.t('purchase_orders.form.deviation_transaction_po')}</td>
                    {this.inOneCurrencyDeviation()}
                    {this.deviationAmount()}
                    <td className="mobile-hide" colSpan={standAlone ? 2 : 1} />
                  </tr>
                </tfoot>
              </table>
              {isTransactionModalOpen
                ? (
                  <TransactionModal
                    invoiceId={invoiceId}
                    purchaseOrderId={purchaseOrder.id}
                    purchaseOrderSupplierId={purchaseOrderSupplierId}
                    purchaseOrderCurrency={purchaseOrder.currency}
                    companyCurrency={companyCurrency}
                    onToggleTransactionModal={this.onToggleTransactionModal}
                    amountFormat={this.amountFormat}
                    dateFormat={dateFormat}
                  />
                )
                : null}
            </div>
          </div>
        </div>
        {!isInvoiced
          ? (
            <div className="window-footer modal-footer form-section-footer column cell-12">
              <div className="items">
                <label className="has-checkbox hide-on-complete">
                  <input type="checkbox" name="markAsInvoiced" checked={isInvoicedModalOpen} onChange={this.openInvoicedModal} />
                  <span className="checkbox input-l" />
                  <span className="link">{I18n.t('purchase_orders.form.items.mark_po_invoiced')}</span>
                </label>
              </div>
            </div>
          ) : null}
        {isInvoicedModalOpen
          ? (
            <InvoicedModal
              closeInvoicedModal={this.closeInvoicedModal}
              confirmInvoiceGoods={confirmInvoiceGoods}
            />
          ) : null}
      </div>
    );
  }
}

export default Invoices;
