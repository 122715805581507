import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';

export default function CustomField({
  purchaseOrder, customFieldSetting, onChange,
}) {
  const currentCustomField = purchaseOrder.custom_fields.find(ent => ent.custom_field_setting_id === customFieldSetting.id);
  const [state, setState] = useState(currentCustomField.value || '');
  const { errors } = currentCustomField;

  if (['number_input', 'text_input'].includes(customFieldSetting.field_type)) {
    return (
      <div className="row">
        <div className={`cell-16 ${errors && errors.value ? 'has-error' : ''}`}>
          <div className={`as-input reduce ${customFieldSetting.required ? 'required' : ''}`}>
            <span className="label">{customFieldSetting.name}</span>
            <input
              type="text"
              disabled={!purchaseOrder.editable}
              name={customFieldSetting.slug}
              placeholder={`Add ${customFieldSetting.name}`}
              value={state}
              onChange={e => setState(e.target.value)}
              onBlur={() => onChange(currentCustomField.id, state)}
              className="output input-r"
              onFocus={(e) => { e.target.select(); }}
            />
          </div>
          {errors && errors.value ? <div className="hint error">{errors.value[0]}</div> : ''}
        </div>
      </div>
    );
  } if (customFieldSetting.field_type === 'select_input') {
    return (
      <div className="row">
        <div className={`cell-16 ${errors && errors.value ? 'has-error' : ''}`}>
          <div className={`as-input reduce ${customFieldSetting.required ? 'required' : ''}`}>
            {!customFieldSetting.required && currentCustomField.value && (
              <label tabIndex="-1" className="add-edit-trigger"><i className="icon-close" onClick={() => { setState(''); onChange(currentCustomField.id, null, null); }} /></label>
            )}
            <span className="label">{customFieldSetting.name}</span>
            <span className={!purchaseOrder.editable ? 'select input-r add-edit selected disabled' : 'select input-r add-edit'} name={`${customFieldSetting.slug}_select`}>
              <input type="checkbox" />
              <div className="select-content">
                {customFieldSetting.options.map(option => (
                  <React.Fragment key={option.id}>
                    <input
                      key={`input-option-${option.id}`}
                      id={`option_${option.id}`}
                      name={customFieldSetting.slug}
                      type="radio"
                      value={state}
                      checked={state === option.name}
                      onChange={() => {
                        setState(option.name);
                        onChange(currentCustomField.id, option.name, option.id);
                      }}
                    />
                    <label className={`${state === option.name ? 'checked' : ''}`} key={`label-${option.id}`} htmlFor={`option_${option.id}`}>
                      {option.name}
                    </label>
                  </React.Fragment>
                ))}
                <div
                  className="placeholder"
                  data-placeholder={`Select ${customFieldSetting.name}`}
                />
              </div>
            </span>
            {errors && errors.value ? <div className="hint error">{errors.value[0]}</div> : ''}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="row">
      <div className={`cell-7 ${errors && errors.value ? 'has-error' : ''}`}>
        <div className={`as-input reduce ${customFieldSetting.required ? 'required' : ''}`}>
          <span className="label">{customFieldSetting.name}</span>
          <DatePicker
            selected={currentCustomField.value ? moment(currentCustomField.value, currentCustomField.date_format) : null}
            onChange={value => onChange(currentCustomField.id, value)}
            readOnly={!purchaseOrder.editable}
            dateFormat={currentCustomField.date_format}
            locale={I18n.locale}
            className="output input-r"
            placeholderText={`Select ${customFieldSetting.name}`}
          />
        </div>
        {errors && errors.value ? <div className="hint error">{errors.value[0]}</div> : ''}
      </div>
    </div>
  );
}
