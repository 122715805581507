import React, { useState, useEffect } from 'react';
import uuid from 'uuid/v4';
import PurchaseOrders from './archive/purchase_orders';
import PurchaseOrderItems from './archive/purchase_order_items';
import Deliveries from './archive/deliveries';
import TabLabel from './common/tab_label';
import CurrentCompanyModel from '../models/current_company_model';

const resolveActivePage = () => {
  const uri = URI(window.location.href).query(true);
  return (uri.view || uri['view[]']) || 'po';
};

export default function Archive({ currentCompany, dateFormat }) {
  const [pageActive, setPageActive] = useState(resolveActivePage());
  useEffect(() => {
    sessionStorage.setItem('closeModalUrl', window.location.href);
  }, []);

  window.addEventListener('popstate', (e) => {
    e.preventDefault();
    setPageActive(resolveActivePage());
  });

  const onPageActivePurchaseOrders = () => {
    window.history.pushState({}, 'Archive', '/purchase_orders/archive?view=po');
    setPageActive('po');
  };

  const onPageActivePurchaseOrderItems = () => {
    window.history.pushState({}, 'Archive', '/purchase_orders/archive?view=items');
    setPageActive('items');
  };

  const onPageActiveDeliveries = () => {
    window.history.pushState({}, 'Archive', '/purchase_orders/archive?view=deliveries');
    setPageActive('deliveries');
  };

  return (
    <div className="grid">
      <div className="cell-16">
        <div className="tile with-table tabs addons-inside">
          <div className="tab-navs">
            <div className="tile-header tab-labels tab-light tile-header-short tile-header-mobile-block">
              <TabLabel
                activeClass={pageActive === 'po' ? 'active' : ''}
                onPageActive={onPageActivePurchaseOrders}
                type="po"
                textValue={I18n.t('archive.po_header')}
              />
              <TabLabel
                activeClass={pageActive === 'items' ? 'active' : ''}
                onPageActive={onPageActivePurchaseOrderItems}
                type="items"
                textValue={I18n.t('archive.line_items_header')}
              />
              <TabLabel
                activeClass={pageActive === 'deliveries' ? 'active' : ''}
                onPageActive={onPageActiveDeliveries}
                type="deliveries"
                textValue={I18n.t('archive.deliveries_header')}
              />
            </div>
          </div>
          <div className="tab-pages tile-content">
            <div key={uuid()} className="page active">
              {
                [
                  pageActive === 'po' && (
                    <PurchaseOrders key={uuid()} currentCompany={new CurrentCompanyModel(currentCompany)} dateFormat={dateFormat} />
                  ),
                  pageActive === 'items' && (
                    <PurchaseOrderItems key={uuid()} currentCompany={new CurrentCompanyModel(currentCompany)} dateFormat={dateFormat} />
                  ),
                  pageActive === 'deliveries' && (
                    <Deliveries key={uuid()} currentCompany={new CurrentCompanyModel(currentCompany)} dateFormat={dateFormat} />
                  ),
                ]
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
