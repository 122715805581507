import React, { useState } from "react";
import CashFlowList from "./components/CashFlow/CashFlowList/index";
import CashFlowBudget from "./components/CashFlow/CashFlowBudget/index";

function CashFlow({ amountFormat, dateFormat }) {
  const [budgetId, setBudgetId] = useState(null);

  if (budgetId) return <CashFlowBudget budgetId={budgetId} backToList={() => setBudgetId(null)} amountFormat={amountFormat} dateFormat={dateFormat} />;

  return <CashFlowList selectBudget={setBudgetId} amountFormat={amountFormat} dateFormat={dateFormat} />;
}

export default CashFlow;
