import React from 'react';
import * as toast from '../../shared/toast';
import { INTEGRATION_TYPES } from '../../../config/constants';
import InvoiceModel from '../../../models/invoice_model';
import InvoicesService from '../../../services/invoices_service';
import { usePurchaseOrderContext } from '../../../pages/purchaseOrder/context';
import Icon from '../../uiElements/Icon/index';

function Invoice(props) {
  const { paymentPlanStore } = usePurchaseOrderContext();
  const refreshPaymentPlan = paymentPlanStore.refreshRecord;

  const unattachTransaction = (e) => {
    const { fetchInvoices, invoice } = props;
    const { id, toUnattachParams } = new InvoiceModel(invoice);
    e.preventDefault();
    new InvoicesService().unattach(toUnattachParams(), id).then((response) => {
      if (response.invoice) {
        fetchInvoices();
        refreshPaymentPlan();
        toast.successToast(I18n.t('purchase_orders.form.transaction_detached'));
      } else {
        toast.errorToast(response.error);
      }
    });
  };

  const destroyTransaction = (e) => {
    const { invoice, fetchInvoices } = props;
    const { id } = new InvoiceModel(invoice);
    e.preventDefault();
    new InvoicesService().destroy(id).then(({ status, error }) => {
      if (status) {
        fetchInvoices();
        refreshPaymentPlan();
        toast.successToast(I18n.t('purchase_orders.form.transaction_deleted'));
      } else {
        toast.errorToast(error);
      }
    });
  };

  const {
    onToggleTransactionModal,
    editable,
    invoice,
    integrationType,
    standAlone,
    accountant,
    amountFormat,
  } = props;

  return (
    <tr>
      <td data-th={I18n.t('purchase_orders.form.transactions.source')}>
        <span>{invoice.humanizedSource}</span>
      </td>
      <td data-th={I18n.t('purchase_orders.form.transactions.date')}>
        <span>{invoice.formattedDocDate()}</span>
      </td>
      <td data-th={I18n.t('purchase_orders.form.transactions.date')}>
        <span>{invoice.formattedDueDate()}</span>
      </td>
      <td className="has-ellipsis" data-ellipsis={integrationType === INTEGRATION_TYPES.XERO ? invoice.supplierName : invoice.fullSupplierName()} data-th={I18n.t('purchase_orders.form.transactions.supplier')}>
        <span className="text-ellipsis w-7">{integrationType === INTEGRATION_TYPES.XERO ? invoice.supplierName : invoice.fullSupplierName()}</span>
      </td>
      <td data-th={I18n.t('purchase_orders.form.transactions.supplier_invoice')}>
        {accountant && invoice.supplierInvoicePath ? <a className="link" href={invoice.supplierInvoicePath} target="blank"><span>{invoice.supplierInvoiceNumber}</span></a>
          : <span>{invoice.supplierInvoiceNumber}</span>
        }
      </td>
      <td data-th={I18n.t('purchase_orders.form.transactions.invoice')}>
        <span>{invoice.docNumber || invoice?.number}</span>
      </td>
      <td data-th={I18n.t('purchase_orders.form.transactions.account')}>
        <span>{invoice.fullAccountName()}</span>
      </td>
      <td className="has-ellipsis" data-ellipsis={invoice.project} data-th={I18n.t('purchase_orders.form.transactions.project')}>
        <span className="text-ellipsis w-5">{invoice.project}</span>
      </td>
      <td className="has-ellipsis" data-ellipsis={invoice.description} data-th={I18n.t('purchase_orders.form.transactions.description')}>
        <span className="text-ellipsis w-7">{invoice.description}</span>
      </td>
      <td className="price number" data-th={I18n.t('purchase_orders.form.transactions.value')}>
        <span>{I18n.toNumber(invoice.currencyValue, amountFormat)}</span>
      </td>
      <td className="with-currency" data-th={I18n.t('purchase_orders.form.transactions.currency')}>
        <span>{invoice.transactionCurrency}</span>
      </td>
      <td className="price number" data-th={I18n.t('purchase_orders.form.transactions.value')}>
        <span>{I18n.toNumber(invoice.value, amountFormat)}</span>
      </td>
      {standAlone && (
        <td data-th={I18n.t('purchase_orders.form.transactions.paid')}>
          <span>{I18n.t(`purchase_orders.form.transactions.paid_status.${invoice.paid}`)}</span>
        </td>
      )}
      <td className="actions">
        {invoice.outdated
          && (
            <Icon hintText={I18n.t('errors.purchase_order_invoice.outdated_title')} iconName="icon-refresh" className="edit" />
          )
        }
        {editable && !invoice.outdated ? (
          <span>
            {invoice.invoicePdfPath && <a href={invoice.invoicePdfPath} target="blank" className="icon-file-preview" />}
            {invoice.automaticallyMatched ? <a className="icon-check color-blue" /> : <a className="icon-check color-green" />}
            {invoice.attachmentUrl ? <a className="icon-insert_file color-mute" href={invoice.attachmentUrl} target="blank" /> : null}
            <div className="has-popup">
              <input type="checkbox" />
              <a className="icon-remove_circle color-mute" />
              <ul className="popup menu bottom-right">
                <li className="item" onClick={unattachTransaction}>
                  <a className="anchor" href="javascript: void(0);">{I18n.t('purchase_orders.form.detach_transaction')}</a>
                </li>
                <li className="item" onClick={destroyTransaction}>
                  <a className="anchor" href="javascript: void(0);">{I18n.t('purchase_orders.form.delete_transaction')}</a>
                </li>
                {invoice.isManual() ? (
                  <li className="item" onClick={() => { onToggleTransactionModal(invoice.id); }}>
                    <a className="anchor" href="javascript: void(0);">{I18n.t('purchase_orders.form.edit_transaction')}</a>
                  </li>
                ) : null}
              </ul>
            </div>
          </span>
        ) : null}
      </td>
    </tr>
  );
}

export default Invoice;
