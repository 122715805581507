import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import EditModal from './components/EditModal';
import PreviewPaymentRow from './components/PreviewPaymentRow';
import { usePurchaseOrderContext } from '../../context.jsx';
import { EditModalProvider } from './components/EditModal/context';

function PaymentPlan({
  paymentPlanId, companyCurrency, poCurrency, poExchangeRate, items, invoices,
}) {
  const [editModalOpened, setEditModalOpened] = useState(false);
  const { t } = I18n;

  const { paymentPlanStore } = usePurchaseOrderContext();

  useEffect(() => {
    if (paymentPlanId) paymentPlanStore.fetchRecord(paymentPlanId);
  }, [paymentPlanId]);

  return (
    <div className="ct-mt-8">
      <EditModalProvider>
        <EditModal
          opened={editModalOpened}
          paymentPlan={paymentPlanStore.record}
          companyCurrency={companyCurrency}
          poCurrency={poCurrency}
          poExchangeRate={poExchangeRate}
          items={items}
          invoices={invoices}
          onClose={() => setEditModalOpened(false)}
        />
      </EditModalProvider>

      <div className="ct-mx-[50px]">
        <div className="ct-flex ct-gap-2 ct-items-center ct-mb-2 ct-justify-between">
          <div className="ct-text-black ct-text-base">{t('frontend.payment_plan.title')}</div>
          <button disabled={!paymentPlanId} onClick={() => setEditModalOpened(true)}>
            <i className="icon-edit ct-text-lg ct-text-blue" />
          </button>
        </div>

        <table className="ct-appearance-none ct-mb-14 ct-table-auto">
          <thead>
            <tr className="small ct-uppercase ct-border-b ct-border-gray ct-h-6 ct-text-gray ct-font-medium ct-text-xxs">
              <th className="custom">{t('frontend.payment_plan.headers.method')}</th>
              <th className="custom">{t('frontend.payment_plan.headers.date')}</th>
              <th className="ct-text-center custom">{t('frontend.payment_plan.headers.usage_rate')}</th>
              <th className="ct-text-right custom">{t('frontend.payment_plan.headers.value')}</th>
              <th className="ct-text-center ct-w-0 custom" />
            </tr>
          </thead>
          <tbody>
            {_.orderBy(paymentPlanStore.record.plannedPayments, ['paymentMethod', 'paymentDate'], ['asc', 'asc']).map(
              payment => (
                <PreviewPaymentRow
                  key={payment.id}
                  payment={payment}
                  numberFormat={paymentPlanStore.record.numberFormat}
                />
              ),
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default observer(PaymentPlan);
