import React, { useState, useEffect } from 'react';
import uuid from 'uuid/v4';
import TabLabel from './common/tab_label';
import ItemsList from './item_list/items_list';

const resolveActivePage = () => {
  const uri = URI(window.location.href).query(true);
  return (uri.view || uri['view[]']) || 'supplier_items';
};

export default function Items({ numberFormat }) {
  const [itemsType, setItemsType] = useState(resolveActivePage());

  useEffect(() => {
    sessionStorage.setItem('closeModalUrl', window.location.href);
  }, []);

  window.addEventListener('popstate', (e) => {
    e.preventDefault();
    setItemsType(resolveActivePage());
  });

  const onPageActive = (type) => {
    window.history.pushState({}, 'Items list', `/supplier_items?view=${type}`);
    setItemsType(type);
  };

  return (
    <div className="grid">
      <div className="ct-w-full">
        <div className="tile with-table tabs addons-inside">
          <div className="tab-navs">
            <div className="tile-header tab-labels tab-light tile-header-short tile-header-mobile-block">
              <TabLabel
                activeClass={itemsType === 'supplier_items' ? 'active' : ''}
                onPageActive={onPageActive}
                type="supplier_items"
                textValue={I18n.t('supplier_items.supplier_items')}
              />
              <TabLabel
                activeClass={itemsType === 'global_products' ? 'active' : ''}
                onPageActive={onPageActive}
                type="global_products"
                textValue={I18n.t('supplier_items.global_products')}
              />
            </div>
          </div>
          <div className="tab-pages tile-content">
            <div key={uuid()} className="page active">
              <ItemsList itemsType={itemsType} numberFormat={numberFormat} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
