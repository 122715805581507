import React, { forwardRef, useEffect, useState } from 'react';
import { Calendar } from 'react-date-range';
import DateFieldBase from '@/components/uiElements/inputs/dates';
import PropTypes from 'prop-types';
import moment from 'moment';
import { dateFieldBasePropTypes } from '../constants';

function SingleDateField({
  onChange, value, dateFormat, ...rest
}, ref) {
  const [date, setDate] = useState();
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    if (!value) return;
    setInputValue(moment(value).format(dateFormat));
  }, [value]);

  useEffect(() => {
    if (!inputValue) return;
    const newDate = moment(inputValue, dateFormat);
    onChange(newDate.toDate());
    setDate(newDate);
  }, [inputValue]);

  return (
    <DateFieldBase
      {...rest}
      ref={ref}
      onChange={newDate => setInputValue(newDate)}
      inputValue={inputValue}
      dateComponent={<Calendar date={date?.toDate()} onChange={onChange} />}
    />
  );
}

export default forwardRef(SingleDateField);

SingleDateField.propTypes = {
  ...dateFieldBasePropTypes,
  value: PropTypes.any,
  onChange: PropTypes.func,
};
