import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import Loader from "@/components/loader";
import Actions from "@/components/ui/table/actions";
import LeftPositionActions from "@/components/ui/table/left_position_actions";
import TableHead from "@/components/ui/table/table_head";
import Filters from "@/components/ui/table/filters";
import CashFlowSettings from "@/components/accountings/components/CashFlow/CashFlowSettings";
import Document from "@/components/accountings/components/CashFlow/Document";
import DashboardTable from "@/components/accountings/components/CashFlow/DashboardTable";
import { useCashFlowBudgetContext } from "./context";
import _ from "lodash";
import { addFilters, removeFilters } from '@/tools'

function CashFlowBudget({ budgetId, backToList, amountFormat, dateFormat }) {
  const { cashFlowStore } = useCashFlowBudgetContext();
  const { record: budget, possibleFilters, settings, columnNames } = cashFlowStore;
  const [filters, setFilters] = useState({ budget_id: budgetId });
  const integerAmountFormat = { delimiter: amountFormat.delimiter, precision: 0 }

  useEffect(() => {
    cashFlowStore.fetchRecord(budgetId, filters)
  }, [JSON.stringify(filters)]);

  function onFilter(filter) {
    setFilters({...addFilters(filters, filter)});
  }

  function onRemoveFilter(filter) {
    setFilters({...removeFilters(filters, filter)});
  }

  const headers = [
    { name: I18n.t("frontend.cash_flow.headers.type"), className: "" },
    { name: I18n.t("frontend.cash_flow.headers.supplier"), className: "" },
    { name: I18n.t("frontend.cash_flow.headers.number"), className: "" },
    { name: I18n.t("frontend.cash_flow.headers.po"), className: "" },
    { name: I18n.t("frontend.cash_flow.headers.doc_date"), className: "" },
    { name: I18n.t("frontend.cash_flow.headers.delivery"), className: "" },
    { name: I18n.t("frontend.cash_flow.headers.est_pay_date"), className: "" },
    { name: I18n.t("frontend.cash_flow.headers.value"), className: "text-center" },
    { name: I18n.t("frontend.cash_flow.headers.currency"), className: "text-center" },
    { name: I18n.t("frontend.cash_flow.headers.value_in_company_currency"), className: "text-center" },
  ];

  return (
    <>
      <div className="tab-pages tile-content">
        <div className="page active">
          <Loader />
          <Actions>
            <LeftPositionActions>
              <a className="link" onClick={() => backToList()}>
                {I18n.t("cash_flow.back_to_all_budgets")}
              </a>
              <Filters
                filters={possibleFilters}
                filterActions={Object.keys(possibleFilters)}
                onFilter={onFilter}
                onRemoveFilter={onRemoveFilter}
              />
            </LeftPositionActions>
            <CashFlowSettings applyFilters={(params) => setFilters({ ...filters, ...params })} settings={settings} />
          </Actions>
          <div className="tables">
            <DashboardTable budget={budget} columnNames={columnNames} amountFormat={integerAmountFormat} />
            <div className="has-bulk">
              <div className="table-scrollable with-always-scrollable first-sticky tbody-th-sticky tfoot-sticky">
                <table className="can-expand">
                  <TableHead headers={headers} />
                  <tbody>
                    {budget?.entities?.map((doc) => (
                      <Document key={doc.id} doc={doc} amountFormat={amountFormat} dateFormat={dateFormat} />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default observer(CashFlowBudget);
