import React from 'react';

function CellProgressBar({
  chartClass, valuePercentage, tooltip, decimalMark,
}) {
  const value = valuePercentage || 0;
  const restValue = 100.0 - value;
  return (
    <div className="chart-inside has-tooltip">
      <div className="charts">
        <div className={`chart ${chartClass}`} style={{ width: `${value}%` }}>
          <span />
        </div>
        <div className="chart null" style={{ width: `${restValue < 0.0 ? 0.0 : restValue}%` }}>
          <span />
        </div>
      </div>
      <div className="tooltip">
        {`${value > 100.0 ? 100.0 : I18n.toNumber(value, { separator: decimalMark, precision: 2 })}% ${tooltip}`}
      </div>
    </div>
  );
}

export default CellProgressBar;
