import React, { useEffect, useState } from "react";
import { Dialog, Listbox } from "@headlessui/react";
import PaymentRow from "./components/PaymentRow";
import PlannedPaymentModel from "@/models/PlannedPaymentModel";
import { DateTime } from "luxon";
import _ from "lodash";
import { PAYMENT_METHODS } from "@/config/constants";
import Button from "@/components/uiElements/old/Button";
import { CaretDownFill, CheckLg } from "react-bootstrap-icons";
import RemoveMilestonesConfirmationModal from "./components/RemoveMilestonesConfirmationModal";
import DisabledOptionTooltip from "./components/DisabledOptionTooltip";
import { usePurchaseOrderContext } from "@/pages/purchaseOrder/context";
import usePrevious from "@/hooks/use_previous";
import { formatNumber } from "@/tools";
import { useEditModalContext } from "./context";

export default function EditModal({
  opened,
  onClose,
  paymentPlan,
  companyCurrency,
  poCurrency,
  poExchangeRate,
  items,
  invoices,
}) {
  const paymentMethods = [
    { value: PAYMENT_METHODS.AUTOMATIC, name: "Automatic", disabled: false },
    {
      value: PAYMENT_METHODS.MILESTONE,
      name: "Milestone",
      disabled: differentCurrencies(),
    },
  ];
  const { currentEdditingPayment } = useEditModalContext();
  const [isOpen, setIsOpen] = useState(false);
  const [
    removeMilestonesConfirmationModalOpened,
    setRemoveMilestonesConfirmationModalOpened,
  ] = useState(false);
  const [payments, setPayments] = useState([{}]);
  const [paymentMethod, setPaymentMethod] = useState(paymentMethods[0]);
  const { plannedPayments, numberFormat, dateFormat } = paymentPlan;
  const { t } = I18n;
  const { paymentPlanStore } = usePurchaseOrderContext();
  const prevSumOfItems = usePrevious(calculateSumOfItems());

  useEffect(() => {
    setIsOpen(opened);
  }, [opened]);

  useEffect(() => {
    if (plannedPayments.length > 0) {
      setPayments(plannedPayments);
      setPaymentMethod(defaultPaymentMethod());
    }
  }, [paymentPlan, opened]);

  useEffect(() => {
    if (prevSumOfItems !== calculateSumOfItems() && plannedPayments.length > 0)
      recalculateResidual(payments);
  }, [items]);

  function submitPlan() {
    paymentPlanStore
      .update(paymentPlan.id, {
        id: paymentPlan.id,
        paymentMethod: paymentMethod.value,
        plannedPaymentsAttributes: payments,
      })
      .then(() => closeModal());
  }

  function defaultPaymentMethod() {
    return (
      paymentMethods.find(({ value }) => value === paymentPlan.paymentMethod) ||
      paymentMethod[0]
    );
  }

  function closeModal() {
    onClose();
  }

  function calculateSumOfItems() {
    return items.reduce(
      (sum, item) =>
        sum + (Number(item.price) || 0) * (Number(item.quantity) || 1),
      0
    );
  }

  function residualRow(collection = payments) {
    return collection.find(
      (payment) => payment.paymentMethod === PAYMENT_METHODS.RESIDUAL
    );
  }

  function addNewPayment() {
    const newPayment = new PlannedPaymentModel({
      ...residualRow(),
      paymentMethod: PAYMENT_METHODS.MILESTONE,
    });

    recalculateResidual([...payments, newPayment]);
  }

  function acceptPayment(payment, oldPayment) {
    const newPayment = new PlannedPaymentModel({
      ...oldPayment,
      ...payment,
      usageRate: Number(payment.usageRate),
      valueCents: payment.value * 100,
      valueCentsInCompanyCurrency:
        payment.value * 100 * oldPayment.exchangeRate,
    });
    const index = _.findIndex(payments, oldPayment);
    payments.splice(index, 1, newPayment);

    recalculateResidual(payments);
  }

  function removePayment(payment) {
    _.remove(payments, payment);

    recalculateResidual(payments);
  }

  function differentCurrencies() {
    return invoices.some(({ currency }) => currency !== poCurrency);
  }

  function recalculateResidual(passedCollection) {
    const collection = [...passedCollection];
    const sumOfItems = calculateSumOfItems();
    const residualRow = collection.find(
      (payment) => payment.paymentMethod === PAYMENT_METHODS.RESIDUAL
    );
    _.remove(collection, residualRow);

    let sortedNewPayments = _.orderBy(
      collection,
      ["paymentMethod", "paymentDate"],
      ["asc", "asc"]
    );
    const sortedIvoices = _.orderBy(invoices, ["paymentDate"], ["asc"]);

    sortedNewPayments = sortedNewPayments.map((payment, i) => {
      const sortedIvoice = sortedIvoices[i];

      if (!sortedIvoice)
        return new PlannedPaymentModel({
          ...payment,
          purchaseOrderInvoiceId: null,
        });

      return new PlannedPaymentModel({
        ...payment,
        exchangeRate: sortedIvoice.value / sortedIvoice.currencyValue,
        currency: sortedIvoice.currency,
        usageRate: (sortedIvoice.currencyValue / sumOfItems) * 100,
        valueCents: sortedIvoice.currencyValue * 100,
        valueCentsInCompanyCurrency: sortedIvoice.value * 100,
        purchaseOrderInvoiceId: sortedIvoice.id,
      });
    });

    const newResidualRow = generateResidual(
      sumOfItems,
      sortedNewPayments,
      _.last(sortedNewPayments)?.paymentDate || DateTime.now().toString()
    );

    setPayments([...sortedNewPayments, newResidualRow]);
  }

  function calculatePayments(newPaymentMethod) {
    if (paymentMethod.value === newPaymentMethod.value) return;

    if (paymentPlan.paymentMethod === newPaymentMethod.value) {
      setPayments(paymentPlan.plannedPayments);
      return setPaymentMethod(newPaymentMethod);
    }

    if (newPaymentMethod.value === PAYMENT_METHODS.MILESTONE) {
      const newPayments = generatePaymentsFromInvoices();

      setPayments([...newPayments]);
    } else {
      return setRemoveMilestonesConfirmationModalOpened(true);
    }

    setPaymentMethod(newPaymentMethod);
  }

  function afterCloseRemoveMilestonesConfirmationModal() {
    const sumOfItems = calculateSumOfItems();

    setPayments([
      new PlannedPaymentModel({
        valueCents: sumOfItems * 100,
        valueCentsInCompanyCurrency: sumOfItems * poExchangeRate * 100,
        usageRate: 100,
        paymentDate: DateTime.now().toString(),
        exchangeRate: poExchangeRate,
        currency: poCurrency,
        paymentMethod: PAYMENT_METHODS.AUTOMATIC,
      }),
    ]);

    setPaymentMethod(
      paymentMethods.find(({ value }) => value === PAYMENT_METHODS.AUTOMATIC)
    );
    setRemoveMilestonesConfirmationModalOpened(false);
  }

  function generateResidual(sumOfItems, payments, lastDate) {
    const restToPay = sumOfItems - currentTotalValue(payments);

    const residualRow = new PlannedPaymentModel({
      paymentMethod: PAYMENT_METHODS.RESIDUAL,
      paymentDate: lastDate,
      exchangeRate: poExchangeRate,
      currency: poCurrency,
      usageRate: (restToPay / sumOfItems) * 100,
      valueCents: restToPay * 100,
      valueCentsInCompanyCurrency: restToPay * poExchangeRate * 100,
    });

    return residualRow;
  }

  function generatePaymentsFromInvoices() {
    const sumOfItems = calculateSumOfItems();
    let newPlannedPayments = _.orderBy(invoices, ["dueDate"], ["asc"]).map(
      (invoice) =>
        new PlannedPaymentModel({
          paymentMethod: PAYMENT_METHODS.MILESTONE,
          paymentDate: invoice.dueDate?.isAmomentObject
            ? invoice.dueDate.d.toISOString()
            : invoice.dueDate,
          exchangeRate: invoice.value / invoice.currencyValue,
          currency: invoice.currency,
          usageRate: (invoice.currencyValue / sumOfItems) * 100,
          valueCents: invoice.currencyValue * 100,
          valueCentsInCompanyCurrency: invoice.value * 100,
          purchaseOrderInvoiceId: invoice.id,
        })
    );

    const sortedNewPayments = _.orderBy(
      newPlannedPayments,
      ["paymentDate"],
      ["asc"]
    );

    const lastPayment = _.last(sortedNewPayments);
    const residualRow = generateResidual(
      sumOfItems,
      sortedNewPayments,
      lastPayment?.paymentDate || DateTime.now().toString()
    );

    sortedNewPayments.push(residualRow);

    return sortedNewPayments;
  }

  function currentTotalValue(collection = payments) {
    return collection.reduce((sum, b) => sum + b.valueCents / 100, 0);
  }

  function currentTotalValueInCompanyCurrency() {
    return payments.reduce(
      (sum, b) => sum + b.valueCentsInCompanyCurrency / 100,
      0
    );
  }

  function sortedPayments() {
    return _.orderBy(
      payments,
      ["paymentMethod", "paymentDate"],
      ["asc", "asc"]
    );
  }

  const paymentMethodSelect = () => {
    return (
      <Listbox
        value={paymentMethod}
        onChange={(paymentMethod) => calculatePayments(paymentMethod)}
        by="value"
      >
        <div className="ct-relative ct-mt-1">
          <Listbox.Button className="ct-relative ct-text-left ct-cursor-default ct-bg-white ct-border-b ct-border-gray-lighter ct-w-40 ct-rounded">
            <div className="ct-mb-3 ct-text-xs">
              {t("frontend.payment_plan.components.edit_modal.payment_method")}
            </div>
            <div className="ct-truncate ct-px-2 ct-pb-2 ct-text-black ct-text-sm ct-flex ct-justify-between">
              {paymentMethod.name}
              <CaretDownFill />
            </div>
          </Listbox.Button>
          <Listbox.Options className="ct-absolute ct-max-h-60 ct-w-full ct-overflow-auto ct-bg-[#F6F7FB]">
            {paymentMethods.map((method, id) => (
              <Listbox.Option
                key={id}
                className={({ active }) =>
                  `ct-relative ct-cursor-default ct-select-none ct-py-2 ct-px-4
                   ${active ? "ct-bg-blue ct-text-white" : ""}
                   ${method.disabled ? "ct-bg-opacity-25" : ""}
                  `
                }
                disabled={method.disabled}
                value={method}
              >
                {({ selected }) => (
                  <div className="ct-flex ct-justify-between ct-w-full">
                    <div
                      className={`ct-flex ct-justify-between ct-w-full ${
                        method.disabled ? "ct-opacity-25" : ""
                      }`}
                    >
                      {method.name}
                      {selected && <CheckLg fontSize={14} />}
                    </div>

                    {method.disabled && (
                      <DisabledOptionTooltip
                        method={method.name.toLowerCase()}
                      />
                    )}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </div>
      </Listbox>
    );
  };

  return (
    <Dialog
      as="div"
      className="ct-relative ct-z-9999 ct-bg-white"
      open={isOpen}
      onClose={() => {}}
    >
      <div className="ct-fixed ct-inset-0 ct-bg-white" aria-hidden="true" />

      <div className="ct-fixed ct-inset-0 ct-flex ct-items-center ct-justify-center ct-bg-white">
        <Dialog.Panel className="ct-bg-white ct-mx-auto ct-rounded ct-min-w-[33%] ct-drop-shadow-lg">
          <RemoveMilestonesConfirmationModal
            isOpened={removeMilestonesConfirmationModalOpened}
            setIsOpened={setRemoveMilestonesConfirmationModalOpened}
            afterClose={afterCloseRemoveMilestonesConfirmationModal}
          />

          <div className="ct-px-5 ct-pt-8 ct-flex ct-items-center ct-justify-between ct-mb-10">
            <h1 className="ct-text-2xl ct-text-black">
              {t("frontend.payment_plan.components.edit_modal.title")}
            </h1>
            {paymentMethodSelect()}
          </div>

          <table className="ct-appearance-none ct-table-auto">
            <thead>
              <tr className="ct-text-gray">
                <th className="ct-pl-5">
                  {t("frontend.payment_plan.headers.method")}
                </th>
                <th>{t("frontend.payment_plan.headers.date")}</th>
                <th>{t("frontend.payment_plan.headers.usage_rate")}</th>
                <th className="ct-text-right">
                  {t("frontend.payment_plan.headers.value")}
                </th>
                <th>{t("frontend.payment_plan.headers.currency")}</th>
                <th>{t("frontend.payment_plan.headers.exchange")}</th>
                <th className="ct-text-right">
                  {t(
                    "frontend.payment_plan.headers.value_in_company_currency",
                    { currency: companyCurrency }
                  )}
                </th>
                <th colSpan="2">
                  {t("frontend.payment_plan.headers.transaction_id")}
                </th>
              </tr>
            </thead>

            <tbody>
              {sortedPayments().map((payment, index) => (
                <PaymentRow
                  key={index}
                  payment={payment}
                  acceptPayment={acceptPayment}
                  removePayment={removePayment}
                  sumOfItems={calculateSumOfItems()}
                  numberFormat={numberFormat}
                  dateFormat={dateFormat}
                />
              ))}
            </tbody>

            <tfoot>
              <tr>
                <td colSpan="2" className="ct-pl-5 ct-text-blue ct-font-xs">
                  {paymentMethod.value === PAYMENT_METHODS.MILESTONE && (
                    <button onClick={() => addNewPayment()}>
                      {t("frontend.payment_plan.add_new_payment")}
                    </button>
                  )}
                </td>
                <td className="ct-font-bold ct-text-gray ct-font-xs">
                  {t("frontend.payment_plan.total_value")}
                </td>
                <td className="ct-text-right ct-font-bold ct-font-sm">
                  {formatNumber(currentTotalValue().toFixed(2))}
                </td>
                <td className="ct-font-bold ct-font-sm" colSpan="2">
                  {poCurrency}
                </td>
                <td className="ct-font-bold ct-font-sm ct-text-right">
                  {formatNumber(
                    currentTotalValueInCompanyCurrency().toFixed(2)
                  )}
                </td>
                <td colSpan="2" />
              </tr>
            </tfoot>
          </table>

          <div className="ct-flex ct-p-5 ct-gap-x-4 ct-float-right ct-mt-4">
            <Button
              text={t("frontend.payment_plan.cancel")}
              onClick={() => closeModal()}
            />
            <Button
              disabled={!!currentEdditingPayment}
              primary
              text={t("frontend.payment_plan.save")}
              onClick={() => submitPlan()}
            />
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
