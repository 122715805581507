import React from 'react';
import Table from '../shared/table';
import * as swipe from '../shared/swipe';
import ArchiveService from '../../services/archive_service';
import ColumnViewSettings from './common/column_view_settings';
import PurchaseOrderFilters from '../accountings/shared/purchase_order_filters';
import TableHead from '../accountings/shared/table_head';
import PaginateWithDots from '../item_list/paginate_with_dots';
import Loader from '../loader';
import ExportButton from '../shared/buttons/export_button';
import DeliveryRow from './deliveries/delivery_row';

class Deliveries extends Table {
  constructor(props) {
    super(props);

    const urlFilters = this.urlParamsSetup();
    const page = urlFilters.page ? parseInt(urlFilters.page) : 1;

    this.filters = urlFilters.filters;
    this.order = urlFilters.order;

    const selectedFilters = urlFilters.filters;

    this.state = {
      deliveryItems: [],
      pages: 1,
      currentPage: page,
      columnView: [],
      filters: [],
      selectedFilters,
      filterActions: [],
      showDeleted: selectedFilters.state ? selectedFilters.state.includes('deleted') : false,
      filtersComponentKey: 1,
      columnViewAttributes: [],
    };
  }

  componentDidMount() {
    swipe.triggerSwipeEvent();
    this.onTableLoading();
    ArchiveService.deliveryItems(window.location.search).then(({
      deliveryItems, columnView, pages, filters,
    }) => {
      this.setState({
        deliveryItems,
        columnView,
        pages,
        filters,
        filterActions: filters ? Object.keys(filters) : [],
        columnViewAttributes: columnView.deliveryColumns(),
      });
      this.onTableLoad();
      window.initTableFluidManual();
    });
  }

  componentDidUpdate() {
    window.resetTableFluidManual();
  }

  onFilter = ({ name, value }) => {
    const { selectedFilters } = this.state;
    const stringValue = value.toString();

    if (selectedFilters[name]) {
      selectedFilters[name].push(stringValue);
    } else {
      selectedFilters[name] = [stringValue];
    }

    this.filter();
  }

  onQueryFilter = ({ value }) => {
    const { selectedFilters } = this.state;
    selectedFilters.query = value?.toString();

    this.filter();
  }

  filterDeleted = () => {
    const { showDeleted } = this.state;

    this.setState({ selectedFilters: { state: showDeleted ? [] : ['deleted'] } }, () => {
      this.filter({ showDeleted: !showDeleted });
    });
  }

  filter = (additionalState = {}, page = 1) => {
    const attributes = {
      custom: Object.keys(this.filters).map(key => ({ name: key, value: this.filters[key] })),
      page,
      order: this.order,
    };

    this.onTableLoading();

    ArchiveService.deliveryItemsFilter(attributes).then(({
      deliveryItems, pages, query,
    }) => {
      window.history.pushState({}, 'Archive', query);
      this.setState({
        deliveryItems,
        pages,
        currentPage: 1,
        ...additionalState,
      });
      this.onTableLoad();
    });
  }

  updateColumnView = ({ target: { value } }) => {
    const { columnView } = this.state;
    columnView[value] = !columnView[value];
    this.setState({
      columnView,
    }, () => {
      ArchiveService.updateColumnView({
        type: 'delivery',
        column_view: columnView.toParams('delivery'),
      });
    });
  }

  headers = () => {
    const {
      state: {
        columnView,
      },
      props: { currentCompany },
    } = this;

    return [
      {
        name: 'delivery_date',
        translation: 'archive.deliveries.delivery_date',
        visible: columnView.deliveryDate,
      },
      {
        name: 'product_number',
        translation: 'archive.deliveries.product_number',
        visible: columnView.productNumber,
      },
      {
        name: 'sku',
        translation: 'archive.deliveries.sku',
        visible: columnView.sku,
      },
      {
        name: 'name',
        translation: 'archive.deliveries.item_name',
        visible: columnView.name,
      },
      {
        name: 'po_number',
        translation: 'archive.deliveries.po_number',
        visible: columnView.poNumber,
      },
      {
        name: 'raised',
        translation: 'archive.deliveries.raised',
        visible: columnView.raised,
      },
      {
        name: 'po_delivery_date',
        translation: 'archive.deliveries.po_delivery_date',
        visible: columnView.poDeliveryDate,
      },
      {
        name: 'supplier_name',
        translation: 'archive.deliveries.supplier',
        visible: columnView.supplierName,
      },
      {
        name: 'budget_name',
        translation: 'archive.deliveries.budget',
        visible: columnView.budgetName,
      },
      {
        name: 'quantity',
        translation: 'archive.deliveries.quantity',
        class: 'number price',
        visible: columnView.quantity,
      },
      {
        name: 'unit',
        translation: 'archive.deliveries.unit',
        class: 'number price',
        visible: columnView.unit,
      },
      {
        name: 'ordered_value_cents',
        translation: 'archive.deliveries.ordered_value',
        class: 'number price',
        visible: columnView.orderedValue,
      },
      {
        name: 'ordered_currency_value_cents',
        translation: 'archive.deliveries.ordered_currency_value',
        attributes: { currency: currentCompany.currency },
        class: 'number price',
        visible: columnView.orderedCurrencyValue,
      },
      {
        name: 'good_items_count',
        translation: 'archive.deliveries.pass',
        class: 'number price',
        visible: columnView.goodItemsCount,
      },
      {
        name: 'bad_items_count',
        translation: 'archive.deliveries.fail',
        class: 'number price',
        visible: columnView.badItemsCount,
      },
      {
        name: 'received_quantity',
        translation: 'archive.deliveries.received_quantity',
        class: 'number price',
        visible: columnView.receivedQuantity,
      },
      {
        name: 'received_value_cents',
        translation: 'archive.deliveries.received_value',
        class: 'number price',
        visible: columnView.receivedValue,
      },
      {
        name: 'received_currency_value_cents',
        translation: 'archive.deliveries.received_currency_value',
        attributes: { currency: currentCompany.currency },
        class: 'number price',
        visible: columnView.receivedCurrencyValue,
      },
      {
        name: 'state',
        translation: 'archive.deliveries.state',
        class: 'has-status',
        visible: columnView.state,
      },
      {
        name: 'total_received',
        translation: 'archive.deliveries.total_received_quantity',
        class: 'number price',
        visible: columnView.totalReceived,
      },
      {
        name: 'not_received',
        translation: 'archive.deliveries.not_received_quantity',
        class: 'number price',
        visible: columnView.notReceived,
      },
      {
        name: 'user_name',
        translation: 'archive.deliveries.received_by',
        visible: columnView.userName,
      },
    ].filter(object => object.visible);
  }

  exportUrl = format => `${window.location.origin}/api/archive/delivery_items.${format}${window.location.search}`;

  render() {
    const {
      state: {
        deliveryItems, isLoading, filters, filterActions, filtersComponentKey, selectedFilters,
        pages, currentPage, columnView, columnViewAttributes, showDeleted,
      },
      props: { currentCompany, dateFormat },
    } = this;
    const dotsNumber = this.headers().length;
    const htmlClasses = isLoading ? ' has-loading' : '';

    return (
      <React.Fragment>
        <div className="tab-addons">
          <ColumnViewSettings
            columnView={columnView}
            handleUpdate={this.updateColumnView}
            visibleColumns={columnViewAttributes}
          />
          <ExportButton exportUrl={this.exportUrl} />
        </div>
        <PurchaseOrderFilters
          onFilter={this.onFilter}
          onRemoveFilter={this.onRemoveFilter}
          onDateFilter={this.onDateFilter}
          onQueryFilter={this.onQueryFilter}
          currentFilters={selectedFilters}
          clearDate={this.clearDate}
          filters={filters}
          filterActions={filterActions}
          integrationRefesh={false}
          key={`availableFiltersPurchaseOrders${filtersComponentKey}`}
          exportEnable={false}
          dateFormat={dateFormat}
        />
        <div>
          <div className={`table-fluid table-scrollable table-column-${dotsNumber}${htmlClasses}`}>
            <Loader />
            {this._renderInputs(dotsNumber)}
            <div className="table-header with-filters">
              <div className="table-nav">
                {this._renderLabels(dotsNumber)}
                {this._renderDots(dotsNumber)}
              </div>
            </div>
            <div className="table">
              <div className="colgroup">
                {this._renderColls(dotsNumber)}
              </div>
              <TableHead
                onOrder={this.onOrder}
                order={this.order}
                headers={this.headers()}
              />
              <div className="tbody">
                {!isLoading && deliveryItems.map(deliveryItem => (
                  <DeliveryRow
                    key={deliveryItem.id}
                    deliveryItem={deliveryItem}
                    columnView={columnView}
                    currentCompany={currentCompany}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="tile-footer">
          {showDeleted
            ? <a className="link" onClick={this.filterDeleted}>{I18n.t('archive.hide_deleted_deliveries')}</a>
            : <a className="link" onClick={this.filterDeleted}>{I18n.t('archive.show_deleted_deliveries')}</a>
          }
          <PaginateWithDots onPageChange={this.onPageChange} numberPages={pages} selected={currentPage} />
        </div>
      </React.Fragment>
    );
  }
}

export default Deliveries;
