const NumberConverter = (value, replaceComma = true) => {
  if (value === '.' || value === '' || value === ',') return 0;
  const newValue = replaceComma ? value.replace(',', '.') : value;
  const [convertedValue] = newValue.match(/((^([0-9-]{0,1})+([.,]{0,1})[0-9]{0,2}))/);

  return convertedValue.replace(/((^0+))(?=\d+)/, '');
};

export const humanizedPrice = (value, format = { delimiter: ' ', separator: ',', precision: 2 }) => {
  const roundedInvoicesValue = (Math.round(value * 100) / 100).toFixed(2);
  return I18n.toNumber(roundedInvoicesValue, format);
};

export const humanizedIntegerPrice = (value, format = { delimiter: ' ', precision: 0 }) => {
  const roundedInvoicesValue = (Math.round(value * 100) / 100).toFixed(0);
  return I18n.toNumber(roundedInvoicesValue, format);
};

export default NumberConverter;
