import uuid from 'uuid/v4';
import Model from '@/models/model';
import convertToCents from '../components/shared/convert_to_cents';
import SupplierInvoiceAttachmentModel from './SupplierInvoiceAttachmentModel';
import SupplierInvoiceTransactionModel from './SupplierInvoiceTransactionModel';
import SupplierInvoiceCommentModel from './SupplierInvoiceCommentModel';
import PurchaseOrderNumberModel from './dropdowns_models/purchase_order_number_model';
import ProjectModel from './dropdowns_models/project_model';
import SupplierModel from './dropdowns_models/supplier_model';
import AccountModel from './dropdowns_models/account_model';
import TypeModel from './dropdowns_models/type_model';
import UserModel from './user_model';
import SupplierInvoiceFileModel from './SupplierInvoiceFileModel';
import PredictionSupplierInvoiceModel from './PredictionSupplierInvoiceModel';
import TrackingOptionModel from './tracking_option_model';
import CurrencyModel from './dropdowns_models/currency_model';

class SupplierInvoiceModel extends Model {
  constructor({
    id, purchase_order_id, project_id, attachments, outdated,
    supplier, account, bank_account, cid, amount_cents, purchase_order,
    currency_amount_cents, doc_number, currency, transactions, editable,
    internal_number, doc_date, due_date, supplier_invoice_type, comments, created_at,
    state, deleted_at, permitted_events, possible_approvers, approved_by,
    updated_at, reference, processing, prediction_supplier_invoice, exchange_rate,
    prediction, transferred_at, duplicated, tracking_options, paid, files, ...attributes
  }) {
    super(attributes);
    this.id = id;
    this.number = internal_number;
    this.poNumber = purchase_order ? new PurchaseOrderNumberModel(purchase_order) : new PurchaseOrderNumberModel({});
    this.project = new ProjectModel({ id: project_id });
    this.supplier = supplier ? new SupplierModel(supplier) : new SupplierModel({});
    this.account = account ? new AccountModel(account) : {};
    this.type = new TypeModel({ id: supplier_invoice_type, name: supplier_invoice_type });
    this.bankAccount = bank_account || '';
    this.reference = reference || '';
    this.cid = cid || '';
    this.outdated = outdated || false;
    this.amount = this.setPriceValue(amount_cents) || '';
    this.currencyAmount = this.setPriceValue(currency_amount_cents) || '';
    this.invoiceNumber = doc_number || '';
    this.currency = new CurrencyModel(currency);
    this.createdAt = created_at;
    this.transferredAt = transferred_at;
    this.uuid = uuid();
    this.state = state;
    this.deletedAt = deleted_at;
    this.possibleApprovers = possible_approvers ? possible_approvers.map(user => new UserModel(user)) : [];
    this.permittedEvents = permitted_events;
    this.approvedBy = approved_by;
    this.editable = editable;
    this.updatedAt = updated_at;
    this.processing = processing;
    this.duplicated = duplicated;
    this.paid = paid || false;
    this.purchaseOrderId = purchase_order_id;
    this.exchangeRate = exchange_rate || 1;
    this.outdated = outdated || false;
    this.trackingOptions = (tracking_options || []).map(ent => (
      new TrackingOptionModel(ent)
    ));
    this.transactions = (transactions || []).map(transaction => (
      new SupplierInvoiceTransactionModel(transaction)
    ));
    this.comments = (comments || []).map(comment => (
      new SupplierInvoiceCommentModel(comment)
    ));
    this.files = (files || []).map(file => (
      new SupplierInvoiceFileModel(file)
    ));

    this.prediction = prediction;
    this.predictionSupplierInvoice = (prediction_supplier_invoice && prediction_supplier_invoice.id) ? new PredictionSupplierInvoiceModel(prediction_supplier_invoice) : null;

    doc_date
      ? this.docDate = doc_date
      : null;
    due_date
      ? this.dueDate = due_date
      : null;

    attachments
      ? this.attachments = attachments.map((attachment, index) => {
        if (index === 0) {
          return new SupplierInvoiceAttachmentModel({ ...attachment, selected: true });
        }
        return new SupplierInvoiceAttachmentModel(attachment);
      })
      : [];
  }

  predictionFor = (field) => {
    if (!this.predictionSupplierInvoice) return null;

    if (this.predictionSupplierInvoice[field]) {
      const confidence = this.predictionSupplierInvoice[`${field}Confidence`];
      return (`${(confidence * 100).toFixed(2)}%`);
    }
    return '0.00%';
  }

  predictionValueFor = (field) => {
    if (this.predictionSupplierInvoice && this.predictionSupplierInvoice[field]) {
      const confidence = this.predictionSupplierInvoice[`${field}Confidence`];
      return (confidence * 100).toFixed(2);
    }

    return null;
  }

  setPriceValue = (value) => {
    if (value) {
      return Number(value / 100).toFixed(2);
    }
    return '';
  }

  formattedDocDate = () => {
    if (this.docDate) {
      return this.docDate;
    }
    return '';
  }

  formattedDueDate = () => {
    if (this.dueDate) {
      return this.dueDate;
    }
    return '';
  }

  humanizedState = () => {
    if (this.state === 'complete') return 'validated';
    if (this.state === 'sent_to_approve') return 'sent for approval';
    return this.state.replace(/_/g, ' ');
  }

  toFormData = (supplierInvoice) => {
    const formData = new FormData();

    supplierInvoice.attachments.map((attachment, index) => {
      formData.append('supplier_invoice[attachments][]', attachment.toFile(), `${index}_pdf_page.png`);
    });

    formData.append('supplier_invoice[reference]', supplierInvoice.reference);
    formData.append('supplier_invoice[paid]', supplierInvoice.paid);

    if (supplierInvoice.purchaseOrderId) formData.append('supplier_invoice[purchase_order_id]', supplierInvoice.poNumber?.poNumber ? supplierInvoice.purchaseOrderId : '');
    if (supplierInvoice.poNumber) formData.append('supplier_invoice[po_number]', supplierInvoice.poNumber.poNumber || '');
    if (supplierInvoice.type) formData.append('supplier_invoice[supplier_invoice_type]', supplierInvoice.type.id);
    if (supplierInvoice.invoiceNumber) formData.append('supplier_invoice[doc_number]', supplierInvoice.invoiceNumber);
    if (supplierInvoice.supplier.id) formData.append('supplier_invoice[supplier_id]', supplierInvoice.supplier.id);
    if (supplierInvoice.amount) formData.append('supplier_invoice[amount_cents]', Math.round(supplierInvoice.amount * 100));
    if (supplierInvoice.currency.id) formData.append('supplier_invoice[currency]', supplierInvoice.currency.id);
    if (supplierInvoice.currencyAmount) formData.append('supplier_invoice[currency_amount_cents]', Math.round(supplierInvoice.currencyAmount * 100));
    if (supplierInvoice.docDate) formData.append('supplier_invoice[doc_date]', supplierInvoice.docDate);
    if (supplierInvoice.dueDate) formData.append('supplier_invoice[due_date]', supplierInvoice.dueDate);
    if (supplierInvoice.bankAccount) formData.append('supplier_invoice[bank_account]', supplierInvoice.bankAccount);
    if (supplierInvoice.cid) formData.append('supplier_invoice[cid]', supplierInvoice.cid);
    if (supplierInvoice.account.id) formData.append('supplier_invoice[account_id]', supplierInvoice.account.id);
    if (supplierInvoice.project.id) formData.append('supplier_invoice[project_id]', supplierInvoice.project.id);
    if (supplierInvoice.event) formData.append('supplier_invoice[event]', supplierInvoice.event);
    if (supplierInvoice.approverIds) {
      supplierInvoice.approverIds.map(approverId => formData.append('supplier_invoice[approver_ids][]', approverId));
    }
    supplierInvoice.trackingOptions.map((supplierInvoiceTrackingOption, trackingOptionIndex) => {
      if (supplierInvoiceTrackingOption.entityTrackingOptionId) formData.append(`supplier_invoice[supplier_invoice_tracking_options_attributes[${trackingOptionIndex}][id]]`, supplierInvoiceTrackingOption.entityTrackingOptionId);
      formData.append(`supplier_invoice[supplier_invoice_tracking_options_attributes[${trackingOptionIndex}][tracking_option_id]]`, supplierInvoiceTrackingOption.id);
      formData.append(`supplier_invoice[supplier_invoice_tracking_options_attributes[${trackingOptionIndex}][_destroy]]`, supplierInvoiceTrackingOption.destroy);
    });

    supplierInvoice.transactions.map((transaction, index) => {
      if (transaction.id) formData.append(`supplier_invoice[transactions_attributes[${index}[id]]]`, transaction.id);
      if (transaction.account.id) formData.append(`supplier_invoice[transactions_attributes[${index}[account_id]]]`, transaction.account.id);
      if (transaction.vat.id) formData.append(`supplier_invoice[transactions_attributes[${index}[tax_code_id]]]`, transaction.vat.id);
      if (transaction.department.id) formData.append(`supplier_invoice[transactions_attributes[${index}[department_id]]]`, transaction.department.id);
      if (transaction.project.id) formData.append(`supplier_invoice[transactions_attributes[${index}[project_id]]]`, transaction.project.id);
      formData.append(`supplier_invoice[transactions_attributes[${index}[_destroy]]]`, transaction.destroy);
      formData.append(`supplier_invoice[transactions_attributes[${index}[included_vat]]]`, convertToCents(transaction.amount));
      formData.append(`supplier_invoice[transactions_attributes[${index}[description]]]`, transaction.description);
      formData.append(`supplier_invoice[transactions_attributes[${index}[value_cents]]]`, convertToCents(transaction.exVat));
      if (transaction.currencyValue) formData.append(`supplier_invoice[transactions_attributes[${index}[currency_value_cents]]]`, convertToCents(transaction.currencyValue));
      if (transaction.poAmount) formData.append(`supplier_invoice[transactions_attributes[${index}[po_amount]]]`, convertToCents(transaction.poAmount));
      transaction.trackingOptions.map((transactionTrackingOption, trackingOptionIndex) => {
        if (transactionTrackingOption.entityTrackingOptionId) formData.append(`supplier_invoice[transactions_attributes[${index}[transaction_tracking_options_attributes[${trackingOptionIndex}]][id]]]`, transactionTrackingOption.entityTrackingOptionId);
        formData.append(`supplier_invoice[transactions_attributes[${index}[transaction_tracking_options_attributes[${trackingOptionIndex}]][tracking_option_id]]]`, transactionTrackingOption.id);
        formData.append(`supplier_invoice[transactions_attributes[${index}[transaction_tracking_options_attributes[${trackingOptionIndex}]][_destroy]]]`, transactionTrackingOption.destroy);
      });
    });

    supplierInvoice.comments.map((comment, index) => {
      if (comment.id) formData.append(`supplier_invoice[comments_attributes[${index}[id]]]`, comment.id);
      formData.append(`supplier_invoice[comments_attributes[${index}[body]]]`, comment.body);
    });

    return formData;
  }
}

export default SupplierInvoiceModel;
