const numberFormatter = (value, numberFormat = {}) => {
  if (value !== '-') {
    const { decimal_mark, thousands_separator } = numberFormat;
    const parsedValue = I18n.toNumber(value, { delimiter: thousands_separator || ' ', separator: decimal_mark || '.', precision: 2 });
    const newValue = Number.isNaN(parsedValue.replace(',', '.'))
      ? Number(0).toFixed(2)
      : parsedValue;

    return newValue;
  }
  return value;
};

export default numberFormatter;
