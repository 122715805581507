import React from 'react';
import Filters from '@/components/ui/table/filters';
import LeftPositionActions from '@/components/ui/table/left_position_actions';
import Search from '@/components/accountings/shared/search';
import TableHead from '../shared/table_head';
import PurchaseOrderRow from './purchase_orders/purchase_order_row';
import AccountingsService from '../../../services/accountings_service';
import PurchaseOrderAttachmentsFooter from '../shared/footer';
import Table from '../../shared/table';
import PurchaseOrderInfo from './purchase_orders/info';
import SupplierInvoiceInfo from './purchase_orders/SupplierInvoiceInformation';
import * as toast from '../../shared/toast';
import * as swipe from '../../shared/swipe';
import InvoiceOverrideModal from './invoice_override_modal';
import Loader from '../../loader';

class PurchaseOrderAttach extends Table {
  constructor(props) {
    super(props);

    this.order = {};
    this.filters = {
      supplier_id: [],
    };
    this.page = 1;
    const { invoices, fromInvoice } = this.props;

    this.invoicesNumber = invoices.length;
    this.invoiceIds = invoices.map(invoice => invoice.id);
    this.ableToLoadMore = false;
    this.overrideInvoices = invoices.filter(invoice => invoice.purchaseOrderId || invoice.budgetId);
    this.invoicesValue = invoices.reduce((prev, current) => prev + (fromInvoice ? current.exVat : current.value), 0);
  }

  state = {
    currentPage: 1,
    markAsInvoiced: false,
    openOverrideConfirmationModal: false,
    purchaseOrders: [],
    showCompleted: false,
    isLoading: false,
    filters: [],
    filterActions: [],
  }

  componentDidMount() {
    const { supplierId } = this.props;
    const searchUrl = supplierId ? `?&supplier_id[]=${supplierId}` : '?';

    if (supplierId && !this.filters.supplier_id.length) {
      this.filters.supplier_id.push(supplierId?.toString());
    }

    this.setState({ isLoading: true });
    AccountingsService.purchaseOrders(searchUrl).then(({ purchaseOrders, filters }) => {
      this.setState({
        purchaseOrders,
        filters,
        filterActions: filters ? Object.keys(filters) : [],
        isLoading: false,
      }, () => swipe.triggerSwipeEvent());
    });

    const scrollElement = this.windowContentElement('window-content.inside');

    scrollElement.addEventListener('scroll', this.onScroll, false);
  }

  componentWillUnmount() {
    this.windowContentElement().removeEventListener('scroll', this.onScroll, false);
  }

  filterOpened = () => {
    const { showCompleted } = this.state;

    this.filters.state = [];
    this.setState({ showCompleted: !showCompleted }, () => {
      this.filter();
    });
  }

  onFilter = ({ name, value }) => {
    this.onTableLoading();
    const stringValue = value.toString();

    if (this.filters[name]) {
      this.filters[name].push(stringValue);
    } else {
      this.filters[name] = [stringValue];
    }

    this.filter();
  }

  filter = (additionalState = {}, page = 1) => {
    this.onTableLoading();
    const { showCompleted } = this.state;

    const attributes = {
      custom: Object.keys(this.filters).map(key => ({ name: key, value: this.filters[key] })),
      page,
      order: this.order,
      showCompleted,
      per: 25,
    };

    const scrollElement = this.windowContentElement();

    AccountingsService.purchaseOrdersFilter(attributes).then(({ purchaseOrders, nextPage, filters }) => {
      this.setState({
        purchaseOrders,
        filters: Object.assign({}, { ...filters }),
        currentPage: 1,
        ableToLoadMore: !!nextPage && nextPage > 1,
        ...additionalState,
      }, () => {
        scrollElement.scrollTop = 0;
        this.onTableLoad();
      });
    });
  }

  infiniteScrollPaginate = () => {
    this.setState({ ableToLoadMore: false });
    this.onTableLoading();

    const { currentPage, purchaseOrders, showCompleted } = this.state;
    const nextPage = currentPage + 1;

    const attributes = {
      custom: Object.keys(this.filters).map(key => ({ name: key, value: this.filters[key] })),
      page: nextPage,
      order: this.order,
      showCompleted,
      per: 25,
    };

    AccountingsService.purchaseOrdersFilter(attributes).then((data) => {
      if (data.purchaseOrders.length > 0) {
        this.setState({
          purchaseOrders: purchaseOrders.concat(data.purchaseOrders),
          currentPage: nextPage,
          isLoading: false,
          ableToLoadMore: !!data.nextPage && data.nextPage > nextPage,
        });
      } else {
        this.onTableLoad();
      }
    });
  }

  toggleMarkAsInvoiced = () => {
    this.setState({
      markAsInvoiced: !this.state.markAsInvoiced,
    });
  }

  onSaveConfirmation = () => {
    if (this.overrideInvoices.length > 0) {
      this.setState({
        openOverrideConfirmationModal: true,
      });
    } else {
      this.onSave();
    }
  }

  onSave = () => {
    const { purchaseOrderId } = this;
    if (!purchaseOrderId) {
      toast.errorToast(I18n.t('purchase_orders.invoices.select_purchase_order_first'));
      return;
    }

    const { props: { onToggleAttachPoSettings, filter }, state: { markAsInvoiced }, invoiceIds } = this;

    const params = {
      removedInvoiceIds: [],
      addedInvoiceIds: invoiceIds,
      purchaseOrderId,
      markAsInvoiced,
    };

    AccountingsService.attachInvoicesToPurchaseOrder(params).then(({ errors }) => {
      if (errors) {
        toast.errorToast(errors);
      } else {
        filter();
        onToggleAttachPoSettings();
        toast.successToast(I18n.t('commons.messages.changes_saved'));
      }
    }).catch(() => {
      toast.errorToast(I18n.t('commons.messages.error'));
    });
  }

  selectPurchaseOrder = (id) => {
    this.purchaseOrderId = id;
  }

  headers = () => (
    [
      { name: 'po_number', translation: 'purchase_orders.po_number' },
      { name: 'date', translation: 'purchase_orders.date_raised' },
      { name: 'supplier', translation: 'purchase_orders.supplier' },
      { name: 'budget', translation: 'purchase_orders.budget' },
      { name: 'description', translation: 'purchase_orders.description' },
      { name: 'amount', class: 'number price', translation: 'purchase_orders.partials.invoices_body.value' },
      { name: 'not_invoiced', class: 'number price', translation: 'purchase_orders.partials.invoices_body.not_invoiced' },
      { name: 'invoiced', class: 'number price', translation: 'purchase_orders.partials.invoices_body.invoiced' },
      { name: 'committed', class: 'number price', translation: 'purchase_orders.partials.invoices_body.committed' },
      { name: 'invoices', class: 'center', translation: 'purchase_orders.partials.invoices_body.invoices' },
      { name: 'file', translation: 'purchase_orders.pdf_file' },
      { name: 'received', class: 'center', translation: 'purchase_orders.received' },
      { name: 'status', class: 'center', translation: 'purchase_orders.status' },
      { name: 'raised_by', translation: 'purchase_orders.raised_by' },
    ]
  )

  render() {
    const {
      props: {
        onToggleAttachPoSettings, supplierId, fromSupplierInvoice, newModal, amountFormat,
      },
      state: {
        openOverrideConfirmationModal, showCompleted, markAsInvoiced, purchaseOrders,
        isLoading, filters, filterActions,
      },
    } = this;
    const dotsNumber = this.headers().length;
    const htmlClasses = isLoading ? ' has-loading' : '';

    return (
      <div className="modal sub-modal centered modal-table full-width active" style={newModal ? { overflow: 'hidden' } : undefined}>
        <div className="modal-wrapper">
          <div className="modal-window window window-form">
            {openOverrideConfirmationModal
              ? (
                <InvoiceOverrideModal
                  onConfirm={this.onSave}
                  onReject={onToggleAttachPoSettings}
                  invoicesNumber={this.overrideInvoices.length}
                />
              )
              : null}
            <div className="window-header modal-header static-height column">
              {
                fromSupplierInvoice ? (
                  <SupplierInvoiceInfo
                    invoicesNumber={this.invoicesNumber}
                    invoicesValue={this.invoicesValue}
                    amountFormat={amountFormat}
                  />
                ) : (
                  <PurchaseOrderInfo
                    invoicesNumber={this.invoicesNumber}
                    invoicesValue={this.invoicesValue}
                    amountFormat={amountFormat}
                  />
                )
              }
              <div className="window-bar">
                <LeftPositionActions>
                  <Filters
                    filterActions={filterActions}
                    filters={filters}
                    selectedFilters={this.filters}
                    onFilter={this.onFilter}
                    onRemoveFilter={this.onRemoveFilter}
                    defaultValues={{ suppliers: supplierId }}
                  />
                </LeftPositionActions>
                <Search onQueryFilter={this.onQueryFilter} currentFilters={this.filters} />
              </div>
            </div>
            <div className="window-content inside" style={newModal ? { maxHeight: '400px' } : undefined}>
              <div className="table-fluid table-column-9">
                {this._renderInputs(dotsNumber, 'attach')}
                <div className="table-header with-filters">
                  <div className="table-nav">
                    {this._renderLabels(dotsNumber, 'attach')}
                    {this._renderDots(dotsNumber, 'attach')}
                  </div>
                </div>
                <div className="table table-selectable table-sortable">
                  <div className="colgroup">
                    {this._renderLabels(dotsNumber, 'attach')}
                  </div>
                  <TableHead
                    onOrder={this.onOrder}
                    order={this.order}
                    headers={this.headers()}
                  />
                  <div className={`tbody ${htmlClasses}`}>
                    <Loader />
                    {purchaseOrders.map(purchaseOrder => (
                      <PurchaseOrderRow
                        purchaseOrder={purchaseOrder}
                        key={purchaseOrder.id}
                        selectPurchaseOrder={this.selectPurchaseOrder}
                      />
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <PurchaseOrderAttachmentsFooter
              onSave={this.onSaveConfirmation}
              onCancel={onToggleAttachPoSettings}
              toggleMarkAsInvoiced={this.toggleMarkAsInvoiced}
              markAsInvoiced={markAsInvoiced}
              filterOpened={this.filterOpened}
              showOpened={showCompleted}
              footerText={['hide_completed_po', 'show_completed_po']}
            />
          </div>
        </div>
        <label className="modal-backdrop" htmlFor="attach-modal-close" onClick={onToggleAttachPoSettings} />
      </div>
    );
  }
}

export default PurchaseOrderAttach;
