import React from 'react';
import OrderItems from './order_items';
import Invoices from './invoices';

class Lists extends React.Component {
  state = {
    totalInvoicesValue: 0,
    totalInvoicesValueInCurrency: 0,
  }

  setTotalInvoicesValue = (totalInvoicesValue) => {
    this.setState({ totalInvoicesValue });
  }

  setTotalInvoicesValueInCurrency = (totalInvoicesValueInCurrency) => {
    this.setState({ totalInvoicesValueInCurrency });
  }

  render = () => {
    const {
      items,
      categories,
      editable,
      setParitallyReceive,
      receiveGoods,
      currency,
      currencies,
      companyCurrency,
      currentCompany,
      exchangeRate,
      invoices,
      saveDraft,
      isDraft,
      confirmReceiveGoods,
      currentUser,
      isInvoiced,
      isReceived,
      purchaseOrder,
      purchaseOrderSupplierId,
      purchaseOrderDepartment,
      purchaseOrderCurrency,
      suppliers,
      departments,
      serverTime,
      confirmInvoiceGoods,
      shouldDisplayInvoiceList,
      renderSupplierItemsModal,
      onToggleSupplierItemModal,
      integrationType,
      setTotalItemsValue,
      setTotalItemsCurrencyValue,
      totalItemsValue,
      totalItemsCurrencyValue,
      budget,
      purchaseOrderErrors,
      taxEnabled,
      standAlone,
      updatePurchaseOrder,
      saveDraftWithLoading,
      updateInvoices,
      dateFormat,
      numberFormat,
    } = this.props;

    const invoicesProps = {
      invoices,
      currentUser,
      companyCurrency,
      purchaseOrder,
      purchaseOrderSupplierId,
      purchaseOrderDepartment,
      purchaseOrderCurrency,
      suppliers,
      departments,
      currencies,
      serverTime,
      exchangeRate,
      isInvoiced,
      confirmInvoiceGoods,
      integrationType,
      standAlone,
    };

    const itemsProps = {
      purchaseOrder,
      items,
      categories,
      editable,
      setParitallyReceive,
      receiveGoods,
      currency,
      saveDraft,
      isDraft,
      confirmReceiveGoods,
      isReceived,
      suppliers,
      currencies,
      exchangeRate,
      companyCurrency,
      currentCompany,
      invoices,
      currentUser,
      isInvoiced,
      purchaseOrderSupplierId,
      renderSupplierItemsModal,
      onToggleSupplierItemModal,
      budget,
      purchaseOrderErrors,
      taxEnabled,
      saveDraftWithLoading,
    };

    const {
      totalInvoicesValue,
      totalInvoicesValueInCurrency,
    } = this.state;

    return (
      <React.Fragment>
        <OrderItems
          ref={(orderItems) => { this.orderItems = orderItems; }}
          {...itemsProps}
          setTotalItemsValue={setTotalItemsValue}
          setTotalItemsCurrencyValue={setTotalItemsCurrencyValue}
          dateFormat={dateFormat}
          numberFormat={numberFormat}
        />
        {shouldDisplayInvoiceList
          ? (
            <Invoices
              {...invoicesProps}
              totalInvoicesValue={totalInvoicesValue}
              totalInvoicesValueInCurrency={totalInvoicesValueInCurrency}
              totalItemsValue={totalItemsValue}
              totalItemsCurrencyValue={totalItemsCurrencyValue}
              setTotalInvoicesValue={this.setTotalInvoicesValue}
              setTotalInvoicesValueInCurrency={this.setTotalInvoicesValueInCurrency}
              updatePurchaseOrder={updatePurchaseOrder}
              updateInvoices={updateInvoices}
              dateFormat={dateFormat}
              numberFormat={numberFormat}
            />
          )
          : null
        }
      </React.Fragment>
    );
  }
}

export default Lists;
