import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import _ from 'lodash';
import PurchaseOrderModel from '@/models/purchase_order_model';
import { serialize } from 'object-to-formdata';
import { snakeKeys } from 'js-convert-case';
import PaymentPlan from '@/pages/purchaseOrder/components/PaymentPlan/index.jsx';
import camelcaseKeys from 'camelcase-keys';
import Activities from './purchase_order/activities';
import Attachments from './purchase_order/attachments';
import CompanyInfo from './purchase_order/company_info';
import BudgetGraph from './purchase_order/budget_graph';
import DeleteModal from './purchase_order/modals/delete_modal';
import ParentErrorModal from './purchase_order/modals/parent_error_modal';
import ChildrenModal from './purchase_order/modals/children_modal';
import FinancialApproverModal from './purchase_order/modals/financial_approver_modal';
import TypeChangeModal from './purchase_order/modals/type_change_modal';
import ReviseModal from './purchase_order/modals/revise_modal';
import ReopenModal from './purchase_order/modals/reopen_modal';
import ConfirmationModal from './purchase_order/modals/confirmation_modal';
import InternalInfo from './purchase_order/internal_info';
import LastSavedAtTimer from './purchase_order/last_saved_at_timer';
import PredefinedItems from './purchase_order/predefined_items';
import RejectModal from './purchase_order/modals/reject_modal';
import SupplierInfo from './purchase_order/supplier_info';
import SupplierMessageModal from './purchase_order/modals/supplier_message_modal';
import RateService from '../services/rate_service';
import Modal from './shared/modal';
import StrictBudgetControlModal from './purchase_order/modals/strict_budget_control_modal';
import DescriptionLineModel from '../models/description_line_model';
import CurrentCompanyModel from '../models/current_company_model';
import Loader from './loader';

import * as api from './shared/api';
import * as toast from './shared/toast';
import Lists from './purchase_order/lists';
import ReceiveAllModal from './purchase_order/receive_all_modal';
import BudgetModel from '../models/budget_model';
import NotificationBar from './shared/notificationBar';

class PurchaseOrder extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    budgets: PropTypes.array,
    purchaseOrder: PropTypes.object,
    categories: PropTypes.array,
    suppliers: PropTypes.array,
    supplierContacts: PropTypes.array,
    addresses: PropTypes.array,
    companyCurrency: PropTypes.string,
    currencies: PropTypes.array,
    serverTime: PropTypes.string,
    supplierMessage: PropTypes.object,
    department: PropTypes.object,
    departments: PropTypes.array,
    poSetup: PropTypes.object,
    currentUser: PropTypes.object,
    taxEnabled: PropTypes.bool,
    errors: PropTypes.object,
    dateFormat: PropTypes.string,
    numberFormat: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.submitAction = 'save';

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCopy = this.handleCopy.bind(this);
    this.handleRestore = this.handleRestore.bind(this);
    this.delete = this.delete.bind(this);
    this.revise = this.revise.bind(this);
    this.sendData = this.sendData.bind(this);
    this.saveDraft = this.saveDraft.bind(this);
    this.saveDraftWithLoading = this.saveDraftWithLoading.bind(this);
    this.updatePurchaseOrder = this.updatePurchaseOrder.bind(this);
    this.updateInvoices = this.updateInvoices.bind(this);
    this.updateActivities = this.updateActivities.bind(this);
    this.updateSupplierMessage = this.updateSupplierMessage.bind(this);
    this.reject = this.reject.bind(this);
    this.prepareBody = this.prepareBody.bind(this);
    this.handleChangeCategoriesForBudget = this.handleChangeCategoriesForBudget.bind(this);
    this.fetchBudgetData = this.fetchBudgetData.bind(this);
    this.addItemsToPurchaseOrder = this.addItemsToPurchaseOrder.bind(this);
    this.openRejectModal = this.openRejectModal.bind(this);
    this.rejectModalToggle = this.rejectModalToggle.bind(this);
    this.sendToSupplier = this.sendToSupplier.bind(this);
    this.openSendToSupplierModal = this.openSendToSupplierModal.bind(this);
    this.openSupplierMessageModal = this.openSupplierMessageModal.bind(this);
    this.isSupplierMessage = this.isSupplierMessage.bind(this);
    this.closeSupplierMessageModal = this.closeSupplierMessageModal.bind(this);
    this.openParentActiveErrorModal = this.openParentActiveErrorModal.bind(this);
    this.closeParentActiveErrorModal = this.closeParentActiveErrorModal.bind(this);
    this.addActivityToComponent = this.addActivityToComponent.bind(this);
    this.updateCurrency = this.updateCurrency.bind(this);
    this.openDeleteModal = this.openDeleteModal.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.openReviseModal = this.openReviseModal.bind(this);
    this.closeReviseModal = this.closeReviseModal.bind(this);
    this.closeModalUrl = this.closeModalUrl.bind(this);
    this.openConfirmationModal = this.openConfirmationModal.bind(this);
    this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
    this.confirmReceiveGoods = this.confirmReceiveGoods.bind(this);
    this.renderSaveButton = this.renderSaveButton.bind(this);
    this.openNewTransactionModal = this.openNewTransactionModal.bind(this);

    this.prepareReceivedBody = this.prepareReceivedBody.bind(this);
    this.sendToSupplierButton = this.sendToSupplierButton.bind(this);
    this.onToggleChange = this.onToggleChange.bind(this);
    this.confirm = this.confirm.bind(this);
    this.disconfirmOrder = this.disconfirmOrder.bind(this);

    this.markAsInvoiced = false;

    this.state = {
      currentCompany: new CurrentCompanyModel(props.currentCompany),
      categories: props.categories,
      purchaseOrder: props.purchaseOrder,
      activities: props.activities,
      errors: props.errors,
      currency: props.purchaseOrder.currency,
      exchangeRate: props.purchaseOrder.exchange_rate,
      supplierMessage: props.supplierMessage,
      predefinedItems: props.predefinedItems,
      markAsSentToSupplier: !props.purchaseOrder.send_supplier_message,
      invoices: props.invoices,
      totalInvoicesValue: 0,
      totalInvoicesValueInCurrency: 0,
      totalItemsValue: 0,
      totalItemsCurrencyValue: 0,
      receiveAllCommentBody: '',
      receiveAllFile: null,
      partillyReceive: false,
      renderSupplierItemsModal: false,
      supplierCurrency: this.defaultCurrency() || props.purchaseOrder.currency,
      isLoading: false,
      parentActiveChanged: false,
      budget: props.purchaseOrder.budget,
      extendedBudget: null,
      confirmationModalInformation: {},
      modals: {
        isRejectOpen: false,
        isSupplierMessageOpen: false,
        isSentToSupplierOpen: false,
        isDeleteOpen: false,
        isReviseOpen: false,
        isReopenOpen: false,
        isReceiveAllModalOpen: false,
        isSideBarOpen: false,
        isStrictBudgetOpen: false,
        isStrictCategoryOpen: false,
        isParentErrorModalOpen: false,
        isTypeChangeOpen: false,
        isChildrenModalOpen: false,
        isFinancialApproverModalOpen: false,
        isConfirmationOpen: false,
      },
    };
  }

  componentDidMount() {
    const { currency, purchaseOrder } = this.state;
    const reactEvent = document.createEvent('HTMLEvents');
    reactEvent.initEvent('react-ready', true, true);
    if (purchaseOrder.state === 'draft') this.updateCurrency(currency);
    purchaseOrder.budget_id && this.fetchBudgetData(purchaseOrder.budget_id);
    document.dispatchEvent(reactEvent);
  }

  onToggleChange(e) {
    const { state } = this;
    this.setState({
      [e.target.name]: !state[e.target.name],
    });
  }

  onAction(action) {
    this.submitAction = action;
  }

  onChangeReceiveAllComentBody = (value) => {
    this.setState({ receiveAllCommentBody: value });
  };

  onReceiveAllFileChange = (file) => {
    this.setState({ receiveAllFile: file });
  };

  onToggleSupplierItemModal = () => {
    this.setState(prevState => ({ renderSupplierItemsModal: !prevState.renderSupplierItemsModal }));
  };

  onSidebarToggle = () => {
    this.setState(prevState => ({ modals: { isSideBarOpen: !prevState.modals.isSideBarOpen } }));
  };

  onCloseStrictBudgetModal = () => {
    this.setState({ modals: { isStrictBudgetOpen: false } });
  };

  onCloseStrictCategoryModal = () => {
    this.setState({ modals: { isStrictCategoryOpen: false } });
  };

  updateSupplierCurrency = (supplierCurrency) => {
    this.setState({ supplierCurrency });
  };

  defaultCurrency = () => {
    const {
      props: { suppliers, purchaseOrder },
    } = this;
    if (purchaseOrder.supplier_id) {
      const findedSupplier = suppliers.find(supplier => supplier.id === purchaseOrder.supplier_id);
      if (findedSupplier) return findedSupplier.currency;
    }
    return false;
  };

  closeNewTransactionModal = () => {
    const { modals } = this.state;
    modals.isTransactionModalOpen = false;
    this.setState({ modals });
  };

  setPdfLanguage = (pdfLanguage) => {
    const { purchaseOrder } = this.state;
    purchaseOrder.supplier_pdf_language = pdfLanguage;
    this.setState({ purchaseOrder }, () => this.saveDraft());
  };

  setSalesOrder = (id) => {
    const { purchaseOrder } = this.state;
    purchaseOrder.sales_order_id = id;
    this.setState({ purchaseOrder }, () => this.saveDraft());
  };

  setCustomer = (id) => {
    const { purchaseOrder } = this.state;
    purchaseOrder.customer_id = id;
    this.setState({ purchaseOrder }, () => this.saveDraft());
  };

  setAccount = (id) => {
    const { purchaseOrder } = this.state;
    purchaseOrder.account_id = id;
    this.setState({ purchaseOrder }, () => this.saveDraft());
  };

  setMultitenant = (value) => {
    const { purchaseOrder } = this.state;
    purchaseOrder.multitenant = value;
    this.setState({ purchaseOrder }, () => this.saveDraft());
  };

  setStartDate = (date) => {
    const { purchaseOrder } = this.state;
    purchaseOrder.start_date = date;
    this.setState({ purchaseOrder }, () => this.saveDraft());
  };

  setMonthsNumber = (number) => {
    const { purchaseOrder } = this.state;
    purchaseOrder.months_number = number;
    this.setState({ purchaseOrder }, () => this.saveDraft());
  };

  setCustomerUplift = (customerUplift) => {
    const { purchaseOrder } = this.state;
    purchaseOrder.customer_uplift = customerUplift;
    this.setState({ purchaseOrder }, () => this.saveDraft());
  };

  setType = (type) => {
    const { purchaseOrder } = this.state;
    purchaseOrder.type = type;
    this.setState({ purchaseOrder }, () => this.saveDraft());
  };

  setParitallyReceive = (value) => {
    this.setState({ partillyReceive: value });
  };

  setTotalItemsValue = (totalItemsValue) => {
    this.setState({ totalItemsValue });
  };

  setTotalItemsCurrencyValue = (totalItemsCurrencyValue) => {
    this.setState({ totalItemsCurrencyValue });
  };

  confirmInvoiceGoods = () => {
    this.setState({ isLoading: true });
    this.markAsInvoiced = true;
    this.sendData({ flash: true, submit_action: 'invoice' }).then(response => (response ? (window.location = this.closeModalUrl()) : this.setState({ isLoading: false })));
  };

  handleReceiveAllModal = () => {
    const { modals } = this.state;
    modals.isReceiveAllModalOpen = !modals.isReceiveAllModalOpen;
    this.setState({ modals });
  };

  handleCustomFieldChange = (id, value, optionId) => {
    const { purchaseOrder } = this.state;
    const index = purchaseOrder.custom_fields.findIndex(el => el.id === id);

    purchaseOrder.custom_fields[index].custom_field_option_id = optionId;
    purchaseOrder.custom_fields[index].value = value;
    this.setState({ purchaseOrder }, () => this.saveDraft());
  };

  closeAndChange = () => {
    this.resetCustomAttributes();
    this.closeTypeChangeModal();
  };

  handleCustomTypesChange = (budget) => {
    const { purchaseOrder } = this.state;

    purchaseOrder.custom_types = budget.custom_types;

    if (budget.custom_types.length > 1 && !budget.custom_types.includes(purchaseOrder.type)) {
      this.setState({ po_type: null }, () => {
        this.resetCustomAttributes();
      });
    } else if (budget.custom_types.length === 1) {
      this.setState({ po_type: budget.custom_types[0] }, () => {
        this.resetCustomAttributes();
      });
    } else {
      this.setState({ purchaseOrder }, () => this.saveDraft());
    }
  };

  resetCustomAttributes = () => {
    const { purchaseOrder, po_type } = this.state;
    purchaseOrder.type = po_type;
    purchaseOrder.sales_order_id = null;
    purchaseOrder.multitenant = false;
    purchaseOrder.start_date = null;
    purchaseOrder.months_number = null;
    purchaseOrder.customer_uplift = null;
    purchaseOrder.customer_id = null;
    this.internalInfo.confirmTypeChange();
    this.setState({ purchaseOrder }, () => this.saveDraft());
  };

  openTypeChangeModal = (type) => {
    const { purchaseOrder } = this.state;
    if (purchaseOrder.type === null) {
      this.setType(type);
    } else {
      this.setState({
        modals: { isTypeChangeOpen: true },
        po_type: type,
      });
    }
  };

  closeTypeChangeModal = () => {
    this.setState({ modals: { isTypeChangeOpen: false } });
  };

  openChildrenModal = () => {
    this.setState({ modals: { isChildrenModalOpen: true } });
  };

  closeChildrenModal = () => {
    this.setState({ modals: { isChildrenModalOpen: false } });
  };

  openFinancialApproverModal = () => {
    this.setState({ modals: { isFinancialApproverModalOpen: true } });
  };

  closeFinancialApproverModal = () => {
    this.setState({ modals: { isFinancialApproverModalOpen: false } });
  };

  handleSubmitParentActive = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.sendToggleParentActiveData({ flash: true }).then((response) => {
      if (response) window.location.href = this.closeModalUrl();
      else this.setState({ isLoading: false, parentActiveChanged: false });
    });
  };

  handleParentActiveChanged = () => {
    this.setState({ parentActiveChanged: true });
  };

  sendToggleParentActiveData = (attrs = {}) => {
    const { purchaseOrder } = this.props;
    const body = { parent_active: this.companyInfo.state.parentActive };
    return api
      .makePutRequest(`/api/purchase_orders/${purchaseOrder.id}/toggle_parent_active`, _.merge(body, attrs))
      .catch((error) => {
        if (error.response) {
          switch (error.response.status) {
            case 422:
              this.setState({ errors: error.response.data.errors });
              if (error.response.data.errors.parent_active) {
                this.setState({ modals: { isParentErrorModalOpen: true } });
              }
              break;
            case 401:
              toast.errorToast(I18n.t('commons.messages.unauthorized'));
              break;
            case 500:
              toast.errorToast(I18n.t('commons.messages.error'));
              break;
            default:
              toast.errorToast(I18n.t('commons.messages.error'));
              Raven.captureException(error);
          }
        } else {
          toast.errorToast(I18n.t('commons.messages.error'));
          Raven.captureException(error);
        }
        return false;
      });
  };

  openReopenModal = () => {
    this.setState({ modals: { isReopenOpen: true } });
  };

  reopen = () => {
    const { purchaseOrder } = this.props;
    this.setState({ isLoading: true });
    this.submitAction = 'reopen';

    this.sendData({ flash: true, submit_action: 'reopen' })
      .then(() => {
        window.location.href = `/purchase_orders/${purchaseOrder.id}/edit?list=invoice`;
        this.setState({ isLoading: false });
      })
      .catch(() => {
        this.setState({ isLoading: false });
      });
  };

  confirm = (data, submitAction = 'confirm') => {
    const confirmedDate = data.confirmedDeliveryDate.format('DD-MM-YYYY');
    const dates = { confirmedDeliveryDate: data.confirmedDeliveryDate.format('DD-MM-YYYY') };
    if (submitAction === 'confirm') dates.expectedDeliveryDate = confirmedDate;

    this.setState({ isLoading: true, confirmationModalInformation: { ...data, ...dates } }, () => {
      this.confirmationRequest(submitAction);
    });
  };

  handleSubmitApproveState = (e) => {
    e.preventDefault();
    this.setState({ isLoading: true });
    this.submitAction = 'save';
    this.sendData({ flash: false }).then((response) => {
      if (response) {
        this.submitAction = 'approve';
        this.handleSubmit(e);
      } else this.setState({ isLoading: false });
    });
  };

  handlePreviewPdf = () => {
    const {
      state: { purchaseOrder },
    } = this;
    if (this.isDraft()) {
      this.setState({ isLoading: true });
      this.submitAction = 'save';
      this.sendData({ flash: false }).then((response) => {
        if (response) {
          window.open(`/purchase_orders/${purchaseOrder.id}.pdf`, '_blank');
        }
        this.setState({ isLoading: false });
      });
    } else {
      window.open(`/purchase_orders/${purchaseOrder.id}.pdf`, '_blank');
    }
  };

  copyPurchaseOrder = () => {
    const { purchaseOrder } = this.props;
    api
      .makePostRequest(`/purchase_orders/${purchaseOrder.id}/copy`)
      .then((response) => {
        window.location = `/purchase_orders/${response.data.id}/edit`;
      })
      .catch((error) => {
        toast.errorToast(I18n.t('commons.messages.error'));
        this.setState({ isLoading: false });
        Raven.captureException(error);
      });
  };

  handleSendToApproval = () => {
    this.setState({ isLoading: true });
    this.submitAction = 'save';
    this.sendData({ flash: false }).then((response) => {
      if (response) {
        this.submitAction = 'send_to_approve';
        this.submitData();
      } else this.setState({ isLoading: false });
    });
  };

  disconfirmOrder() {
    const { purchaseOrder } = this.props;
    this.setState({ isLoading: true });
    const data = {
      confirmation_note: null,
      confirmation_file: null,
      confirmed_delivery_date: null,
      submit_action: 'disconfirm',
    };

    api.makePutRequest(`/purchase_orders/${purchaseOrder.id}`, data, false).then(() => {
      window.location.reload();
    });
  }

  confirmationRequest(submitAction) {
    const { purchaseOrder } = this.props;
    const { confirmationModalInformation } = this.state;
    const data = serialize({
      purchase_order: snakeKeys(confirmationModalInformation),
      flash: true,
      submit_action: submitAction,
    });

    api.makePutRequest(`/purchase_orders/${purchaseOrder.id}`, data, false).then(() => {
      window.location.reload();
    });
  }

  isSupplierMessage() {
    const { supplierMessage } = this.state;
    return supplierMessage && supplierMessage.id;
  }

  closeSupplierMessageModal() {
    const { modals } = this.state;
    if (modals.isSupplierMessageOpen) {
      if (this.isSupplierMessage()) {
        this.submitAction = 'save';
        this.sendData();
      } else {
        this.internalInfo.setAutosendOff();
      }
    }
    this.setState({ modals: { isSentToSupplierOpen: false, isSupplierMessageOpen: false } });
  }

  openDeleteModal(e) {
    e.preventDefault();
    const { purchaseOrder } = this.props;

    if (purchaseOrder.parent_active && purchaseOrder.children_present) {
      this.openChildrenModal();
    } else {
      this.setState({ modals: { isDeleteOpen: true } });
    }
  }

  closeDeleteModal() {
    this.setState({ modals: { isDeleteOpen: false } });
  }

  openReviseModal(e) {
    e.preventDefault();
    this.setState({ modals: { isReviseOpen: true } });
  }

  closeReviseModal() {
    this.setState({ modals: { isReviseOpen: false } });
  }

  openRejectModal(e) {
    e.preventDefault();
    this.rejectModalToggle();
  }

  rejectModalToggle() {
    const { modals } = this.state;
    this.setState({ modals: { isRejectOpen: !modals.isRejectOpen } });
  }

  openSendToSupplierModal() {
    this.setState({ modals: { isSentToSupplierOpen: true } });
  }

  openSupplierMessageModal() {
    this.setState({ modals: { isSupplierMessageOpen: true } });
  }

  openParentActiveErrorModal() {
    this.setState({ modals: { isParentErrorModalOpen: true } });
  }

  closeParentActiveErrorModal() {
    this.setState({ modals: { isParentErrorModalOpen: false } });
    this.companyInfo.setState({ parentActive: !this.companyInfo.state.parentActive });
  }

  openConfirmationModal() {
    this.setState({ modals: { isConfirmationOpen: true } });
  }

  closeConfirmationModal() {
    this.setState({ modals: { isConfirmationOpen: false } });
  }

  addActivityToComponent(activity) {
    this.activities.addActivity(activity);
  }

  openNewTransactionModal() {
    const { modals } = this.state;
    modals.isTransactionModalOpen = true;
    this.setState({ modals });
  }

  async sendData(attrs = {}) {
    const { state, props } = this;
    let body = {};
    if (
      props.currentUser.isAdmin
      && _.includes(['sent_to_supplier', 'received', 'invoiced', 'completed'], state.purchaseOrder.state)
    ) {
      const invoiceObjectBody = this.markAsInvoiced ? this.prepareInvoicesBody() : {};
      if (props.list == 'receive') {
        body = _.merge(this.prepareBody(), invoiceObjectBody, this.prepareReceivedBody());
      } else {
        body = _.merge(this.prepareBody(), this.prepareReceivedBody(), invoiceObjectBody);
      }
    } else if (props.list == 'receive') {
      body = this.prepareReceivedBody();
    } else if (props.list == 'invoice') {
      body = this.prepareInvoicesBody();
    } else {
      body = this.prepareBody();
    }

    return api
      .makePutRequest(`/purchase_orders/${props.purchaseOrder.id}`, _.merge(body, attrs))
      .then((response) => {
        const { items } = response.data.purchase_order;
        const { description_lines } = response.data.purchase_order;
        const descriptionLines = description_lines.map(line => new DescriptionLineModel(line));
        this.lists.orderItems.state.descriptionLines = descriptionLines;

        let newItems = this.lists.orderItems.state.items;

        newItems = newItems.map(obj => ({ ...obj, ...items.find(i => i.uuid === obj.uuid) }));
        newItems = newItems.filter(obj => obj._destroy !== '1');

        this.lists.orderItems.updateItems(newItems, false);
        if (this.predefinedItemsRef) {
          this.predefinedItemsRef.setItems(response.data.purchase_order.predefined_items);
        }

        this.updatePurchaseOrder(response.data.purchase_order);
        this.updateActivities(response.data.purchase_order.activities);
        if (this.submitAction === 'receive_all') {
          const formData = new FormData();
          formData.append('purchase_order_file[attachment]', state.receiveAllFile);
          formData.append('purchase_order_file[category]', 'internal');
          formData.append('purchase_order_file[delivery_id]', response.data.purchase_order.deliveries[0].id);
          api.makePostFileRequest('files', formData).then(() => true);
        }
        this.setState({ errors: {}, predefinedItems: response.data.purchase_order.predefined_items });
        return true;
      })
      .catch((error) => {
        if (error.response) {
          switch (error.response.status) {
            case 422:
              this.setState({ errors: error.response.data.purchase_order.errors });
              if (error.response.data.purchase_order.errors.strict_control) {
                this.setState({ modals: { isStrictBudgetOpen: true } });
              }
              if (error.response.data.purchase_order.errors.category_strict_control) {
                this.setState({ modals: { isStrictCategoryOpen: true } });
              }
              if (error.response.data.purchase_order.errors.parent_active) {
                this.setState({ modals: { isParentErrorModalOpen: true } });
              }
              if (error.response.data.purchase_order.errors.financial_approver_id) {
                this.setState({ modals: { isFinancialApproverModalOpen: true } });
              }
              if (error.response.data.purchase_order.errors.currency) {
                toast.errorToast(error.response.data.purchase_order.errors.currency.join(' '));
              }
              state.purchaseOrder.custom_fields = error.response.data.custom_fields;
              this.setState({ purchaseOrder: state.purchaseOrder });
              this.lists.orderItems.updateItems(error.response.data.items);
              break;
            case 401:
              toast.errorToast(I18n.t('commons.messages.unauthorized'));
              break;
            case 500:
              toast.errorToast(I18n.t('commons.messages.error'));
              break;
            default:
              toast.errorToast(I18n.t('commons.messages.error'));
              Raven.captureException(error);
          }
        } else {
          toast.errorToast(I18n.t('commons.messages.error'));
          Raven.captureException(error);
        }
        return false;
      });
  }

  delete() {
    const { purchaseOrder } = this.props;
    this.setState({ isLoading: true });
    api
      .makeDeleteRequest(`/purchase_orders/${purchaseOrder.id}`)
      .then(() => {
        window.location.href = this.closeModalUrl();
      })
      .catch((error) => {
        toast.errorToast(I18n.t('commons.messages.error'));
        this.setState({ isLoading: false });
        Raven.captureException(error);
      });
  }

  revise() {
    const { purchaseOrder } = this.props;
    this.setState({ isLoading: true });
    api
      .makePostRequest(`/purchase_orders/${purchaseOrder.id}/revise`)
      .then(() => {
        window.location.href = `/purchase_orders/${purchaseOrder.id}/edit`;
      })
      .catch((error) => {
        toast.errorToast(I18n.t('commons.messages.error'));
        this.setState({ isLoading: false });
        Raven.captureException(error);
      });
  }

  sendToSupplier() {
    this.setState({ isLoading: true });
    this.submitAction = 'send_to_supplier';
    this.sendData({ flash: true }).then((response) => {
      if (response) window.location.href = this.closeModalUrl();
      else this.setState({ isLoading: false });
    });
  }

  reject() {
    this.setState({ isLoading: true });
    this.submitAction = 'reject';
    this.sendData({ flash: true, purchase_order: { action_body: this.rejectModal.state.body } }).then((response) => {
      if (response) window.location.href = this.closeModalUrl();
      else this.setState({ isLoading: false });
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.submitData();
  }

  submitData() {
    this.setState({ isLoading: true });
    this.sendData({ flash: true }).then((response) => {
      if (response) window.location.href = this.closeModalUrl();
      else this.setState({ isLoading: false });
    });
  }

  handleCopy(e) {
    e.preventDefault();
    if (this.isDraft()) {
      this.setState({ isLoading: true });
      this.submitAction = 'save';
      this.sendData({ flash: false }).then((sendDataResponse) => {
        if (sendDataResponse) {
          this.copyPurchaseOrder();
        } else this.setState({ isLoading: false });
      });
    } else {
      this.copyPurchaseOrder();
    }
  }

  handleRestore(e) {
    const { purchaseOrder } = this.props;
    e.preventDefault();
    this.setState({ isLoading: true });
    api
      .makePostRequest(`/purchase_orders/${purchaseOrder.id}/restore`)
      .then(() => {
        window.location = this.closeModalUrl();
      })
      .catch((error) => {
        toast.errorToast(I18n.t('commons.messages.error'));
        this.setState({ isLoading: false });
        Raven.captureException(error);
      });
  }

  handleChangeCategoriesForBudget(budget_id) {
    api.makeGetRequest(`/budget_categories/?budget_id=${budget_id}.json`).then((response) => {
      this.lists.orderItems.updateItems(null, true);
      this.setState({ categories: response.data.budget_categories }, () => {
        this.saveDraft();
      });
    });
    this.fetchBudgetData(budget_id);
  }

  fetchBudgetData(budget_id) {
    api.makeGetRequest(`/api/budgets/${budget_id}`).then((response) => {
      this.setState({ extendedBudget: new BudgetModel(response.data.budget) });
    });
  }

  addItemsToPurchaseOrder(items) {
    const { items: orderItems, descriptionLines } = this.lists.orderItems.state;
    const { length } = [...orderItems, ...descriptionLines];
    const newItems = items.map((item, index) => {
      const newItem = item;
      newItem.position = length + index;
      return newItem;
    });
    this.lists.orderItems.updateAndSaveItems(this.lists.orderItems.state.items.concat(newItems));
  }

  confirmReceiveGoods(receiveType = 'receive') {
    this.setState({ isLoading: true });
    this.submitAction = receiveType;
    this.sendData({ flash: true }).then((response) => {
      if (response) window.location = this.closeModalUrl();
      else this.setState({ isLoading: false });
    });
  }

  updatePurchaseOrder(purchaseOrder) {
    this.setState({ purchaseOrder });
  }

  updateActivities(activities) {
    this.activities.updateActivities(activities);
  }

  updateInvoices(invoices) {
    this.setState({ invoices });
  }

  updateSupplierMessage(supplierMessage) {
    this.setState(
      {
        supplierMessage,
      },
      () => {
        this.closeSupplierMessageModal();
      },
    );
  }

  updateCurrency(currency, update = true) {
    const { companyCurrency } = this.props;
    RateService.findBy(currency, companyCurrency).then((value) => {
      this.setState({ exchangeRate: value, currency }, () => {
        if (update) this.saveDraft();
        this.internalInfo.updateCurrency(currency);
      });
    });
  }

  saveDraft() {
    if (this.isDraft()) {
      this.submitAction = 'save';
      this.sendData();
    }
  }

  saveDraftWithLoading() {
    this.setState({ isLoading: true });

    if (this.isDraft()) {
      this.submitAction = 'save';
      this.sendData().then(() => this.setState({ isLoading: false }));
    } else {
      this.setState({ isLoading: false });
    }
  }

  isDraft() {
    const { purchaseOrder } = this.state;
    return ['draft', 'revised', 'rejected'].includes(purchaseOrder.state);
  }

  prepareReceivedBody() {
    const {
      state: { modals, receiveAllCommentBody },
      props: { currentUser },
    } = this;
    return {
      purchase_order: {
        items_attributes: this.lists.orderItems.yieldReceived(),
        comments_attributes: modals.isReceiveAllModalOpen
          ? [{ body: receiveAllCommentBody, user_id: currentUser.id }]
          : [],
      },
      submit_action:
        this.lists.orderItems.state.markAsReceived || modals.isReceiveAllModalOpen ? this.submitAction : 'save',
      list: 'receive',
    };
  }

  prepareBody() {
    const { currency, markAsSentToSupplier, purchaseOrder } = this.state;
    return {
      purchase_order: {
        budget_id: this.internalInfo.state.budget_id,
        project_id: this.internalInfo.state.project_id,
        company_address_contact_id: this.companyInfo.state.purchaseOrderCompanyAddressContact.id,
        company_address_contact_name: this.companyInfo.state.purchaseOrderCompanyAddressContact.name,
        company_address_contact_email: this.companyInfo.state.purchaseOrderCompanyAddressContact.email,
        company_address_contact_phone: this.companyInfo.state.purchaseOrderCompanyAddressContact.phone,
        delivery_address_contact_id: this.companyInfo.state.purchaseOrderDeliveryAddressContact.id,
        delivery_address_contact_name: this.companyInfo.state.purchaseOrderDeliveryAddressContact.name,
        delivery_address_contact_email: this.companyInfo.state.purchaseOrderDeliveryAddressContact.email,
        delivery_address_contact_phone: this.companyInfo.state.purchaseOrderDeliveryAddressContact.phone,
        autosend: this.internalInfo.state.autosend,
        items_attributes: this.lists.orderItems ? this.lists.orderItems.state.items : [],
        description_lines_attributes: this.lists.orderItems
          ? this.lists.orderItems.state.descriptionLines.map(line => line.toParams())
          : [],
        supplier_id: this.supplierInfo.state.supplier_id,
        supplier_contact_id: this.supplierInfo.state.supplier_contact_id,
        supplier_pdf_language: purchaseOrder.supplier_pdf_language,
        type: purchaseOrder.type,
        sales_order_id: purchaseOrder.sales_order_id,
        start_date: purchaseOrder.start_date,
        months_number: purchaseOrder.months_number,
        customer_uplift: purchaseOrder.customer_uplift,
        multitenant: purchaseOrder.multitenant,
        customer_id: purchaseOrder.customer_id,
        account_id: purchaseOrder.account_id,
        company_address_id: this.companyInfo.state.companyAddressId,
        delivery_address_id: this.companyInfo.state.deliveryAddressId,
        invoice_address_id: this.companyInfo.state.invoiceAddressId,
        parent_active: this.companyInfo.state.parentActive,
        parent_id: this.companyInfo.state.parentId,
        description: this.companyInfo.state.description,
        currency,
        delivery_date: (this.supplierInfo.state.delivery_date ? this.supplierInfo.state.delivery_date.format('DD-MM-YYYY') : null),
        delivery_terms: this.supplierInfo.state.delivery_terms,
        payment_terms: this.supplierInfo.state.payment_terms,
        payment_term_id: this.supplierInfo.state.paymentTermId,
        comments_for_supplier: this.supplierInfo.state.comments_for_supplier,
        send_supplier_message: !markAsSentToSupplier,
        custom_fields_attributes: this.prepareCustomFields(purchaseOrder) || [],
        calculation_method: this.lists.orderItems.state.calculationMethod,
        tax: this.lists.orderItems.state.tax,
        expected_delivery_date: this.supplierInfo.state?.expected_delivery_date?.format('DD-MM-YYYY'),
      },
      submit_action: this.submitAction,
    };
  }

  /* eslint-disable no-param-reassign */
  prepareCustomFields(purchaseOrder) {
    const tempCustomFields = purchaseOrder.custom_fields.map(cf => Object.assign({}, cf));
    tempCustomFields.filter(cf => cf.date_field).forEach((customField) => {
      customField.value = moment(customField.value, customField.date_format).format('YYYY-MM-DD');
    });
    return tempCustomFields;
  }
  /* eslint-enable no-param-reassign */

  prepareInvoicesBody() {
    return {
      submit_action: this.markAsInvoiced ? 'invoice' : 'save',
      list: 'invoice',
    };
  }

  totalRateOverall() {
    return this.lists.orderItems.state.totalRateOverall;
  }

  closeModalUrl() {
    return sessionStorage.getItem('closeModalUrl') || '/';
  }

  saveAndClose() {
    if (this.isDraft()) {
      this.submitAction = 'save';
      this.setState({ isLoading: true });
      this.sendData({ flash: true }).then((response) => {
        if (response) window.location.href = this.closeModalUrl();
        else this.setState({ isLoading: false });
      });
    } else {
      window.location.href = this.closeModalUrl();
    }
  }

  sendToSupplierButton() {
    const { markAsSentToSupplier } = this.state;
    if (markAsSentToSupplier) {
      return (
        <input
          type="submit"
          className="button button-primary"
          value={I18n.t('purchase_orders.form.done')}
          onClick={this.sendToSupplier}
        />
      );
    }
    return (
      <input
        type="submit"
        id="send-to-supplier"
        className="button button-primary"
        value={I18n.t('purchase_orders.form.sent_to_supplier')}
        onClick={this.openSendToSupplierModal}
      />
    );
  }

  shouldDisplayInvoiceList() {
    const {
      props: { list, currentUser },
      state: { purchaseOrder, invoices },
    } = this;
    return (
      ((_.includes(purchaseOrder.permitted_actions, 'invoice') || purchaseOrder.state === 'invoiced')
        && list == 'invoice')
      || purchaseOrder.state === 'completed'
      || (!list && currentUser.isAdmin && purchaseOrder.state === 'invoiced')
      || purchaseOrder.state === 'completed'
      || invoices.length > 0
    );
  }

  renderSaveButton() {
    const {
      list,
      currentUser,
      purchaseOrder: { state },
    } = this.props;

    if (this.isDraft()) {
      return (
        <form onSubmit={this.handleSubmit}>
          <input
            type="submit"
            className="button inverse button-mute"
            value={I18n.t('purchase_orders.form.save_as_draft')}
            onClick={this.onAction.bind(this, 'save')}
          />
        </form>
      );
    }
    if (!list && currentUser.isAdmin) {
      const showButton = state === 'approved' || state === 'rejected';
      const buttonClass = showButton ? 'button inverse button-mute' : 'button button-primary';
      return (
        <form onSubmit={this.handleSubmit}>
          <input
            type="submit"
            className={buttonClass}
            value={I18n.t('purchase_orders.form.receive_save')}
            onClick={this.onAction.bind(this, 'save')}
          />
        </form>
      );
    }
  }

  render() {
    const {
      props: {
        permissions,
        suppliers,
        supplierContacts,
        budgets,
        addresses,
        attachments,
        serverTime,
        currencies,
        predefinedItems,
        currentUser,
        isTutorialPending,
        companyCurrency,
        department,
        list,
        departments,
        poSetup,
        projects,
        fieldSettings,
        integrationType,
        parents,
        sales_orders,
        customers,
        accounts,
        accountsEnabled,
        taxEnabled,
        customFieldSettings,
        paymentTerms,
        standAlone,
        cashFlowEnabled,
        numberFormat,
        dateFormat,
      },
      state: {
        purchaseOrder,
        activities,
        categories,
        errors,
        invoices,
        currency,
        exchangeRate,
        totalInvoicesValue,
        totalItemsValue,
        totalItemsCurrencyValue,
        markAsSentToSupplier,
        modals,
        supplierMessage,
        receiveAllFile,
        purchaseOrder: { editable },
        receiveAllCommentBody,
        partillyReceive,
        renderSupplierItemsModal,
        supplierCurrency,
        isLoading,
        budget,
        parentActiveChanged,
        currentCompany,
        extendedBudget,
      },
    } = this;

    const loadingClass = isLoading ? ' has-loading' : '';
    const supplierId = this.supplierInfo ? this.supplierInfo.state.supplier_id : purchaseOrder.supplier_id;
    const commentsCount = purchaseOrder.activities.filter(e => e.activity_type == 'commented').length;
    const { decimal_mark, thousands_separator } = numberFormat;
    const amountFormat = { delimiter: thousands_separator, separator: decimal_mark, precision: 2 };

    return (
      <div className={loadingClass}>
        <Loader />
        <input
          autoComplete="off"
          defaultChecked="true"
          className="modal-close"
          id="modal-close"
          name="create-po"
          type="radio"
          value="off"
        />
        <input
          autoComplete="off"
          defaultChecked="true"
          className="modal-close"
          id="transaction-modal-close"
          name="transaction-modal"
          type="radio"
          value="off"
        />
        <input
          autoComplete="off"
          className="modal-close"
          id="sub-modal-close"
          name="create-po-add"
          type="radio"
          value="off"
        />
        <input
          autoComplete="off"
          defaultChecked="true"
          className="modal-open"
          id="create-po-modal"
          name="create-po"
          type="radio"
          value="on"
        />
        <div className="modal centered modal-fullscreen">
          <div className="modal-wrapper">
            <div className="modal-window window window-form">
              <div className="window-header modal-header ">
                <span>
                  {purchaseOrder.state === 'draft'
                    ? I18n.t('purchase_orders.form.new_purchase_order')
                    : I18n.t('purchase_orders.form.purchase_order')}
                  <span className="mute">{` ${purchaseOrder.po_number}`}</span>
                  {purchaseOrder.state === 'received' || purchaseOrder.state === 'completed' ? (
                    <span className="margin-left-3">
                      {partillyReceive ? (
                        <span className="has-tooltip left-middle" data-tooltip="partially received">
                          <span className="dot-icon icon-par_rec">
                            <span className="path1" />
                            <span className="path2" />
                            <span className="path3" />
                            <span className="path4" />
                          </span>
                        </span>
                      ) : null}
                      <span className={`status ${purchaseOrder.state}`}>{purchaseOrder.state}</span>
                    </span>
                  ) : null}
                </span>
                <span>
                  <LastSavedAtTimer serverTime={serverTime} updatedAt={purchaseOrder.updated_at} />
                  <label className="mobile-inline-block" htmlFor="internal-comment-modal">
                    <i className="icon-question_answer" />
                  </label>
                  <label className="modal-close">
                    <a onClick={() => this.saveAndClose()}>
                      <i className="icon-close" />
                    </a>
                  </label>
                </span>
              </div>
              {purchaseOrder.outdated
                && (
                  <div className="window-toasts">
                    <div className="toast show error">
                      <i className="icon-info" />
                      {' '}
                      {I18n.t('errors.purchase_order.outdated_title')}
                    </div>
                  </div>
                )
              }
              {purchaseOrder.external_files_limit_reached && (
                <NotificationBar
                  type="error"
                  iconName="icon-email"
                  content={I18n.t('purchase_orders.external_files_error', {
                    size: purchaseOrder.external_files_limit_size,
                  })}
                />
              )}
              <div className="window-content">
                <div className={`window-wrapper grid ${modals.isSideBarOpen ? null : 'collapse'}`}>
                  <div className="window-sidebar-toggle">
                    <i className="icon-attach_file" onClick={this.onSidebarToggle} />
                    <i className="icon-question_answer" onClick={this.onSidebarToggle}>
                      <div className="comments-count">
                        {commentsCount}
                      </div>
                    </i>
                  </div>
                  <div className={`form-section cell-4 ${editable ? '' : 'form-not-editable'}`}>
                    <div className="form-section-header">
                      <span>{I18n.t('purchase_orders.form.supplier_info')}</span>
                    </div>
                    <div className="form-section-content">
                      <SupplierInfo
                        ref={(supplierInfo) => {
                          this.supplierInfo = supplierInfo;
                        }}
                        permissions={permissions}
                        suppliers={suppliers}
                        isTutorialPending={isTutorialPending}
                        purchaseOrder={purchaseOrder}
                        supplierContacts={supplierContacts}
                        currencies={currencies}
                        supplierError={errors.supplier_id}
                        supplierContactError={errors.supplier_contact_id}
                        deliveryDateError={errors.delivery_date}
                        paymentTermsError={errors.payment_terms}
                        deliveryTermsError={errors.delivery_terms}
                        expectedDeliveryDateError={errors.expected_delivery_date}
                        updateCurrency={this.updateCurrency}
                        companyCurrency={companyCurrency}
                        saveDraft={this.saveDraft}
                        updateSupplierCurrency={this.updateSupplierCurrency}
                        integrationType={integrationType}
                        paymentTerms={paymentTerms}
                        paymentTermError={errors.payment_term_id}
                        dateFormat={dateFormat}
                      />
                    </div>
                  </div>

                  <div className={`form-section cell-4 ${editable ? '' : 'form-not-editable'}`}>
                    <div className="form-section-header">
                      <span>{I18n.t('purchase_orders.form.company_info')}</span>
                    </div>
                    <div className="form-section-content">
                      <CompanyInfo
                        ref={(companyInfo) => {
                          this.companyInfo = companyInfo;
                        }}
                        permissions={permissions}
                        isTutorialPending={isTutorialPending}
                        addresses={addresses}
                        purchaseOrder={purchaseOrder}
                        currentCompany={currentCompany}
                        parents={parents}
                        companyAddressError={errors.company_address_id}
                        deliveryAddressError={errors.delivery_address_id}
                        invoiceAddressError={errors.invoice_address_id}
                        descriptionError={errors.description}
                        companyAddressContactError={errors.company_address_contact_id}
                        deliveryAddressContactError={errors.delivery_address_contact_id}
                        parentActiveError={errors.parent_active}
                        saveDraft={this.saveDraft}
                        handleParentActiveChanged={this.handleParentActiveChanged}
                      />
                    </div>
                  </div>

                  <div className={`form-section cell-4 ${editable ? '' : 'form-not-editable'}`}>
                    <InternalInfo
                      ref={(internalInfo) => {
                        this.internalInfo = internalInfo;
                      }}
                      fieldSettings={fieldSettings}
                      budgets={budgets}
                      purchaseOrder={purchaseOrder}
                      handleChangeCategoriesForBudget={this.handleChangeCategoriesForBudget}
                      currencies={currencies}
                      currency={currency}
                      currencyError={errors.currency}
                      companyCurrency={companyCurrency}
                      exchangeRate={exchangeRate}
                      handleUpdateCurrency={this.updateCurrency}
                      budgetError={errors.budget}
                      autosendError={errors.autosend}
                      saveDraft={this.saveDraft}
                      openSupplierMessageModal={this.openSupplierMessageModal}
                      department={department}
                      projects={projects}
                      projectError={errors.project_id}
                      setPdfLanguage={this.setPdfLanguage}
                      sales_orders={sales_orders}
                      setSalesOrder={this.setSalesOrder}
                      setStartDate={this.setStartDate}
                      setMonthsNumber={this.setMonthsNumber}
                      setCustomerUplift={this.setCustomerUplift}
                      totalItemsValue={totalItemsValue}
                      setMultitenant={this.setMultitenant}
                      customers={customers}
                      setCustomer={this.setCustomer}
                      openTypeChangeModal={this.openTypeChangeModal}
                      salesOrderError={errors.sales_order_id}
                      typeError={errors.type}
                      startDateError={errors.start_date}
                      monthsNumberError={errors.months_number}
                      customerUpliftError={errors.customer_uplift}
                      customerError={errors.customer_id}
                      accounts={accounts}
                      setAccount={this.setAccount}
                      accountError={errors.account_id}
                      accountsEnabled={accountsEnabled}
                      handleCustomTypesChange={this.handleCustomTypesChange}
                      customFieldSettings={customFieldSettings}
                      handleCustomFieldChange={this.handleCustomFieldChange}
                      dateFormat={dateFormat}
                      numberFormat={numberFormat}
                    />

                    {cashFlowEnabled && (
                      <PaymentPlan
                        paymentPlanId={purchaseOrder.payment_plan_id}
                        companyCurrency={companyCurrency}
                        poCurrency={purchaseOrder.currency}
                        poExchangeRate={purchaseOrder.exchange_rate}
                        invoices={camelcaseKeys(invoices, { deep: true })}
                        items={camelcaseKeys(this.lists?.orderItems?.state?.items || purchaseOrder.items, {
                          deep: true,
                        })}
                      />
                    )}
                  </div>
                  {extendedBudget && purchaseOrder.state === 'sent_to_approve' && (
                    <BudgetGraph
                      planned={purchaseOrder.planned}
                      budget={extendedBudget}
                      exchangeRate={exchangeRate}
                      items={this.lists.orderItems.state.items}
                      currentCompany={currentCompany}
                      numberFormat={numberFormat}
                    />
                  )}
                  <Lists
                    ref={(lists) => {
                      this.lists = lists;
                    }}
                    items={purchaseOrder.items}
                    setParitallyReceive={this.setParitallyReceive}
                    categories={categories}
                    editable={editable}
                    receiveGoods={
                      ((_.includes(purchaseOrder.permitted_actions, 'receive') || purchaseOrder.state === 'received')
                        && list == 'receive')
                      || purchaseOrder.state === 'completed'
                      || (!list && currentUser.isAdmin && purchaseOrder.state === 'received')
                      || purchaseOrder.state === 'completed'
                    }
                    currency={currency}
                    currencies={currencies}
                    companyCurrency={companyCurrency}
                    currentCompany={currentCompany}
                    exchangeRate={exchangeRate}
                    invoices={invoices}
                    saveDraft={this.saveDraft}
                    saveDraftWithLoading={this.saveDraftWithLoading}
                    isDraft={this.isDraft()}
                    confirmReceiveGoods={this.confirmReceiveGoods}
                    currentUser={currentUser}
                    isInvoiced={purchaseOrder.state == 'invoiced' || purchaseOrder.state == 'completed'}
                    isReceived={purchaseOrder.state == 'received' || purchaseOrder.state == 'completed'}
                    sumItems={this.sumItems}
                    purchaseOrder={purchaseOrder}
                    updatePurchaseOrder={this.updatePurchaseOrder}
                    updateInvoices={this.updateInvoices}
                    purchaseOrderSupplierId={supplierId}
                    purchaseOrderDepartment={department}
                    purchaseOrderCurrency={purchaseOrder.currency}
                    suppliers={suppliers}
                    departments={departments}
                    serverTime={serverTime}
                    sumInvoices={this.sumInvoices}
                    totalInvoicesValue={totalInvoicesValue}
                    totalItemsValue={totalItemsValue}
                    totalItemsCurrencyValue={totalItemsCurrencyValue}
                    confirmInvoiceGoods={this.confirmInvoiceGoods}
                    setTotalItemsValue={this.setTotalItemsValue}
                    setTotalItemsCurrencyValue={this.setTotalItemsCurrencyValue}
                    shouldDisplayInvoiceList={this.shouldDisplayInvoiceList()}
                    renderSupplierItemsModal={renderSupplierItemsModal}
                    onToggleSupplierItemModal={this.onToggleSupplierItemModal}
                    differentCurrency={supplierCurrency !== currency}
                    integrationType={integrationType}
                    budget={budget}
                    purchaseOrderErrors={errors}
                    taxEnabled={taxEnabled}
                    standAlone={standAlone}
                    dateFormat={dateFormat}
                    numberFormat={numberFormat}
                  />
                  <div className="form-section cell-12 with-tile">
                    {_.includes(purchaseOrder.permitted_actions, 'send_to_supplier') && (
                      <div className="window-footer modal-footer form-section-footer column cell-12">
                        <div className="items">
                          <label className="has-checkbox hide-on-complete">
                            <input
                              type="checkbox"
                              name="markAsSentToSupplier"
                              checked={markAsSentToSupplier}
                              onChange={this.onToggleChange}
                            />
                            <span className="checkbox input-l" />
                            <span className="link">
                              {I18n.t('purchase_orders.form.items.mark_po_sent_to_supplier')}
                            </span>
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="form-section with-sidebar">
                    <div className="form-section-wrapper form-sections sidebar">
                      <div className="form-section auto">
                        <div className="form-section-header not-desktop">
                          <span>{I18n.t('purchase_orders.form.documents')}</span>
                        </div>
                        <div className="form-section-content">
                          <Attachments
                            externalFilesLimitReached={purchaseOrder.external_files_limit_reached}
                            externalFilesLimitRemaining={purchaseOrder.external_files_limit_remaining}
                            externalFilesLimitSize={purchaseOrder.external_files_limit_size}
                            onSidebarToggle={this.onSidebarToggle}
                            attachments={attachments}
                            addActivity={this.addActivityToComponent}
                          />
                        </div>
                      </div>
                      <input
                        className="mobile-modal-open"
                        id="internal-comment-modal"
                        name="create-po-add"
                        type="radio"
                      />
                      <div className="mobile-modal">
                        <div className="mobile-modal-wrapper">
                          <div className="form-section fill mobile-window">
                            <div className="form-section-wrapper-header mobile-window-header mobile-modal-header">
                              <span>
                                <i className="icon-question_answer" />
                                {I18n.t('purchase_orders.form.internal_comments')}
                              </span>
                              <label className="mobile-close-modal mobile-show" htmlFor="sub-modal-close">
                                <i className="icon-close" />
                              </label>
                            </div>
                            <Activities
                              ref={(refActivities) => {
                                this.activities = refActivities;
                              }}
                              activities={activities}
                              editable={editable}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="window-footer modal-footer form-section-footer cell-12">
                    <div className="items">
                      {purchaseOrder.confirmable && [
                        <button
                          key={0}
                          onClick={() => this.openConfirmationModal()}
                          className="button inverse button-mute"
                          type="submit"
                        >
                          {I18n.t('purchase_orders.form.order_confirmation')}
                        </button>,
                        <ConfirmationModal
                          key={1}
                          isOpen={modals.isConfirmationOpen}
                          purchaseOrder={new PurchaseOrderModel(purchaseOrder)}
                          close={this.closeConfirmationModal}
                          confirm={this.confirm}
                        />,
                      ]}
                      {purchaseOrder.confirmation_state === 'confirmed' && [
                        <button
                          key={0}
                          onClick={() => this.openConfirmationModal()}
                          className="button inverse button-mute"
                          type="submit"
                        >
                          {I18n.t('purchase_orders.form.edit_order_confirmation')}
                        </button>,
                        <ConfirmationModal
                          key={1}
                          isOpen={modals.isConfirmationOpen}
                          purchaseOrder={new PurchaseOrderModel(purchaseOrder)}
                          close={this.closeConfirmationModal}
                          remove={this.disconfirmOrder}
                          confirm={data => this.confirm(data, 'update_confirmation_info')}
                        />,
                      ]}
                      {purchaseOrder.deletable && [
                        <button
                          key={0}
                          type="submit"
                          className="button inverse button-warning"
                          onClick={this.openDeleteModal}
                        >
                          <i className="icon-delete_forever mobile-inline-block" />
                          <span className="mobile-hide">{I18n.t('purchase_orders.form.delete')}</span>
                        </button>,
                        <DeleteModal
                          key={1}
                          isOpen={modals.isDeleteOpen}
                          close={this.closeDeleteModal}
                          delete={this.delete}
                        />,
                      ]}
                      {purchaseOrder.revisable && [
                        <button
                          key={0}
                          type="submit"
                          className="button inverse button-mute"
                          onClick={this.openReviseModal}
                        >
                          {I18n.t('purchase_orders.form.revise')}
                        </button>,
                        <ReviseModal
                          key={1}
                          isOpen={modals.isReviseOpen}
                          close={this.closeReviseModal}
                          revise={this.revise}
                        />,
                      ]}
                      {purchaseOrder.reopenable ? (
                        <>
                          <button
                            key={0}
                            type="submit"
                            className="button inverse button-mute"
                            onClick={this.openReopenModal}
                          >
                            {I18n.t('purchase_orders.form.reopen')}
                          </button>
                          <ReopenModal isOpen={modals.isReopenOpen} reopen={this.reopen} />
                        </>
                      ) : null}
                      <a className="button inverse button-mute" onClick={this.handlePreviewPdf}>
                        {I18n.t('purchase_orders.form.preview_po')}
                      </a>
                      {purchaseOrder.duplicable ? (
                        <form onSubmit={this.handleCopy}>
                          <input
                            type="submit"
                            className="button inverse button-mute"
                            value={I18n.t('purchase_orders.form.copy_po')}
                          />
                        </form>
                      ) : null}
                      {editable && this.renderSaveButton()}
                      {parentActiveChanged && permissions.parent_po_editable && (
                        <form onSubmit={this.handleSubmitParentActive}>
                          <input
                            key={0}
                            type="submit"
                            className="button inverse button-mute"
                            value={I18n.t('purchase_orders.form.receive_save')}
                            onClick={this.onAction.bind(this, 'save')}
                          />
                        </form>
                      )}
                      {_.includes(purchaseOrder.permitted_actions, 'send_to_approve') && (
                        <input
                          type="submit"
                          id="send-to-approve"
                          className="button button-primary"
                          value={I18n.t('purchase_orders.form.sent_to_approval')}
                          onClick={this.handleSendToApproval}
                        />
                      )}
                      {_.includes(purchaseOrder.permitted_actions, 'approve') && (
                        <input
                          type="submit"
                          className="button button-approve"
                          value={I18n.t('purchase_orders.form.approve')}
                          onClick={this.handleSubmitApproveState}
                        />
                      )}
                      {_.includes(purchaseOrder.permitted_actions, 'reject') && [
                        <input
                          key={0}
                          type="submit"
                          className="button button-reject"
                          value={I18n.t('purchase_orders.form.reject')}
                          onClick={this.openRejectModal}
                        />,
                        <RejectModal
                          ref={(rejectModal) => {
                            this.rejectModal = rejectModal;
                          }}
                          isOpen={modals.isRejectOpen}
                          submit={this.reject}
                          handleClose={this.rejectModalToggle}
                          errors={errors}
                          key={1}
                        />,
                      ]}
                      {_.includes(purchaseOrder.permitted_actions, 'send_to_supplier') && this.sendToSupplierButton()}
                      {(_.includes(purchaseOrder.permitted_actions, 'receive') || purchaseOrder.state === 'received')
                        && list == 'receive' && (
                          <React.Fragment>
                            {!(purchaseOrder.state == 'received' || purchaseOrder.state == 'completed') ? (
                              <button
                                key={0}
                                type="submit"
                                className="button inverse button-mute"
                                onClick={this.handleReceiveAllModal}
                              >
                                {I18n.t('purchase_orders.form.receive_all')}
                              </button>
                            ) : null}
                            <form onSubmit={this.handleSubmit}>
                              <input
                                key={0}
                                type="submit"
                                className="button button-primary"
                                value={I18n.t('purchase_orders.form.receive_save')}
                                onClick={this.onAction.bind(this, 'receive')}
                              />
                            </form>
                          </React.Fragment>
                      )}
                      {(_.includes(purchaseOrder.permitted_actions, 'invoice') || purchaseOrder.state === 'invoiced')
                        && list == 'invoice' && (
                          <form onSubmit={this.handleSubmit}>
                            <input
                              key={0}
                              type="submit"
                              className="button button-primary"
                              value={I18n.t('purchase_orders.form.invoice_save')}
                              onClick={this.onAction.bind(this, 'invoice')}
                            />
                          </form>
                      )}

                      {purchaseOrder.restorable && (
                        <form onSubmit={this.handleRestore}>
                          <input
                            key={0}
                            type="submit"
                            className="button inverse button-warning"
                            value={I18n.t('purchase_orders.form.restore')}
                          />
                        </form>
                      )}
                      <SupplierMessageModal
                        purchaseOrder={purchaseOrder}
                        isSentToSupplierOpen={modals.isSentToSupplierOpen}
                        isSupplierMessageOpen={modals.isSupplierMessageOpen}
                        handleClose={this.closeSupplierMessageModal}
                        sendToSupplier={this.sendToSupplier}
                        supplierMessage={supplierMessage}
                        poSetup={poSetup}
                        updateSupplierMessage={this.updateSupplierMessage}
                        updatePurchaseOrder={this.updatePurchaseOrder}
                        updateActivities={this.updateActivities}
                      />
                      <ReceiveAllModal
                        isReceiveAllModalOpen={modals.isReceiveAllModalOpen}
                        onChangeComment={this.onChangeReceiveAllComentBody}
                        commentBody={receiveAllCommentBody}
                        handleReceiveAllModal={this.handleReceiveAllModal}
                        confirmReceiveGoods={this.confirmReceiveGoods}
                        onReceiveAllFileChange={this.onReceiveAllFileChange}
                        receiveAllFile={receiveAllFile}
                        commentError={errors.comments}
                      />
                      {modals.isStrictBudgetOpen ? (
                        <Modal>
                          <StrictBudgetControlModal
                            isModalOpen={modals.isStrictBudgetOpen}
                            onToggleModal={this.onCloseStrictBudgetModal}
                            type="budget"
                          />
                        </Modal>
                      ) : null}
                      {modals.isStrictCategoryOpen ? (
                        <Modal>
                          <StrictBudgetControlModal
                            isModalOpen={modals.isStrictCategoryOpen}
                            onToggleModal={this.onCloseStrictCategoryModal}
                            type="category"
                          />
                        </Modal>
                      ) : null}
                      {modals.isParentErrorModalOpen ? (
                        <ParentErrorModal
                          message={errors.parent_active}
                          isOpen={modals.isParentErrorModalOpen}
                          close={this.closeParentActiveErrorModal}
                        />
                      ) : null}
                      {modals.isChildrenModalOpen ? (
                        <ChildrenModal isOpen={modals.isChildrenModalOpen} close={this.closeChildrenModal} />
                      ) : null}
                      {modals.isTypeChangeOpen ? (
                        <TypeChangeModal
                          isOpen={modals.isTypeChangeOpen}
                          close={this.closeTypeChangeModal}
                          closeAndChange={this.closeAndChange}
                        />
                      ) : null}
                      {modals.isFinancialApproverModalOpen ? (
                        <FinancialApproverModal
                          message={errors.financial_approver_id}
                          isOpen={modals.isFinancialApproverModalOpen}
                          close={this.closeFinancialApproverModal}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <input className="side-expand" id="side-form" name="aside" type="checkbox" />
          <aside className="side right fixed side-form width-4">
            {editable && (
              <PredefinedItems
                ref={(refPredefinedItems) => {
                  this.predefinedItemsRef = refPredefinedItems;
                }}
                onToggleSupplierItemModal={this.onToggleSupplierItemModal}
                items={predefinedItems}
                addItemsToPurchaseOrder={this.addItemsToPurchaseOrder}
                amountFormat={amountFormat}
              />
            )}
          </aside>
        </div>
      </div>
    );
  }
}

export default PurchaseOrder;
