import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';

class DateFilterInput extends React.Component {
  constructor(props) {
    super(props);

    const {
      dateFrom, dateTo, minDateFrom, minDateTo, label, dateFormat,
    } = this.props;
    const dateFromProps = (Array.isArray(dateFrom)) ? dateFrom[0] : dateFrom;
    const dateToProps = (Array.isArray(dateTo)) ? dateTo[0] : dateTo;
    const selected = dateFromProps || dateToProps;
    const filterLabel = label || I18n.t('purchase_orders.dates');
    this.state = {
      dateFrom: dateFromProps ? moment(dateFromProps, dateFormat) : null,
      dateTo: dateToProps ? moment(dateToProps, dateFormat) : null,
      minDateFrom: minDateFrom ? moment(minDateFrom) : null,
      minDateTo: minDateTo ? moment(minDateTo) : null,
      selected,
      filterLabel,
    };
  }

  onDateFromChange = (value) => {
    this.setState({ dateFrom: value, selected: true }, () => {
      this.resolveDateFilter();
    });
  }

  onDateToChange = (value) => {
    this.setState({ dateTo: value, selected: true }, () => {
      this.resolveDateFilter();
    });
  }

  resolveDateFilter = () => {
    const { props: { onDateFilter, dateFormat }, state: { dateTo, dateFrom } } = this;
    if (dateFrom && dateTo) {
      onDateFilter(
        {
          dateTo: moment(dateTo).format(dateFormat),
          dateFrom: moment(dateFrom).format(dateFormat),
        },
      );
    }
  }

  resetDates = () => {
    const { clearDate } = this.props;
    this.setState({
      dateFrom: null,
      dateTo: null,
      selected: false,
    });

    clearDate();
  }

  formatDate = (date, dateFormat) => (date ? date.format(dateFormat) : null)

  render() {
    const {
      dateFrom, dateTo, selected, minDateFrom, minDateTo, filterLabel,
    } = this.state;
    const { dateFormat } = this.props;

    return (
      <span className={`select ${selected ? 'selected' : ''} input-xs filter-select can-unselect has-datepicker react-datapicker picker-attached`}>
        <input autoComplete="off" type="checkbox" />
        <div className="select-content">
          <div className="label">{filterLabel}</div>
          <DatePicker
            placeholderText={I18n.t('purchase_orders.date_from')}
            selected={dateFrom}
            minDate={minDateFrom}
            maxDate={dateTo}
            onChange={this.onDateFromChange}
            value={this.formatDate(dateFrom, dateFormat)}
            name="dateFrom"
            dateFormat={dateFormat}
            autoComplete="off"
          />
          <DatePicker
            placeholderText={I18n.t('purchase_orders.date_to')}
            selected={dateTo}
            minDate={dateFrom || minDateTo}
            onChange={this.onDateToChange}
            value={this.formatDate(dateTo, dateFormat)}
            name="dateTo"
            dateFormat={dateFormat}
            autoComplete="off"
          />
          <div className="placeholder" data-placeholder={filterLabel} />
          <button className="button button-s date-clear" onClick={this.resetDates}>
            {`x ${I18n.t('purchase_orders.clear')}`}
          </button>
        </div>
      </span>
    );
  }
}

export default DateFilterInput;
