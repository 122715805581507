import uuid from 'uuid/v4';
import React from 'react';
import PropTypes from 'prop-types';
import PredefinedItem from './items/predefined_item';

class PredefinedItems extends React.Component {
  static propTypes = {
    addedItems: PropTypes.array,
    amountFormat: PropTypes.object,
  }

  constructor(props) {
    super(props);

    this.addToPurchaseOrder = this.addToPurchaseOrder.bind(this);
    this.assignItemAction = this.assignItemAction.bind(this);
    this.searchItems = this.searchItems.bind(this);
    this.setItems = this.setItems.bind(this);

    const { items } = props;
    this.state = {
      addedItems: [],
      items,
      primaryItems: items,
    };
  }

  setItems(items) {
    this.setState({ items, primaryItems: items });
  }

  searchItems(e) {
    const { state: { primaryItems }, props: { items } } = this;
    const text = e.target.value.toLowerCase();
    if (text.length < 2) {
      this.setState({ items: primaryItems });
    } else {
      const searchedItems = items.filter(element => element.name.toLowerCase().match(text));
      this.setState({ items: searchedItems });
    }
  }

  addToPurchaseOrder() {
    const { props: { addItemsToPurchaseOrder }, state: { addedItems } } = this;
    addItemsToPurchaseOrder(addedItems.map(element => ({
      sku: element.props.item.sku,
      unit: element.props.item.unit,
      name: element.props.item.name,
      price: element.props.item.price,
      quantity: element.props.item.quantity,
      category_id: element.props.item.category_id,
      e_type: 'item',
      uuid: uuid(),
    })));
    addedItems.map(item => item.clearCheckedStatus());
    this.setState({ addedItems: [] });
  }

  assignItemAction(itemComponent) {
    const { addedItems } = this.state;
    if (itemComponent.state.checked) {
      const newArray = addedItems.filter(element => element.props.index != itemComponent.props.index);
      this.setState({ addedItems: newArray });
    } else {
      this.setState({ addedItems: addedItems.concat([itemComponent]) });
    }
  }

  render() {
    const { items } = this.state;
    const { onToggleSupplierItemModal, amountFormat } = this.props;

    return (
      <div>
        <div className="side-header">
          <span>
            {I18n.t('purchase_orders.form.supplier_catalog')}
          </span>
          <span>
            <label className="modal-close" htmlFor="side-form">
              <i className="icon-close" />
            </label>
          </span>
        </div>
        <div className="side-content">
          <div className="search-list">
            <div className="control right-icon">
              <input name="search" type="text" placeholder="Type to search" onChange={this.searchItems} />
              <button className="button transparent icon-search" />
              <a
                className="hint display-block text-right color-navy"
                style={{ cursor: 'pointer' }}
                onClick={onToggleSupplierItemModal}
              >
                {I18n.t('purchase_orders.form.view_items_list')}
              </a>
            </div>
            <div className="control-list">
              { items.map((item, i) => (
                <PredefinedItem
                  item={item}
                  key={(`${item.id}`)}
                  index={i}
                  addItem={this.addItem}
                  removeItem={this.removeItem}
                  assignItemAction={this.assignItemAction}
                  amountFormat={amountFormat}
                />
              )) }
            </div>
          </div>
        </div>
        <div className="side-footer">
          <div className="button button-primary" onClick={this.addToPurchaseOrder}>{I18n.t('purchase_orders.form.add_selected')}</div>
        </div>
      </div>
    );
  }
}

export default PredefinedItems;
