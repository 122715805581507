import React from 'react';
import { NumericFormat } from 'react-number-format';
import Loader from '../loader';

function SupplierItemForm({
  onToggleSupplierItemsFormModal, groups, categories, isShowOnly, supplierItem, onSave,
  onGrossPriceOrDiscountChange, onSupplierChange, onChange, onSupplierSearchChange, selectSuppliers, isSaving, errors,
  numberFormat,
}) {
  const Header = () => {
    if (isShowOnly) return <h1>{`${I18n.t('supplier_items.partials.form.item')} ${supplierItem.number}`}</h1>;
    if (supplierItem.id) return <h1>{`${I18n.t('supplier_items.partials.form.edit_item')} ${supplierItem.number}`}</h1>;

    return <h1>{I18n.t('supplier_items.partials.form.new_item')}</h1>;
  };

  const { thousands_separator, decimal_mark, placeholder } = numberFormat;

  return (
    <div className="modal centered sub-modal modal-l active">
      <div className="modal-wrapper">
        <div className="modal-window window">
          <div className="window-header modal-header modal-header-auto">
            <Header />
          </div>
          <div className="window-content">
            <Loader />
            <div className="grid space-3 gutter-5 vertical-start">
              <div className="cell-8 grid space-2 t-cell-16">
                { isShowOnly ? (
                  <div className="row">
                    <div className="cell">
                      <div className="as-input">
                        <span className="label">{I18n.t('supplier_items.partials.form.supplier')}</span>
                        <span className="select react-only">
                          <div className="select-content">
                            <input
                              id={`supplier_${supplierItem.supplier.id}`}
                              name="supplierId"
                              type="radio"
                              value={supplierItem.supplier.id}
                              checked
                            />
                            <label htmlFor={`supplier_${supplierItem.supplier.id}`}>
                              <span>{supplierItem.supplier.name}</span>
                              <span>{`${supplierItem.supplier.street} ${supplierItem.supplier.zip_code} ${supplierItem.supplier.city?.length === 0 ? '' : `, ${supplierItem.supplier.city}`}`}</span>
                            </label>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className={`cell ${errors.supplier_id ? 'has-error' : ''}`}>
                      <div className="as-input required">
                        <span className="label">{I18n.t('supplier_items.partials.form.supplier')}</span>
                        <span className="select react-only">
                          <input type="checkbox" />
                          <div className="control icon-search">
                            <input
                              type="text"
                              name="supplierId"
                              onChange={onSupplierSearchChange}
                              placeholder={I18n.t('supplier_items.partials.form.select_supplier')}
                            />
                          </div>
                          <div className="select-content">
                            {selectSuppliers.map(supplier => (
                              <React.Fragment>
                                <input
                                  id={`supplier_${supplier.id}`}
                                  name="supplierId"
                                  type="radio"
                                  value={supplier.id}
                                  checked={supplier.id == supplierItem.supplierId}
                                  onChange={onSupplierChange}
                                  data-currency={supplier.currency}
                                />
                                <label htmlFor={`supplier_${supplier.id}`}>
                                  <span>{supplier.name}</span>
                                  <span>{`${supplier.street} ${supplier.zip_code} ${supplier.city?.length === 0 ? '' : `, ${supplier.city}`}`}</span>
                                </label>
                              </React.Fragment>
                            ))}
                            <div
                              className="placeholder"
                              data-placeholder={I18n.t('supplier_items.partials.form.select_supplier')}
                              data-selected={I18n.t('supplier_items.partials.form.selected')}
                            />
                          </div>
                        </span>
                        {errors.supplier_id ? (
                          <div className="hint">{errors.supplier_id.join(', ')}</div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                )
                }
                <div className="row">
                  <div className={`cell ${errors.number ? 'has-error' : ''}`}>
                    <div className="as-input required">
                      <span className="label">{I18n.t('supplier_items.partials.form.number')}</span>
                      <input
                        type="text"
                        name="number"
                        value={supplierItem.number}
                        onChange={onChange}
                        placeholder={I18n.t('supplier_items.partials.form.enter_number')}
                        disabled={isShowOnly}
                      />
                      {errors.number ? (
                        <div className="hint">{errors.number.join(', ')}</div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={`cell ${errors.product_number ? 'has-error' : ''}`}>
                    <div className="as-input">
                      <span className="label">{I18n.t('supplier_items.partials.form.product_number')}</span>
                      <input
                        type="text"
                        name="productNumber"
                        value={supplierItem.productNumber}
                        onChange={onChange}
                        placeholder={I18n.t('supplier_items.partials.form.enter_product_number')}
                        disabled={isShowOnly}
                      />
                      {errors.product_number ? (
                        <div className="hint">{errors.product_number.join(', ')}</div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={`cell ${errors.global_number ? 'has-error' : ''}`}>
                    <div className="as-input">
                      <span className="label">{I18n.t('supplier_items.partials.form.global_id')}</span>
                      <input
                        type="text"
                        name="globalNumber"
                        value={supplierItem.globalNumber}
                        onChange={onChange}
                        placeholder={I18n.t('supplier_items.partials.form.enter_global_id')}
                        disabled={isShowOnly}
                      />
                      {errors.global_number ? (
                        <div className="hint">{errors.global_number.join(', ')}</div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={`cell ${errors.supplier_item_group_id ? 'has-error' : ''}`}>
                    <div className="as-input">
                      <span className="label">{I18n.t('supplier_items.partials.form.item_group')}</span>
                      <input
                        type="text"
                        name="group"
                        value={supplierItem.group}
                        onChange={onChange}
                        list="groups"
                        placeholder={I18n.t('supplier_items.partials.form.select_or_enter_item_group')}
                        disabled={isShowOnly}
                      />
                      <datalist id="groups">
                        {groups.map(group => (
                          <option>{group.name}</option>
                        ))}
                      </datalist>
                      {errors.supplier_item_group_id ? (
                        <div className="hint">{errors.supplier_item_group_id.join(', ')}</div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={`cell ${errors.supplier_item_category_id ? 'has-error' : ''}`}>
                    <div className="as-input">
                      <span className="label">{I18n.t('supplier_items.partials.form.item_category')}</span>
                      <input
                        type="text"
                        name="category"
                        value={supplierItem.category}
                        onChange={onChange}
                        list="categories"
                        placeholder={I18n.t('supplier_items.partials.form.select_or_enter_item_category')}
                        disabled={isShowOnly}
                      />
                      <datalist id="categories">
                        {categories.map(category => (
                          <option>{category.name}</option>
                        ))}
                      </datalist>
                      {errors.supplier_item_category_id ? (
                        <div className="hint">{errors.supplier_item_category_id.join(', ')}</div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={`cell ${errors.description ? 'has-error' : ''}`}>
                    <div className="as-input required">
                      <span className="label">{I18n.t('supplier_items.partials.form.description')}</span>
                      <textarea
                        name="description"
                        value={supplierItem.description}
                        onChange={onChange}
                        placeholder={I18n.t('supplier_items.partials.form.enter_description')}
                        disabled={isShowOnly}
                      />
                      {errors.description ? (
                        <div className="hint">{errors.description.join(', ')}</div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="cell-8 grid space-2 t-cell-16">
                <div className="row">
                  <div className={`cell ${errors.unit_measure ? 'has-error' : ''}`}>
                    <div className="as-input required">
                      <span className="label">{I18n.t('supplier_items.partials.form.unit_measure')}</span>
                      <input
                        type="text"
                        name="unitMeasure"
                        value={supplierItem.unitMeasure}
                        onChange={onChange}
                        placeholder={I18n.t('supplier_items.partials.form.enter_unit_measure')}
                        disabled={isShowOnly}
                      />
                      {errors.unit_measure ? (
                        <div className="hint">{errors.unit_measure.join(', ')}</div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                { isShowOnly ? (
                  <div className="row">
                    <div className="cell">
                      <div className="as-input">
                        <span className="label">{I18n.t('supplier_items.partials.form.currency')}</span>
                        <span className="select react-only">
                          <div className="select-content">
                            <input
                              id={`supplier_${supplierItem.currency}`}
                              name="currency"
                              type="radio"
                              value={supplierItem.currency}
                              checked
                            />
                            <label htmlFor={`supplier_${supplierItem.currency}`}>
                              <span>{supplierItem.currency}</span>
                            </label>
                          </div>
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className={`cell ${errors.currency ? 'has-error' : ''}`}>
                      <div className="as-input required">
                        <span className="label">{I18n.t('supplier_items.partials.form.currency')}</span>
                        <input
                          id={`currency_${supplierItem.currency}`}
                          name="currency"
                          type="text"
                          value={supplierItem.currency}
                          onChange={onChange}
                          disabled
                        />
                        {errors.currency ? (
                          <div className="hint">{errors.currency.join(', ')}</div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className={`cell ${errors.gross_price ? 'has-error' : ''}`}>
                    <div className="as-input required">
                      <span className="label">{I18n.t('supplier_items.partials.form.gross_price')}</span>
                      <NumericFormat
                        name="grossPrice"
                        onClick={e => e.target.select()}
                        allowedDecimalSeparators={[',', '.']}
                        fixedDecimalScale
                        decimalScale={2}
                        value={supplierItem.grossPrice || ''}
                        onValueChange={({ value: v }) => onGrossPriceOrDiscountChange(v, 'grossPrice')}
                        thousandSeparator={thousands_separator}
                        allowNegative={false}
                        disabled={isShowOnly}
                        placeholder={placeholder}
                        as="input"
                        decimalSeparator={decimal_mark}
                      />
                      {errors.gross_price ? (
                        <div className="hint">{errors.gross_price.join(', ')}</div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={`cell ${errors.discount ? 'has-error' : ''}`}>
                    <div className="as-input">
                      <span className="label">{I18n.t('supplier_items.partials.form.discount')}</span>
                      <NumericFormat
                        name="discount"
                        onClick={e => e.target.select()}
                        allowedDecimalSeparators={[',', '.']}
                        fixedDecimalScale
                        decimalScale={2}
                        value={supplierItem.discount || ''}
                        onValueChange={({ value: v }) => onGrossPriceOrDiscountChange(v, 'discount')}
                        thousandSeparator={thousands_separator}
                        allowNegative={false}
                        disabled={isShowOnly}
                        placeholder={placeholder}
                        as="input"
                        decimalSeparator={decimal_mark}
                      />
                      {errors.discount ? (
                        <div className="hint">{errors.discount.join(', ')}</div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={`cell ${errors.net_price ? 'has-error' : ''}`}>
                    <div className="as-input">
                      <span className="label">{I18n.t('supplier_items.partials.form.net_price')}</span>
                      <NumericFormat
                        name="netPrice"
                        onClick={e => e.target.select()}
                        allowedDecimalSeparators={[',', '.']}
                        fixedDecimalScale
                        decimalScale={2}
                        value={supplierItem.netPrice || ''}
                        thousandSeparator={thousands_separator}
                        allowNegative={false}
                        disabled
                        placeholder={placeholder}
                        as="input"
                        decimalSeparator={decimal_mark}
                      />
                      {errors.net_price ? (
                        <div className="hint">{errors.net_price.join(', ')}</div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className={`cell ${errors.minimum_order_quantity ? 'has-error' : ''}`}>
                    <div className="as-input">
                      <span className="label">{I18n.t('supplier_items.partials.form.minimum_order_quantity')}</span>
                      <input
                        type="number"
                        name="minimumOrderQuantity"
                        value={supplierItem.minimumOrderQuantity}
                        onChange={onChange}
                        placeholder="1"
                        disabled={isShowOnly}
                      />
                      {errors.minimum_order_quantity ? (
                        <div className="hint">{errors.minimum_order_quantity.join(', ')}</div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="window-footer modal-footer modal-footer-space">
            { isShowOnly ? (
              <div className="items">
                <label
                  className="button button-transparent button-mute inverse link"
                  onClick={onToggleSupplierItemsFormModal}
                >
                  {I18n.t('commons.actions.back')}
                </label>
              </div>
            ) : (
              <div className="items">
                <label
                  className="button button-transparent button-mute inverse link"
                  onClick={onToggleSupplierItemsFormModal}
                >
                  {I18n.t('commons.actions.cancel')}
                </label>
                <button
                  type="button"
                  className="button button-primary"
                  onClick={onSave}
                  disabled={isSaving}
                >
                  {I18n.t('commons.actions.save')}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <label className="modal-backdrop" onClick={onToggleSupplierItemsFormModal} />
    </div>
  );
}

export default SupplierItemForm;
