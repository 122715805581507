import React, { useState } from 'react';
import Warning from '@/icons/Warning';
import Tooltip from '@/components/uiElements/Tooltip';
import useTooltip from '@/hooks/useTooltip';
import { humanizedPrice } from '../../shared/number_converter';

function InvoiceRow({
  supplierInvoice, onBulkSelect, showTransactions, standAlone, xero, amountFormat,
}) {
  const [isTooltipOpen, tooltipTriggerRef] = useTooltip();
  const [expandedClass, setExpandedClass] = useState('');
  const onExpand = () => {
    if (expandedClass === '') {
      setExpandedClass('expanded');
    } else {
      setExpandedClass('');
    }
  };

  const onSelect = () => {
    onBulkSelect(supplierInvoice);
  };

  const matched = () => {
    const { transactions } = supplierInvoice;

    const transactionAssignes = transactions
      .map(transaction => transaction.isAssigned())
      .map(num => Boolean(num));
    return transactionAssignes.some(num => num == true);
  };

  const selected = supplierInvoice.selected ? 'checked' : '';

  const statusClass = () => {
    const { state, deletedAt } = supplierInvoice;
    if (deletedAt) return 'deleted';
    if (state === 'complete') return 'valid';
    if (state === 'transferred') return 'transfer';
    return state;
  };

  const redirectToSupplierInvoice = () => {
    window.location.href = `/supplier_invoices/${supplierInvoice.id}/edit`;
  };

  return (
    <thead className={`expandable with-table ${expandedClass}`}>
      <tr>
        <td className="has-child">
          <label className="has-checkbox as-td">
            <span onClick={onSelect} className={`checkbox input-s ${selected}`} />
          </label>
          <div className="as-td has-ellipsis" data-ellipsis={supplierInvoice.type.dispValues}>
            <div className="text-ellipsis w-7">
              <span>
                {supplierInvoice.type.dispValues}
              </span>
            </div>
          </div>
        </td>
        <td onClick={redirectToSupplierInvoice}>{supplierInvoice.number}</td>
        <td className="has-ellipsis" onClick={redirectToSupplierInvoice} data-ellipsis={supplierInvoice.supplier.name}>
          <div className="text-ellipsis w-7">{supplierInvoice.supplier.name}</div>
        </td>
        <td onClick={redirectToSupplierInvoice}>{supplierInvoice.invoiceNumber}</td>
        <td className="has-ellipsis" onClick={redirectToSupplierInvoice} data-ellipsis={supplierInvoice.formattedDocDate()}>
          <div className="text-ellipsis w-7">{supplierInvoice.formattedDocDate()}</div>
        </td>
        <td className="has-ellipsis" onClick={redirectToSupplierInvoice} data-ellipsis={supplierInvoice.formattedDueDate()}>
          <div className="text-ellipsis w-7">{supplierInvoice.formattedDueDate()}</div>
        </td>
        <td onClick={redirectToSupplierInvoice}>{supplierInvoice.reference}</td>
        <td className="number" onClick={redirectToSupplierInvoice}>
          {humanizedPrice(supplierInvoice.currencyAmount, amountFormat)}
        </td>
        <td onClick={redirectToSupplierInvoice}>{supplierInvoice.currency.id}</td>
        <td className="number price bold" onClick={redirectToSupplierInvoice}>
          {humanizedPrice(supplierInvoice.amount, amountFormat)}
        </td>
        <td onClick={redirectToSupplierInvoice}>{supplierInvoice.createdAt}</td>
        <td onClick={redirectToSupplierInvoice}>{supplierInvoice.transferredAt}</td>
        {(standAlone || xero) && <td onClick={redirectToSupplierInvoice}>{I18n.t(`purchase_orders.partials.invoices_body.paid_status.${supplierInvoice.paid}`)}</td>}
        <td className="has-status" onClick={redirectToSupplierInvoice}>
          <div className={`status capitalize ${statusClass()}`}>
            {supplierInvoice.deletedAt ? 'deleted' : supplierInvoice.humanizedState()
            }
          </div>
        </td>
        <td onClick={redirectToSupplierInvoice}>
          {
            matched() ? 'Yes' : 'No'
          }
        </td>
        <td className="actions ct-relative">

          { supplierInvoice.outdated && (
            <a href={`/supplier_invoices/${supplierInvoice.id}/edit`} ref={tooltipTriggerRef}>
              <Warning className="ct-h-5.5 ct-w-5.5 ct-text-orange" />
            </a>
          )}

          { isTooltipOpen && (
            <div className="ct-absolute ct-top-1 1300:ct-right-[calc(1.7rem+3px)] ct-right-[calc(1rem+3px)] ct-translate-y-[-100%] ct-z-20">
              <Tooltip isOpen={isTooltipOpen} direction="BOTTOM_RIGHT" text={I18n.t('errors.supplierInvoice.outdated_title')} className="ct-w-fit" />
            </div>
          )}

          { showTransactions && <a className="arrow" onClick={onExpand} /> }

        </td>
      </tr>
    </thead>
  );
}

export default InvoiceRow;
