import React from 'react';
import * as numberConverter from '../../../shared/number_converter';

export default function Info({ invoicesNumber, invoicesValue, amountFormat }) {
  return (
    <div className="window-top">
      <span>
        <h1>
          {`${I18n.t('purchase_orders.invoices.attach_transactions_to', { number: invoicesNumber })}:`}
        </h1>
      </span>
      <span>
        <div className="table total">
          <div className="tr">
            <div className="td label">
              {`${I18n.t('purchase_orders.invoices.selected_transactions_value')}:`}
            </div>
            <div className="td value">{numberConverter.humanizedPrice(invoicesValue, amountFormat)}</div>
          </div>
        </div>
      </span>
    </div>
  );
}
