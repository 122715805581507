import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';
import InputBase from '..';

function NumberField(
  {
    name,
    label,
    error,
    disabled,
    required,
    decimal,
    currency,
    percent,
    table,
    value,
    onChange,
    button,
    warning,
    allowNegative,
    allowBetterPrecision,
    decimalSeparator,
    thousandSeparator,
  },
  ref,
) {
  const setSuffix = () => {
    if (percent) return ' %';
    if (currency) return `  ${currency}`;
    return undefined;
  };

  const setPlaceholder = () => {
    let placeholder = decimal ? `0${decimalSeparator || ','}00` : '0';
    if (percent) placeholder += ' %';
    if (currency) placeholder += `  ${currency}`;
    return placeholder;
  };

  const setDecimalScale = () => {
    if (!allowBetterPrecision) return decimal ? 2 : 0;
    const decimalIndex = value.toString().indexOf('.');
    const decimalPlaces = decimalIndex >= 0 ? value.toString().length - decimalIndex - 1 : 2;
    if (decimalPlaces > 4) return 4;
    if (decimalPlaces == 1) return 2;
    return decimalPlaces;
  };

  return (
    <InputBase
      ref={ref}
      name={name}
      label={label}
      error={error}
      disabled={disabled}
      required={required}
      table={table}
      button={button}
      warning={warning}
    >
      <NumericFormat
        name={name}
        onClick={e => e.target.select()}
        decimalScale={setDecimalScale()}
        allowedDecimalSeparators={[',', '.']}
        suffix={setSuffix()}
        fixedDecimalScale
        value={value || ''}
        onValueChange={({ value: v }) => onChange && onChange(v)}
        thousandSeparator={!percent ? (thousandSeparator || ' ') : undefined}
        allowNegative={allowNegative}
        disabled={disabled}
        placeholder={setPlaceholder()}
        as="input"
        className="custom ct-text-right"
        decimalSeparator={decimalSeparator || '.'}
      />
    </InputBase>
  );
}

export default forwardRef(NumberField);

NumberField.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  decimal: PropTypes.bool,
  currency: PropTypes.string,
  percent: PropTypes.bool,
  table: PropTypes.bool,
  button: PropTypes.object,
  warning: PropTypes.string,
};
