import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import _ from 'lodash';
import { withLimit, withMoreLimit } from '../shared/utils';
import SupplierModal from './supplier_info/supplier_modal';
import SupplierContactModal from './supplier_info/supplier_contact_modal';
import * as api from '../shared/api';
import { INTEGRATION_TYPES } from '../../config/constants';

class SupplierInfo extends React.Component {
  static propTypes = {
    suppliers: PropTypes.array,
    supplierContacts: PropTypes.array,
    paymentTerms: PropTypes.array,
    purchaseOrder: PropTypes.object,
    currencies: PropTypes.array,
    updateCurrency: PropTypes.func,
    dateFormat: PropTypes.string,
  }

  constructor(props) {
    super(props);

    this.onChangeSupplier = this.onChangeSupplier.bind(this);
    this.onChangeSupplierContact = this.onChangeSupplierContact.bind(this);
    this.onChangeSearchSupplier = this.onChangeSearchSupplier.bind(this);
    this.onChangeSearchSupplierContact = this.onChangeSearchSupplierContact.bind(this);
    this.openSupplierModal = this.openSupplierModal.bind(this);
    this.openSupplierContactModal = this.openSupplierContactModal.bind(this);
    this.closeSupplierModal = this.closeSupplierModal.bind(this);
    this.closeSupplierContactModal = this.closeSupplierContactModal.bind(this);
    this.addSupplier = this.addSupplier.bind(this);
    this.addSupplierContact = this.addSupplierContact.bind(this);
    this.changeSupplier = this.changeSupplier.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onExpectedDeliveryDateChange = this.onExpectedDeliveryDateChange.bind(this);
    this.saveDraft = this.saveDraft.bind(this);

    const {
      purchaseOrder: {
        supplier_id, supplier_contact_id, payment_terms, delivery_terms,
        comments_for_supplier, delivery_date, expected_delivery_date,
      },
      suppliers, supplierContacts, paymentTerms,
    } = props;

    this.mainSuppliers = suppliers;
    this.mainSupplierContacts = supplierContacts;
    this.mainPaymentTerms = paymentTerms;

    this.state = {
      supplier_id: parseInt(supplier_id),
      supplier_contact_id,
      supplierCurrency: '',
      suppliers: withMoreLimit(suppliers),
      supplierContacts: withLimit(supplierContacts),
      paymentTerms,
      supplierModalOpen: false,
      supplierContactModalOpen: false,
      payment_terms: payment_terms || '',
      delivery_terms: delivery_terms || '',
      comments_for_supplier: comments_for_supplier || '',
      searchValue: '',
      typing: false,
      typingTimeout: 0,
      delivery_date: (delivery_date) ? moment(delivery_date) : null,
      expected_delivery_date: (expected_delivery_date) ? moment(expected_delivery_date) : null,
      paymentTermId: props.purchaseOrder.payment_term_id,
      paymentTermName: props.purchaseOrder.payment_term_name,
      paymentTermValue: props.purchaseOrder.payment_term_value,
      paymentTermStartAtTheNextMonthBeginning: props.purchaseOrder.payment_term_start_at_the_next_month_beginning,
    };
  }

  onDateChange(date) {
    const { saveDraft } = this.props;
    this.setState({ delivery_date: date }, () => { saveDraft(); });
  }

  onExpectedDeliveryDateChange(date) {
    const { saveDraft } = this.props;
    this.setState({ expected_delivery_date: date }, () => { saveDraft(); });
  }

  onChangeSupplier(e) {
    this.changeSupplier(e.target.value);
  }

  onPaymentTermChange = ({ target: { value } }) => {
    const { saveDraft } = this.props;
    this.setState({ paymentTermId: value }, () => { saveDraft(); });
  }

  onPaymentTermDetach = () => {
    const { saveDraft } = this.props;
    this.setState({ paymentTermId: null }, () => { saveDraft(); });
  }

  onChangeSupplierContact(e) {
    const { state: { supplier_contact_id }, props: { saveDraft } } = this;
    if (supplier_contact_id == e.target.value) {
      this.setState({ supplier_contact_id: undefined, supplierContacts: [] });
    } else {
      this.setState({ supplier_contact_id: e.target.value }, () => { saveDraft(); });
    }
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onChangeSearchSupplier(e) {
    const { state: { typingTimeout } } = this;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      searchValue: e.target.value,
      typing: false,
      typingTimeout: setTimeout(() => {
        const { state: { searchValue } } = this;
        this.setState({
          suppliers: withMoreLimit(this.mainSuppliers.filter(
            (supplier) => {
              let supplier_number;
              if (supplier.supplier_number == null) {
                supplier_number = '';
              } else {
                ({ supplier_number } = supplier);
              }
              return supplier.name.toLowerCase().includes(searchValue.toLowerCase()) || supplier_number.includes(searchValue);
            },
          )),
        });
      }, 350),
    });
  }

  onChangeSearchSupplierContact(e) {
    const { typingTimeout } = this.state;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      searchValue: e.target.value,
      typing: false,
      typingTimeout: setTimeout(() => {
        const { searchValue } = this.state;
        this.setState({
          supplierContacts: withLimit(this.mainSupplierContacts.filter(
            supplierContact => supplierContact.name.toLowerCase().includes(searchValue.toLowerCase()),
          )),
        });
      }, 350),
    });
  }

  stateAfterSentToSupplier = () => {
    const { purchaseOrder } = this.props;
    return ['sent_to_supplier', 'received', 'invoiced', 'completed'].includes(purchaseOrder.state);
  }

  onChangeSearchPaymentTerm = (e) => {
    const { typingTimeout } = this.state;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      searchValue: e.target.value,
      typing: false,
      typingTimeout: setTimeout(() => {
        const { searchValue } = this.state;
        this.setState({
          paymentTerms: withLimit(this.mainPaymentTerms.filter(
            paymentTerm => paymentTerm.name.toLowerCase().includes(searchValue.toLowerCase()),
          )),
        });
      }, 350),
    });
  }

  supplierLine = (supplier) => {
    const {
      street, zip_code, city, country, address_line2, address_line3,
    } = supplier;

    const zipAndCity = [zip_code, city].filter(Boolean).join(' ');
    const lines = [street, address_line2, address_line3].filter(Boolean).join(', ');
    const secondLineAddress = [lines, zipAndCity, country];

    return secondLineAddress.filter(Boolean).join(', ');
  }

  poSupplierLine = (purchaseOrder) => {
    const {
      supplier_street, supplier_zip_code, supplier_city, supplier_country,
      supplier_address_line2, supplier_address_line3,
    } = purchaseOrder;

    const zipAndCity = [supplier_zip_code, supplier_city].filter(Boolean).join(' ');
    const lines = [supplier_street, supplier_address_line2, supplier_address_line3].filter(Boolean).join(', ');
    const secondLineAddress = [lines, zipAndCity, supplier_country];

    return secondLineAddress.filter(Boolean).join(', ');
  }

  showSupplierNumber = (ent) => {
    const { integrationType } = this.props;

    if (ent.supplier_number && integrationType !== INTEGRATION_TYPES.XERO) return true;
    return false;
  }

  addSupplier(supplier) {
    const { suppliers } = this.state;
    this.setState({
      suppliers: suppliers.concat([
        supplier,
      ]),
    }, () => {
      this.changeSupplier(supplier.id);
    });
  }

  addSupplierContact(supplierContact) {
    const { saveDraft } = this.props;
    const { supplierContacts } = this.state;
    this.mainSupplierContacts = this.mainSupplierContacts.concat([
      supplierContact,
    ]);
    this.setState({
      supplierContacts: supplierContacts.concat([
        supplierContact,
      ]),
      supplier_contact_id: supplierContact.id,
    }, () => saveDraft());
  }

  openSupplierModal() {
    this.setState({ supplierModalOpen: true });
  }

  closeSupplierModal() {
    this.setState({ supplierModalOpen: false });
  }

  openSupplierContactModal() {
    this.setState({ supplierContactModalOpen: true });
  }

  closeSupplierContactModal() {
    this.setState({ supplierContactModalOpen: false });
  }

  changeSupplier(supplierId) {
    const {
      state: {
        supplier_id, suppliers, payment_terms, delivery_terms, paymentTermId,
      },
      props: {
        saveDraft, updateCurrency, updateSupplierCurrency,
      },
    } = this;
    if (parseInt(supplier_id) == parseInt(supplierId)) {
      this.setState({ supplier_id: undefined });
    } else {
      const supplier = _.find(suppliers, s => s.id == parseInt(supplierId));

      this.setState({
        supplier_id: parseInt(supplierId),
        payment_terms: supplier.payment_terms || payment_terms,
        paymentTermId: supplier.payment_term_id || paymentTermId,
        delivery_terms: supplier.delivery_terms || delivery_terms,
        supplierCurrency: supplier.currency,
      });

      updateSupplierCurrency(supplier.currency);
      updateCurrency(supplier.currency, false);

      api.makeGetRequest(`/suppliers/${supplierId}/contacts`)
        .then((response) => {
          const mainSupplierContacts = response.data.supplier_contacts;
          this.setState({ supplierContacts: mainSupplierContacts }, () => {
            const { supplierContacts } = this.state;
            const defaultSupplierContact = supplierContacts.find(supplierContact => supplierContact.default === true);
            if (defaultSupplierContact) {
              this.setState({ supplier_contact_id: parseInt(defaultSupplierContact.id) }, () => { saveDraft(); });
            } else {
              this.setState({ supplier_contact_id: '' }, () => { saveDraft(); });
            }
          });
        });
    }
  }

  saveDraft() {
    const { saveDraft } = this.props;
    saveDraft();
  }

  render() {
    const {
      props: {
        purchaseOrder, supplierContactError, supplierError, currencies, companyCurrency,
        paymentTermsError, deliveryTermsError, deliveryDateError, isTutorialPending, expectedDeliveryDateError,
        purchaseOrder: { editable }, permissions: { supplier_editable }, paymentTermError, dateFormat,
      },
      state: {
        supplier_id, suppliers, supplierModalOpen, supplierContacts, supplier_contact_id,
        supplierContactModalOpen, delivery_date, expected_delivery_date, payment_terms, delivery_terms, comments_for_supplier,
        paymentTermId, paymentTerms,
      },
    } = this;

    return (
      <div className="grid space-2">
        <div key={0} className="row">
          <div className={`cell-16 ${supplierError ? 'has-error' : ''}`}>
            <div className="as-input reduce required">
              {(!isTutorialPending && editable && supplier_editable)
                ? (
                  <label tabIndex="-1" className="add-edit-trigger"><i className="icon-add" onClick={this.openSupplierModal} /></label>
                ) : ''
              }
              <span className="label">{I18n.t('purchase_orders.form.supplier')}</span>
              <span className={`select input-r add-edit${editable ? '' : ' disabled'}${supplier_id ? ' selected' : ''}`} name="supplier">
                <input type="checkbox" />
                <div className="control">
                  <input type="text" tabIndex="-1" onChange={this.onChangeSearchSupplier} placeholder={I18n.t('purchase_orders.form.type_to_search')} />
                </div>
                <div className="select-content">
                  {!_.isEmpty(purchaseOrder.supplier_name)
                    && [
                      <input key="input-copied" id="supplier_copied" name="supplier" type="radio" value={purchaseOrder.supplier_id} checked={parseInt(purchaseOrder.supplier_id) == parseInt(supplier_id)} onChange={this.onChangeSupplier} />,
                      <label className={`${parseInt(purchaseOrder.supplier_id) == parseInt(supplier_id) ? 'checked' : ''}`} key="label-copied" htmlFor="supplier_copied">
                        <span>{purchaseOrder.supplier_name}</span>
                        {this.showSupplierNumber(purchaseOrder)
                          ? (
                            <span>{`ID: ${purchaseOrder.supplier_number}`}</span>
                          ) : ''
                        }
                        <span>{this.poSupplierLine(purchaseOrder)}</span>
                      </label>,
                    ]
                  }
                  {suppliers.filter(supplier => (purchaseOrder.supplier_id != supplier.id)).map(supplier => (
                    [
                      <input key={`input-${supplier.id}`} id={`supplier_${supplier.id}`} name="supplier" type="radio" value={supplier.id} checked={supplier.id == supplier_id} onChange={this.onChangeSupplier} />,
                      <label className={`${supplier.id == supplier_id ? 'checked' : ''}`} key={`label-${supplier.id}`} htmlFor={`supplier_${supplier.id}`}>
                        <span>{supplier.name}</span>
                        {this.showSupplierNumber(supplier)
                          ? (
                            <span>
                              {`ID: ${supplier.supplier_number}`}
                            </span>
                          ) : ''
                        }
                        <span>{this.supplierLine(supplier)}</span>
                      </label>,
                    ]
                  ))}
                  <div className="placeholder" data-placeholder={I18n.t('purchase_orders.form.select_supplier')} data-selected={I18n.t('purchase_orders.form.selected')} />
                </div>
                {supplierError ? <div className="hint error">{supplierError}</div> : ''}
              </span>
            </div>
            <SupplierModal
              isOpen={supplierModalOpen}
              handleClose={this.closeSupplierModal}
              handleAdd={this.addSupplier}
              currencies={currencies}
              companyCurrency={companyCurrency}
              paymentTerms={this.mainPaymentTerms}
            />
          </div>
        </div>
        <div key={1} className="row">
          <div className={`cell-16 ${supplierContactError ? 'has-error' : ''}`}>
            <div className="as-input reduce">
              {!isTutorialPending && editable && supplier_id
                ? (
                  <label tabIndex="-1" className="add-edit-trigger"><i className="icon-add" onClick={this.openSupplierContactModal} /></label>
                ) : false
              }
              <span className="label">{I18n.t('purchase_orders.form.supplier_contact')}</span>
              <span className={`select input-r add-edit${editable && supplier_id ? '' : ' disabled'} ${supplier_contact_id ? ' selected' : ''}`} name="supplier_contact">
                <input type="checkbox" />
                <div className="control icon-search">
                  <input type="text" tabIndex="-1" onChange={this.onChangeSearchSupplierContact} placeholder={I18n.t('purchase_orders.form.type_to_search')} />
                </div>
                <div className="select-content">
                  {!_.isEmpty(purchaseOrder.supplier_contact_name) && _.find(supplierContacts, supplierContact => (purchaseOrder.supplier_contact_id == supplierContact.id))
                    && [
                      <input key="input-copied" id="supplier_contact_copied" name="supplier_contact" type="radio" value={purchaseOrder.supplier_contact_id} checked={purchaseOrder.supplier_contact_id == supplier_contact_id} onChange={this.onChangeSupplierContact} />,
                      <label className={`${purchaseOrder.supplier_contact_id == supplier_contact_id ? 'checked' : ''}`} key="label-copied" htmlFor="supplier_contact_copied">
                        <span>{purchaseOrder.supplier_contact_name}</span>
                        <span>{purchaseOrder.supplier_contact_phone}</span>
                      </label>,
                    ]
                  }
                  {supplierContacts.filter(supplier_contact => (purchaseOrder.supplier_contact_id != supplier_contact.id)).map(supplier_contact => (
                    [
                      <input key={`input-${supplier_contact.id}`} id={`supplier_contact_${supplier_contact.id}`} name="supplier_contact" type="radio" checked={supplier_contact.id == supplier_contact_id} value={supplier_contact.id} onChange={this.onChangeSupplierContact} />,
                      <label className={`${supplier_contact.id == supplier_contact_id ? 'checked' : ''}`} key={`label-${supplier_contact.id}`} htmlFor={`supplier_contact_${supplier_contact.id}`}>
                        <span>
                          {supplier_contact.name}
                        </span>
                        <span>{supplier_contact.phone}</span>
                      </label>,
                    ]
                  ))}
                  <div className="placeholder" data-placeholder={I18n.t('purchase_orders.form.select_supplier_contact')} data-selected={I18n.t('purchase_orders.form.selected')} />
                </div>
                {supplierContactError ? <div className="hint error">{supplierContactError}</div> : ''}
              </span>
            </div>
            {editable && supplier_id
              ? <SupplierContactModal supplierId={parseInt(supplier_id)} isOpen={supplierContactModalOpen} handleClose={this.closeSupplierContactModal} handleAdd={this.addSupplierContact} /> : false
            }
          </div>
        </div>

        <div className="row">
          <div className={`cell-7 ${deliveryDateError ? 'has-error' : ''}`}>
            <div className="as-input reduce">
              <span className="label">{I18n.t('purchase_orders.form.delivery_date')}</span>
              <DatePicker
                selected={delivery_date}
                onChange={this.onDateChange}
                readOnly={!editable}
                dateFormat={dateFormat}
                locale={I18n.locale}
                className="output input-r"
                placeholderText={I18n.t('purchase_orders.form.select_delivery_date')}
              />
            </div>
            {deliveryDateError ? <div className="hint error">{deliveryDateError}</div> : ''}
          </div>
        </div>
        {this.stateAfterSentToSupplier() && (
        <div className="row">
          <div className={`cell-7 ${expectedDeliveryDateError ? 'has-error' : ''}`}>
            <div className="as-input reduce">
              <span className="label">{I18n.t('purchase_orders.form.expected_delivery_date')}</span>
              <DatePicker
                selected={expected_delivery_date}
                onChange={this.onExpectedDeliveryDateChange}
                readOnly={!editable}
                dateFormat={dateFormat}
                locale={I18n.locale}
                className="output input-r"
                placeholderText={I18n.t('purchase_orders.form.select_expected_delivery_date')}
              />
            </div>
            {expectedDeliveryDateError ? <div className="hint error">{expectedDeliveryDateError}</div> : ''}
          </div>
        </div>
        )}
        {purchaseOrder.payment_terms ? (
          <div className="row">
            <div className={`cell-16 ${paymentTermsError ? 'has-error' : ''}`}>
              <div className="as-input reduce">
                <span className="label">{I18n.t('purchase_orders.form.payment_terms')}</span>
                <input
                  className="output input-r"
                  placeholder={I18n.t('purchase_orders.form.payment_terms_placeholder')}
                  disabled={!editable}
                  type="text"
                  name="payment_terms"
                  value={payment_terms}
                  onChange={this.onChange}
                  onBlur={this.saveDraft}
                  onFocus={(e) => { e.target.select(); }}
                />
              </div>
              {paymentTermsError ? <div className="hint error">{paymentTermsError}</div> : ''}
            </div>
          </div>
        ) : (
          <div className="row">
            <div className={`cell-16 ${paymentTermError ? 'has-error' : ''}`}>
              <div className="as-input reduce">
                {paymentTermId && (
                  <label tabIndex="-1" className="add-edit-trigger"><i className="icon-close" onClick={this.onPaymentTermDetach} /></label>
                )}
                <span className="label">{I18n.t('purchase_orders.form.payment_terms')}</span>
                <span className={`select input-r add-edit${editable ? '' : ' disabled'}${paymentTermId ? ' selected' : ''}`} name="supplier">
                  <input type="checkbox" />
                  <div className="control">
                    <input type="text" tabIndex="-1" onChange={this.onChangeSearchPaymentTerm} placeholder={I18n.t('purchase_orders.form.type_to_search')} />
                  </div>
                  <div className="select-content">
                    {!_.isEmpty(purchaseOrder.payment_term_name)
                      && [
                        <input key="input-copied" id="payment_term_copied" name="supplier" type="radio" value={purchaseOrder.payment_term_id} checked={purchaseOrder.payment_term_id == paymentTermId} onChange={this.onPaymentTermChange} />,
                        <label className={`${purchaseOrder.payment_term_id == paymentTermId ? 'checked' : ''}`} key="label-copied" htmlFor="payment_term_copied">
                          <span>{`${purchaseOrder.payment_term_name} - ${purchaseOrder.payment_term_value} days`}</span>
                        </label>,
                      ]
                    }
                    {paymentTerms.filter(paymentTerm => (purchaseOrder.payment_term_id != paymentTerm.id)).map(paymentTerm => (
                      [
                        <input key={`input-${paymentTerm.id}`} id={`payment_term_${paymentTerm.id}`} name="payment_term" type="radio" value={paymentTerm.id} checked={paymentTerm.id == paymentTermId} onChange={this.onPaymentTermChange} />,
                        <label className={`${paymentTerm.id == paymentTermId ? 'checked' : ''}`} key={`label-${paymentTerm.id}`} htmlFor={`payment_term_${paymentTerm.id}`}>
                          <span>{`${paymentTerm.name} - ${paymentTerm.value} days`}</span>
                        </label>,
                      ]
                    ))}
                    <div className="placeholder" data-placeholder={I18n.t('purchase_orders.form.select_payment_terms')} data-selected={I18n.t('purchase_orders.form.selected')} />
                  </div>
                  {paymentTermError ? <div className="hint error">{paymentTermError}</div> : ''}
                </span>
              </div>
            </div>
          </div>
        )}

        <div className="row">
          <div className={`cell-16 ${deliveryTermsError ? 'has-error' : ''}`}>
            <div className="as-input reduce">
              <span className="label">{I18n.t('purchase_orders.form.delivery_terms')}</span>
              <input
                className="output input-r"
                disabled={!editable}
                type="text"
                name="delivery_terms"
                value={delivery_terms}
                placeholder={I18n.t('purchase_orders.form.delivery_terms_placeholder')}
                onChange={this.onChange}
                onBlur={this.saveDraft}
                onFocus={(e) => { e.target.select(); }}
              />
            </div>
            {deliveryTermsError ? <div className="hint error">{deliveryTermsError}</div> : ''}
          </div>
        </div>

        <div className="row">
          <div className="cell-16">
            <div className="as-input reduce">
              <span className="label">{I18n.t('purchase_orders.form.comments_for_supplier')}</span>
              <input className="output input-r" type="text" disabled={!editable} name="comments_for_supplier" placeholder={I18n.t('purchase_orders.form.add_comment')} value={comments_for_supplier} onChange={this.onChange} onBlur={this.saveDraft} onFocus={(e) => { e.target.select(); }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SupplierInfo;
