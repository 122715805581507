import React, { useEffect, useState } from 'react';

const SupplierItemRow = ({
  supplierItem, selectedItems, onChangeBulkAction,
}) => {
  const [elementSelected, setElementSelected] = useState(false);

  useEffect(() => {
    setElementSelected(selectedItems.find(({ id }) => id === supplierItem.id));
  }, [selectedItems]);

  return (
    <tr>
      <td>
        <label className="has-checkbox">
          <input
            className="count-selected"
            type="checkbox"
            checked={elementSelected}
            onChange={() => onChangeBulkAction(supplierItem.id)}
          />
          <span className="checkbox input-s" />
        </label>
      </td>
      <td>{supplierItem.number}</td>
      <td>{supplierItem.productNumber}</td>
      <td>{supplierItem.globalNumber}</td>
      <td>{supplierItem.description}</td>
      <td>{supplierItem.unitMeasure}</td>
      <td className="number">{supplierItem.minimumOrderQuantity}</td>
      <td className="number bold">
        {supplierItem.grossPrice}
      </td>
      <td className="number">
        {`${supplierItem.discount} %`}
      </td>
      <td className="number bold">
        {supplierItem.netPrice}
      </td>
      <td>{supplierItem.currency}</td>
    </tr>
  );
};

export default SupplierItemRow;
