import React from "react";

import { humanizedIntegerPrice } from "@/components/shared/number_converter";
import Row from "@/components/ui/table/row";
import CellTd from "@/components/ui/table/cell_td";
import TableHead from "@/components/ui/table/table_head";

function DashboardTable({ columnNames, budget, amountFormat }) {
  const mappedDashboardHeaders = () => {
    const humanizedNames = columnNames.map((name) => name.replaceAll("_", " "));

    return humanizedNames.map((name) => {
      if (name === "overdue") return { name, className: "first number" };
      return { name, className: "number" };
    });
  };

  const renderBudgetCashflowRow = () => {
    return budget?.records?.map((record) => {
      if (record.paymentDate === "overdue")
        return (
          <CellTd key={record.paymentDate} className="mark first mute number">
            {humanizedIntegerPrice(record.valueCentsInCompanyCurrency / 100, amountFormat)}
          </CellTd>
        );

      return (
        <CellTd key={record.paymentDate} className="bold number mark">
          {humanizedIntegerPrice(record.valueCentsInCompanyCurrency / 100, amountFormat)}
        </CellTd>
      );
    });
  };

  const totalRow = () => {
    if (!budget.name) return 0;
    return (
      budget?.records
        ?.map((record) => record.valueCentsInCompanyCurrency)
        .reduce((acc, val) => acc + val) / 100
    );
  };

  return (
    <div className="table-dashboard">
      <div className="table-scrollable scroll-init">
        <table>
          <TableHead headers={mappedDashboardHeaders()} />
          <tbody>
            <Row className="un-hover">{renderBudgetCashflowRow()}</Row>
          </tbody>
        </table>
      </div>
      <table className="right-pinned">
        <TableHead
          headers={[
            { name: "Total", className: "" },
            { name: "Out of scope", className: "" },
          ]}
        />
        <tbody>
          <Row className="un-hover">
            <CellTd className="number bold double-dark-mark">
              {humanizedIntegerPrice(totalRow(), amountFormat)}
            </CellTd>
            <CellTd className="number bold double-dark-mark">
              {humanizedIntegerPrice(budget?.outOfScope?.valueCentsInCompanyCurrency / 100, amountFormat)}
            </CellTd>
          </Row>
        </tbody>
      </table>
    </div>
  );
}

export default DashboardTable;
