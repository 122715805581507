import React, { useState } from 'react';
import { humanizedPrice } from '../../shared/number_converter';
import TransactionRowActions from './transaction_row_actions';
import AccountingsService from '../../../services/accountings_service';
import * as toast from '../../shared/toast';
import SupplierInvoiceTransactionModel from '../../../models/SupplierInvoiceTransactionModel';

function TransactionRow({
  transaction, onAttachToPurchaseOrder, onAttachToBudget, state,
  currentUser, setAttachInfoModalOpen, amountFormat,
}) {
  const [attachTransaction, setAttachTransaction] = useState(transaction);

  const onDetach = () => {
    const invoiceIds = [attachTransaction.id];
    const params = {
      budgetId: null, categoryId: null, purchaseOrderId: null, invoiceIds,
    };

    AccountingsService.invoicesBulkUpdate(params).then(() => {
      updateTransaction({ budgetId: null, categoryId: null, purchaseOrderId: null });
      toast.successToast(I18n.t('commons.messages.changes_saved'));
    }).catch(() => {
      toast.errorToast(I18n.t('commons.messages.error'));
    });
  };

  const updateTransaction = (params) => {
    setAttachTransaction(
      new SupplierInvoiceTransactionModel({
        ...attachTransaction,
        params,
      }),
    );
  };

  const {
    account, vat, department, description, amount, exVat, poAmount,
  } = attachTransaction;

  return (
    <tr>
      <td className="no-f has-ellipsis" data-th="Account" data-ellipsis={account.name}><span className="text-ellipsis w-10">{account.name}</span></td>
      <td className="no-f has-ellipsis" data-th="Vat" data-ellipsis={vat.name}><span className="text-ellipsis w-5">{vat.name}</span></td>
      <td className="no-f has-ellipsis" data-th="Department" data-ellipsis={department.name}><span className="text-ellipsis w-5">{department.name}</span></td>
      {/* <td className="no-f" data-th="Project"><span>{project.name}</span></td> */}
      <td className="no-f has-ellipsis" data-th="Description" data-ellipsis={description}><span className="text-ellipsis w-10">{description}</span></td>
      <td className="number price no-f" data-th="Amount"><span>{humanizedPrice(amount, amountFormat)}</span></td>
      <td className="number price no-f" data-th="Ex vat"><span>{humanizedPrice(exVat, amountFormat)}</span></td>
      <td className="number price no-f" data-th="Po amount"><span>{humanizedPrice(poAmount, amountFormat)}</span></td>
      <td className="mark on-table no-f" data-th="Match">
        <span className="half-space">
          { attachTransaction.isAssigned()
            ? (
              <React.Fragment>
                <p className="color-green small">
                  { attachTransaction.isAssignedToBudget()
                    ? (
                      <React.Fragment>
                        {`${I18n.t('purchase_orders.invoices.linked')}:`}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        {`${I18n.t('purchase_orders.invoices.matched')}:`}
                      </React.Fragment>
                    )
                }
                </p>
                <p>{attachTransaction.purchaseOrderNumber || attachTransaction.categoryName }</p>
                <p className="small">{attachTransaction.budgetName}</p>
              </React.Fragment>
            )
            : null
          }
        </span>
        <span className="actions only-mobile">
          <TransactionRowActions
            onAttachToPurchaseOrder={onAttachToPurchaseOrder}
            onAttachToBudget={onAttachToBudget}
            transaction={attachTransaction}
            onDetach={onDetach}
            state={state}
            currentUser={currentUser}
            setAttachInfoModalOpen={setAttachInfoModalOpen}
          />
        </span>
      </td>
      <td className="actions mark on-table mobile-hide">
        <span>
          <TransactionRowActions
            onAttachToPurchaseOrder={onAttachToPurchaseOrder}
            onAttachToBudget={onAttachToBudget}
            transaction={attachTransaction}
            onDetach={onDetach}
            state={state}
            currentUser={currentUser}
            setAttachInfoModalOpen={setAttachInfoModalOpen}
          />
        </span>
      </td>
    </tr>
  );
}

export default TransactionRow;
