import React from 'react';
import TableFluidHead from './table_fluid_head';
import InvoiceRow from './invoice_row';
import TableBodyTransaction from './table_body_transactions';
import Loader from '../../loader';

function TableFluid({
  supplierInvoices, onBulkSelectAll, onBulkSelect, headers, order,
  onOrder, htmlClasses, showTransactions, onAttachToPurchaseOrder,
  onAttachToBudget, renderLabels, renderDots, renderInputs,
  currentUser, setAttachInfoModalOpen, standAlone, xero, amountFormat,
}) {
  return (
    <div className={`table-fluid table-scrollable table-column-11 show-last ${htmlClasses}`}>
      <Loader />
      {
        renderInputs(10, 'accounting_column')
      }
      <div className="table-header with-filters">
        <div className="mobile-filters">
          <label htmlFor="mobile-filters">
            <i className="icon-filter_list" />
          </label>
          <div className="selected-filters" />
        </div>
        <div className="table-nav">
          {
            renderLabels(10, 'accounting_column')
          }
          {
            renderDots(10, 'accounting_column')
          }
        </div>
      </div>

      <table className="can-expanded">
        <TableFluidHead
          onBulkSelectAll={onBulkSelectAll}
          headers={headers}
          order={order}
          onOrder={onOrder}
        />
        {
          supplierInvoices.map(supplierInvoice => (
            <React.Fragment key={supplierInvoice.uuid}>
              <InvoiceRow
                supplierInvoice={supplierInvoice}
                onBulkSelect={onBulkSelect}
                showTransactions={showTransactions}
                standAlone={standAlone}
                xero={xero}
                amountFormat={amountFormat}
              />
              <TableBodyTransaction
                transactions={supplierInvoice.transactions}
                onAttachToPurchaseOrder={onAttachToPurchaseOrder}
                onAttachToBudget={onAttachToBudget}
                state={supplierInvoice.state}
                currentUser={currentUser}
                setAttachInfoModalOpen={setAttachInfoModalOpen}
                amountFormat={amountFormat}
              />
            </React.Fragment>
          ))
        }
      </table>
    </div>
  );
}

export default TableFluid;
